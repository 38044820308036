const mainTitle = 'Pacing Ahead / Behind';

export const componentConfig = {
  cid: 'homePerformancePacing',
  id: 'homePerformancePacing',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'HOME.Pacing',
  breakpoints: ['lg6', 'sm6'],
  name: 'Pacing',
  title: mainTitle,
  __typename: 'Pacing',
  exportableTableData: 'xls',
  filterable: true,
  hasBrandImage: undefined,
  hideIfNoData: undefined,
  nameKey: 'Pacing',
  rootValueKey: 'HOME',
  stacked: false,
  overrides: undefined,
  feedSource: undefined,
  noDataTooltips: ['no data'],
  tooltipsTitle: [
    {
      title: mainTitle,
      message:
        'This module shows orders that are pacing far ahead of schedule or are pacing well behind schedule (+/-5%).',
    },
  ],
};

export const componentHeight = '460px';

export const dataSource = 'HOME.Pacing';
