
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';
import { Campaign } from '../../../../types/filters';
import { mapGetters } from 'vuex';
import { C360UserMenu, C360Avatar, useLink } from '@c360/ui';
import { AdvertiserInfo } from '@point/utility-classes';

const MENU_ITEMS = utils.MENU_ITEMS;

export default {
  name: 'settingsMenuC360',
  props: ['selectedAdvertiserName', 'isMobile', 'isAdmin'],
  components: { C360UserMenu, C360Avatar },
  mixins: [analytics],
  data: (): {
    exportOpened: boolean;
    snackbar: boolean;
    magicLinkShareDashboard: string | null;
    generatingShareDashboardLink: boolean;
    windowHeight: number;
    menuItems: Array<Object>;
    menuOpen: boolean;
  } => ({
    exportOpened: false,
    snackbar: false,
    magicLinkShareDashboard: null,
    generatingShareDashboardLink: false,
    windowHeight: 0,
    menuItems: [],
    menuOpen: false,
  }),
  mounted(): void {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  methods: {
    generateUserForAvatarComponent(): string {
      const name = this.getterUser.name;
      if (!name) return this.getterUser.email;
      let nameItems = name.split(' ');
      if (nameItems.length === 1) return nameItems[0];
      nameItems = nameItems.filter(word => isNaN(word));
      return nameItems.join(' ');
    },
    hasIndividualRight(roles: Array<string>): boolean {
      return utils.hasRight(this.$store.state.customer.user, roles);
    },
    handleMenuItemClick($event): void {
      const id = $event.id;
      switch (id) {
        case MENU_ITEMS.SUPPORT_CENTER.id: {
          this.openHelpAdmin();
          break;
        }
        case MENU_ITEMS.ADVERTISER_VIEW.id: {
          this.openAdvertiserView();
          break;
        }
        case MENU_ITEMS.EXIT_FULLSCREEN.id: {
          this.handleFullScreen();
          break;
        }
        case MENU_ITEMS.GO_FULLSCREEN.id: {
          this.handleFullScreen();
          break;
        }
        case MENU_ITEMS.EDIT_ADVERTISER.id: {
          this.editClient();
          break;
        }
        case MENU_ITEMS.TO_MANAGE_REPORTS.id: {
          this.goToManageReports();
          break;
        }
        case MENU_ITEMS.SHARE_PAGE_WITH_DATES.id: {
          this.copyToClip(true);
          break;
        }
        case MENU_ITEMS.SHARE_PAGE_WITHOUT_DATES.id: {
          this.copyToClip(false);
          break;
        }
        case MENU_ITEMS.BACK_TO_DASHBOARD.id: {
          this.openRecentCampaign();
          break;
        }
        case MENU_ITEMS.MANAGE_ADVERTISERS.id: {
          this.openLinearAdmin();
          break;
        }
        case MENU_ITEMS.MANAGE_USERS.id: {
          this.openManageUsersAdmin();
          break;
        }
        case MENU_ITEMS.MANAGE_AGENCIES.id: {
          this.openAgencyAdmin();
          break;
        }
        case MENU_ITEMS.MANAGE_SYSTEM.id: {
          this.openSystemAdmin();
          break;
        }
        case MENU_ITEMS.CUSTOMIZE.id: {
          this.openEditMode();
          break;
        }
        case MENU_ITEMS.LOGOUT.id: {
          this.$router.push({ name: 'logout' });
          this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_LOGOUT);
          break;
        }
        case MENU_ITEMS.FEED_TOOLS.id: {
          this.$router.push({ name: 'adminfeedtools' });
          break;
        }
        case MENU_ITEMS.SHOW_ALL_TACTICS.id: {
          this.toggleShowAllTactics();
          break;
        }
        case MENU_ITEMS.SHOW_TACTICS_WITH_CAMPAIGNS.id: {
          this.toggleShowAllTactics();
          break;
        }
        case MENU_ITEMS.EDIT_CAMPAIGN_PERMISSIONS.id: {
          this.openEditCampaignPermissions();
          break;
        }
      }
    },
    openLinearAdmin(): void {
      this.$router.push({ name: 'linearadmin' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_ADVERTISERS);
    },
    openDashAdmin(): void {
      this.$router.push({ name: 'admin' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_USERS);
    },
    openAgencyAdmin(): void {
      this.$router.push({ name: 'agencyAdmin' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_REPORTS);
    },
    openReportsAdmin(): void {
      this.$router.push({ name: 'reportsadmin' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_AGENCIES);
    },
    openSystemAdmin(): void {
      this.$router.push({ name: 'systemAdmin' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_SYSTEM);
    },
    openAdvertiserView(): void {
      this.$router.push({ name: 'advertiser' });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_ADVERTISER_VIEW);
    },
    openEditMode(): void {
      const componentList = this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components.find(
        c => c.type !== 'summaryonly',
      );
      const customizations = componentList.components
        .filter(c => !c.disabled)
        .map(c => {
          return { cid: c.cid, overrides: c.overrides, id: c.id };
        });
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);
      this.$store.dispatch('layoutEditor/toggleEditMode', true);
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_EDIT_LAYOUT);
    },
    openHelpAdmin(): void {
      const agency = utils.getAgencyFromURL();

      const baseURL = useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_SBG_HELP);
      window.location.href = `${baseURL}/help/category/14665903913111`;
    },
    openManageUsersAdmin(): void {
      const agency = utils.getAgencyFromURL();
      const ahRefreshToken = localStorage.getItem('ah:refreshToken');
      let tokenQueryParam;
      if (ahRefreshToken) {
        tokenQueryParam = `/ahlogin/?token=${ahRefreshToken}`;
      }
      const redirectQueryParam = '&redirect_page=Users';
      const baseURL = useLink({
        product: 'admin',
        agency,
        env: utils.getEnvForProductLink(),
      });
      this.analyticTrack(this.trackValue.EVENT_NAV, this.trackValue.TRACK_NAV_MANAGE_USERS);
      window.location.href = `${baseURL}${tokenQueryParam}${redirectQueryParam}`;
    },
    editClient(): void {
      this.analyticTrack(this.trackValue.EVENT_NAV_OPTIONS, this.trackValue.TRACK_NAV_EDIT_ADVERTISER);
      this.$router.push({ name: 'linearadmin', query: { ppid: this.$route.query.id } });
    },
    goToManageReports(): void {
      this.analyticTrack(this.trackValue.EVENT_NAV_OPTIONS, this.trackValue.TRACK_NAV_GO_TO_MANAGE_REPORTS);
      this.$router.push({
        name: 'scheduledreports',
        query: { advertiser: this.$route.query.id, tab: 'advertiser_reports' },
      });
    },
    openRecentCampaign(): void {
      const recentStr: string | null = localStorage.getItem(`${this.$store.getters.user.email}:recentPages`);
      if (!recentStr) {
        this.$router.push({ name: 'home', query: { recent: 'true' } });
      } else {
        const lastCampaign = JSON.parse(recentStr).find(page => (page.name = 'home' && page.query?.id));
        this.$router.push({ name: 'home', query: lastCampaign ? lastCampaign.query : { recent: 'true' } });
      }
    },
    copyToClip(shareWithDates: boolean): void {
      this.generatingShareDashboardLink = true;
      const allDateAccess = !this.$route.query?.daterange || this.$route.query?.daterange === 'alltime';

      const { campaigndaterange, campaignenddate, campaignstartdate } = this.$store.state.customer.selection;
      let daterange = 'alltime';
      if (campaigndaterange === 'customRange') {
        daterange = 'customRange';
      } else if (this.$route.query?.daterange) {
        daterange = this.$route.query.daterange;
      }

      // by default use viewCampaigns query.
      let camps = this.$route.query?.viewCampaigns;
      // Special case for GAM
      const viewCampaignType = this.$route.query?.viewCampaignType;
      if (viewCampaignType) {
        // get all the campaigns that match the tactic.
        camps = this.$store.state.customer?.allTimeCampaigns?.campaignlist.filter((c: Campaign) => {
          return c.CampaignType === viewCampaignType;
        });
        // return flat list of campaign ids
        camps = camps.map((x: Campaign): string => x.id).join(',');
      }

      const layout = this.$store.state.customer.dynamicLayout?.layoutCustomizations?.tactic;

      this.$store
        .dispatch('admin/getShareDashboardLink', {
          email: this.getterUser.email,
          aid: this.$route.query?.id,
          layout,
          tab: this.$route.query?.tab,
          advertiserName: this.selectedAdvertiserName,
          advertiserLogo: this.advertiserInfo?.advertiserLogo,
          agencyPartner: this.advertiserInfo?.AgencyPartner,
          // FIXME: check if that's still needed after redirects
          theme: this.Theme.companyId,
          campaigns: this.$route.query?.campaigns,
          dmas: this.$route.query?.dmas,
          types: this.$route.query?.types,
          viewCampaigns: camps,
          daterange,
          startdate: this.$route.query?.startdate,
          enddate: this.$route.query?.enddate,
          allDateAccess: allDateAccess,
          campaigndaterange: campaigndaterange === 'customRange' ? '' : 'alltime',
          campaignstartdate,
          campaignenddate,
          baseDate: campaignstartdate || this.$route.query?.startdate,
          summaryView: this.$route.query?.summaryView || false,
          shareWithDates,
          aliases: this.advertiserInfo?.aliases,
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((tokenResponse: any) => {
          if (!tokenResponse || !tokenResponse.token) {
            this.magicLinkShareDashboard = 'Sorry, an error occurred';
            // eslint-disable-next-line no-console
            console.error('getShareDashboardLink error', tokenResponse);
          }
          if (tokenResponse.id) {
            this.magicLinkShareDashboard = `${window.location.origin}/s/${tokenResponse.id}`;
          } else {
            this.magicLinkShareDashboard = `${window.location.origin}/view/?t=${tokenResponse.token}`;
          }
          navigator.clipboard.writeText(this.magicLinkShareDashboard);
          this.snackbar = true;
          this.analyticShareTrack(
            this.trackValue.EVENT_NAV,
            this.trackValue.TRACK_NAV_SHARE_DASHBOARD,
            tokenResponse.id,
            tokenResponse.token,
          );
          return;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          this.magicLinkShareDashboard = 'Sorry, an error occurred';
          // eslint-disable-next-line no-console
          console.error('copyToClip getShareDashboardLink error', err);
        })
        .finally(() => {
          this.generatingShareDashboardLink = false;
        });
    },
    handleFullScreen(): void {
      utils.toggleFullScreen();
      this.analyticTrack(this.trackValue.EVENT_NAV_OPTIONS, this.trackValue.TRACK_NAV_FULLSCREEN);
      this.$emit('handleFullscreen', this.isFullscreen);
    },
    onResize(): void {
      this.windowHeight = window.innerHeight;
    },
    async updateAdvertiserInfo(): Promise<void> {
      try {
        if (!this.advertiserInfo) {
          await this.loadAdvertiserInfo();
        }
        const updated = await this.$store.dispatch('advertiser/updateAdvertiserInfo', {
          advertiserLogo: this.advertiserInfo?.advertiserLogo,
          WURL: this.advertiserInfo?.WURL,
          phone: this.advertiserInfo?.phone,
          isShowAllTactics: !this.isShowAllTactics,
          gamAutoOnboarding: this.advertiserInfo?.gamAutoOnboarding,
          propertyId: this.$route.query.id,
        });
        if (updated) {
          await this.loadAdvertiserInfo();
        }
      } catch (error) {
        console.error('updateAdvertiserInfo error', error);
      }
    },
    async toggleShowAllTactics(): Promise<void> {
      await this.updateAdvertiserInfo();
    },
    async loadAdvertiserInfo(): Promise<void> {
      try {
        await this.$store.dispatch('advertiser/getAdvertiserInfo', { id: this.$route.query.id });
      } catch (error) {
        console.error('loadAdvertiserInfo error', error);
      }
    },
    openEditCampaignPermissions(): void {
      this.$emit('open-edit-campaign-permissions-modal');
    },
  },
  computed: {
    ...mapGetters({
      getterUser: 'user',
    }),
    advertiserInfo(): AdvertiserInfo {
      return this.$store.state?.advertiser?.advertiserInfo?.data;
    },
    settingsMenuItems(): Array<Object> {
      const advertiserOptions = [];
      if (!this.isAdmin && !this.isAdvertiserView && this.hasEditPermissions) {
        advertiserOptions.push(MENU_ITEMS.EDIT_ADVERTISER);
      }
      if (this.canCopy && this.hasIndividualRight(['READ_SCHEDULED_REPORT'])) {
        advertiserOptions.push(MENU_ITEMS.TO_MANAGE_REPORTS);
      }
      if (!this.isAdvertiserView) {
        advertiserOptions.push(MENU_ITEMS.ADVERTISER_VIEW);
      }
      if (this.isAdmin) {
        advertiserOptions.push(MENU_ITEMS.BACK_TO_DASHBOARD);
      }

      const adminOptions = [];
      if (this.iaAdminHierarchySU || this.hasIndividualRight(['ADMIN_MENU_USER_MANAGEMENT'])) {
        adminOptions.push(MENU_ITEMS.MANAGE_USERS);
      }
      if (this.hasIndividualRight(['SCREEN_ADVERTISER_MGMT']) || this.isSuperUser || this.iaAdminHierarchySU) {
        adminOptions.push(MENU_ITEMS.MANAGE_ADVERTISERS);
      }
      if (
        this.hasIndividualRight(['SCREEN_AGENCY_MGMT']) ||
        this.isSuperUser ||
        this.iaAdminHierarchySU ||
        this.isMultiAgencyAdmin
      ) {
        adminOptions.push(MENU_ITEMS.MANAGE_AGENCIES);
      } else {
        adminOptions.push(MENU_ITEMS.MANAGE_AGENCY);
      }
      if (this.iaAdminHierarchySU) {
        adminOptions.push(MENU_ITEMS.MANAGE_SYSTEM);
        adminOptions.push(MENU_ITEMS.FEED_TOOLS);
      }
      const editOptions = [];
      if (this.hasCustomizeRight && this.layoutIsLoaded && !this.isOrderPages && !this.isLanding) {
        const customizeIcon = MENU_ITEMS.CUSTOMIZE;
        customizeIcon.title = `Edit ${this.onSummaryPage ? 'product summary' : 'product'} layout`;
        editOptions.push(customizeIcon);
      }

      if (
        this.isCampaignPage &&
        this.iaAdminHierarchySU &&
        !this.isMultipleCampaignsSelected &&
        !this.isOrderPages &&
        !this.onSummaryPage &&
        !this.isLanding
      ) {
        editOptions.push(MENU_ITEMS.EDIT_CAMPAIGN_PERMISSIONS);
      }

      if (this.canCopy) {
        if (this.isShowAllTactics) {
          editOptions.push(MENU_ITEMS.SHOW_TACTICS_WITH_CAMPAIGNS);
        } else {
          editOptions.push(MENU_ITEMS.SHOW_ALL_TACTICS);
        }
      }

      const shareOptions = [];
      if (!this.isOrderPages && !this.isLanding) {
        if (this.canCopy) {
          if (!this.onSummaryPage) {
            shareOptions.push(MENU_ITEMS.SHARE_PAGE_WITH_DATES);
          }
          shareOptions.push(MENU_ITEMS.SHARE_PAGE_WITHOUT_DATES);
        }
      }

      const commonOptions = [];
      if (this.isLocalDev) {
        commonOptions.push(MENU_ITEMS.THEME_EDITOR);
      }

      if (this.isFullscreen) {
        commonOptions.push(MENU_ITEMS.EXIT_FULLSCREEN);
      } else {
        commonOptions.push(MENU_ITEMS.GO_FULLSCREEN);
      }

      if (!this.isHideHelpCenter) {
        commonOptions.push(MENU_ITEMS.SUPPORT_CENTER);
      }
      commonOptions.push(MENU_ITEMS.LOGOUT);

      const finalArray = [];
      if (shareOptions.length) finalArray.push(shareOptions);
      if (advertiserOptions.length) finalArray.push(advertiserOptions);
      if (adminOptions.length) finalArray.push(adminOptions);
      if (editOptions.length) finalArray.push(editOptions);
      if (commonOptions.length) finalArray.push(commonOptions);
      return finalArray;
    },
    canCopy(): boolean {
      return !!navigator.clipboard && this.$route.name === 'home' && this.$route.query?.id;
    },
    isFullscreen(): boolean {
      return utils.isFullscreen();
    },
    isAdvertiserView(): boolean {
      return this.$route.name === 'advertiser';
    },
    isLocalDev(): boolean {
      return /localhost|127.0.0.1|analytics.compulse360.local.adportal.io/gi.test(window.location.host);
    },
    isSuperUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
    iaAdminHierarchySU(): boolean {
      return this.$store.state.customer.user.is_superuser;
    },
    hasCustomizeRight(): boolean {
      const userAgency = this.$store.state.customer.user?.Agency.toLowerCase();
      // no customizing the summary pages at this time
      if (this.$route.query?.summaryView) {
        return false;
      } else if (this.$route.query?.tab) {
        // super user has full access.
        if (this.isSuperUser || this.iaAdminHierarchySU) return true;
        // make sure the user has the same agency as the advertiser they are using the same theme.
        else if (
          this.$store?.state?.advertiser?.advertiserInfo?.data?.AgencyPartnerName.toLowerCase() === userAgency ||
          this.Theme?.companyId.toLowerCase() === userAgency
        ) {
          // check if the user has the right to customize the tactic
          return (
            this.hasIndividualRight(['CUSTOMIZE_AGENCY']) ||
            this.hasIndividualRight(['CUSTOMIZE_ADVERTISER']) ||
            this.hasIndividualRight(['CUSTOMIZE_USER'])
          );
        } else return false;
      } else {
        return false;
      }
    },
    onSummaryPage(): boolean {
      return this.$route.query?.tab === 'summary';
    },
    isShowAllTactics(): boolean {
      return !!this.$store.state?.advertiser?.advertiserInfo?.data?.isShowAllTactics;
    },
    menuMaxHeight(): object {
      return { 'max-height': `${this.windowHeight - 100}px` };
    },
    layoutIsLoaded(): boolean {
      return !!this.$store.state.customer.dynamicLayout;
    },
    hasEditPermissions(): boolean {
      return this.isSuperUser || this.iaAdminHierarchySU || this.hasIndividualRight(['SCREEN_ADVERTISER_MGMT']);
    },
    isHideHelpCenter(): boolean {
      return this.getterUser.permissions.includes('HIDE_HELP_CENTER');
    },
    isOrderPages(): boolean {
      return ['orderlist', 'ordersummary'].includes(this.$route?.query?.tab);
    },
    isLanding(): boolean {
      return this.$route.query.recent === 'true' || this.$route.query.tab === 'home';
    },
    isMultipleCampaignsSelected(): boolean {
      return this.$store.state.filters?.selectedCampaigns?.length > 1;
    },
    isCampaignPage(): boolean {
      return this.$route.name === 'home';
    },
    isMultiAgencyAdmin(): boolean {
      return this.hasIndividualRight(['SHOW_ALL_AGENCIES']);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
