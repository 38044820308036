
import Vue from 'vue';
import { componentConfig, componentHeight } from './utils/configOrdersEndingSoon';
import { EnhancedDateRange, Range, RangeText } from './types/types';
import MetricsHeader from './metricsHeader.vue';
import OrderCard from './orderCard.vue';
import { updateStorage, checkDataValidity } from './utils/utils';
import NoChartData from '../../charts/no-data/NoChartData.vue';
import { Order } from '../../../../store/modules/primary/types';

export default Vue.extend({
  name: 'EndingSoonOrders',
  data: (): {
    dateRange: Range | null;
    selectedRange: RangeText | null;
    componentConfig: any;
    loading: boolean;
    currentPage: number;
    itemsPerPage: number;
    orders: Order[];
    showNoDataChart: boolean;
    localStorageName: string;
    componentHeight: string;
  } => ({
    dateRange: null,
    selectedRange: null,
    componentConfig,
    loading: false,
    currentPage: 1,
    itemsPerPage: 5,
    orders: [],
    showNoDataChart: false,
    localStorageName: 'ending_soon_orders',
    componentHeight,
  }),
  components: {
    MetricsHeader,
    OrderCard,
    NoChartData,
  },
  created() {
    this.setDefaultDateRange();
    this.getData();
  },
  computed: {
    totalPages(): number {
      if (!this.orders?.length) return 0;
      return Math.ceil(this.orders?.length / this.itemsPerPage);
    },
    paginatedData() {
      if (!this.orders) return [];
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return this.orders?.slice(start, end);
    },
  },
  methods: {
    async getData() {
      const storedData = localStorage.getItem(this.localStorageName);
      if (storedData) {
        const parsedData = JSON.parse(storedData);

        const { isDataValid, isRightUser, isRightAgency } = checkDataValidity(
          parsedData,
          this.$store.state.customer.user.email,
          this.$store.state.customer.user.Agency,
        );
        this.orders = parsedData?.data;

        if (!isRightUser || !isRightAgency) {
          localStorage.removeItem(this.localStorageName);
          await this.updateEndingsSoonOrders(true);
        } else if (!isDataValid) {
          await this.updateEndingsSoonOrders(false);
        }
      } else {
        await this.updateEndingsSoonOrders(true);
      }
    },
    async updateEndingsSoonOrders(withLoading: boolean) {
      await this.getEndingsSoonOrders(withLoading);
      updateStorage(
        this.orders,
        this.localStorageName,
        this.$store.state.customer.user.email,
        this.$store.state.customer.user.Agency,
      );
    },
    async getEndingsSoonOrders(withLoading: boolean) {
      this.loading = withLoading;
      try {
        const { Orders } = await this.$store.dispatch('primary/getEndingSoonOrders', {
          daterange: this.dateRange,
          limit: 100,
          offset: 0,
        });
        this.orders = Orders;
        const hasNoOrders = this.orders?.length === 0;
        this.showNoDataChart = hasNoOrders;
      } catch (error) {
        this.showNoDataChart = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    setDefaultDateRange() {
      this.dateRange = Range.THISWEEK;
      this.selectedRange = RangeText.THISWEEK;
    },
    selectRange(range: EnhancedDateRange): void {
      if (this.loading) return;
      this.dateRange = range?.RangeKey;
      this.selectedRange = range?.Text;
      this.getEndingsSoonOrders(true);
    },
  },
});
