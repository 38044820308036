
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import TopMetricCard from './topMetricCard.vue';
import TopMetric from '../../../../types/top-metric';
import utils from '../../../../util';
import CardMetric from '../../../../types/card-metric';

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericTopMetrics',
  components: { NoChartData, TopMetricCard },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    metrics: { type: Array as () => Array<TopMetric> },
    requiredData: String,
    theme: Object,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
  },
  data() {
    // return { componentHeight: 200 };
  },
  computed: {
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    cards(): Array<CardMetric> {
      if (!this.metrics || !Array.isArray(this.metrics)) return [];
      const analytics = utils.adDataForKey(this);
      const hideSpendMetrics = analytics?.SOCIALTotal && !!analytics?.SOCIALTotal?.HideSpend;

      return this.metrics.reduce((allCards: Array<CardMetric>, metric: TopMetric) => {
        const card = {
          type: '',
          description: '',
          title: '',
          headline: '',
          toolTip: '',
        };

        // special case to hide Social CPC/Spend metrics
        const isSpendMetric =
          metric.topDataKey === 'SOCIALTotal.SpendVal' || metric.topDataKey === 'SOCIALTotal.CostPerClick';
        if (isSpendMetric && hideSpendMetrics) {
          return allCards;
        }

        if (metric.type) {
          card.type = metric.type;
        }
        if (metric.title) {
          card.title = metric.title;
        }
        if (metric.topDataKey) {
          const data = utils.adDataForKey(this, metric.topDataKey);
          if (data) card.headline = utils.stringFormat(data, metric.topDataFormat);
        }
        if (metric.secondDataKey) {
          const data = utils.adDataForKey(this, metric.secondDataKey);
          if (data) card.description = utils.stringFormat(data, metric.secondDataFormat);
        }
        if (metric.toolTip) {
          card.toolTip = metric.toolTip;
        }
        // console.log(JSON.stringify(card));
        if (card.description || card.headline) allCards.push(card);
        return allCards;
      }, []);
    },
    hasRequiredData(): boolean {
      // make sure we have all of the required props
      if (this.metrics && Array.isArray(this.metrics) && this.cards && this.cards.length) return true;
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.hasRequiredData && this.cards.length > 0) {
          this.$emit('rendered', { empty: false });
        } else {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    });
  },
  methods: {
    // this is temporary, feature to help devs
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    devTooltipData(card: any): any {
      const validEmails = [];

      let email = 'n/a';
      if (this.$store.state?.customer?.user) {
        email = this.$store.state.customer.user;
      }
      if (this.isExporting && this.exportData.userEmail) {
        email = this.exportData.userEmail;
      }

      const validEmail = validEmails.find((e: string) => e.toLowerCase() === email);
      if (!validEmail || this.sectionConfig.id !== 'broadcast' || card.title !== 'Total Visits') {
        return null;
      }
      const adData = utils.adDataForKey(this);
      const { AOVisits, Visits8, Visits10, Visits12, Visits15, Visits20, Visits30 } = adData.BROADCASTTotal;
      return { AOVisits, Visits8, Visits10, Visits12, Visits15, Visits20, Visits30 };
    },
  },
});
