
import Vue from 'vue';
import utils from '../../../../util';
import { C360Icon } from '@c360/ui';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'dataTableFooter',
  components: { ExportDownloadBtn, C360Icon },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'page',
    'pageCount',
    'itemsPerPage',
    'itemsLength',
    'search',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'showSearch',
    'isOrder',
    'isBackendPagination',
  ],
  methods: {
    prevPage(): void {
      if (this.page > 1) {
        this.$emit('update-page', parseInt(this.page) - 1);
      }
    },
    nextPage(): void {
      if (
        (this.isBackendPagination && this.page <= this.pageCount) ||
        (!this.isBackendPagination && this.page < this.pageCount)
      ) {
        this.$emit('update-page', parseInt(this.page) + 1);
      }
    },
  },
  computed: {
    showArrowButtons(): boolean {
      return this.isBackendPagination ? this.pageCount > 0 : this.pageCount > 1;
    },
    canExport(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.itemsLength > 0 && (this.canExportToCSV || this.canExportToXLS);
    },
    canExportToCSV(): boolean {
      return !!this.componentConfig?.downloadTableData;
    },
    canExportToXLS(): boolean {
      return !!this.componentConfig?.exportableTableData;
    },
    isLastPage(): boolean {
      if (this.isBackendPagination) {
        return this.pageCount < 1 || this.pageCount - this.page < 0;
      }
      return this.page === this.pageCount || this.pageCount < 1;
    },
    proposalRange(): string {
      let remainder = 0;
      if (this.itemsLength) {
        remainder = this.itemsLength % this.itemsPerPage;
      }
      const firstNumber = 1 + this.itemsPerPage * (this.page - 1);
      let secondNumber;
      const secondNumberCondition = this.isBackendPagination ? this.page <= this.pageCount : this.page < this.pageCount;
      if (secondNumberCondition) {
        secondNumber = this.page * this.itemsPerPage;
      } else {
        if (remainder >= 1) {
          secondNumber = (this.page - 1) * this.itemsPerPage + remainder;
        } else {
          secondNumber = this.page * this.itemsPerPage;
        }
      }
      return `${firstNumber}-${secondNumber} of ${this.itemsLength}`;
    },
  },
});
