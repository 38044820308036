const mainTitle = 'Performance Overview';

export const componentConfig = {
  cid: 'homePerformanceFullChart',
  id: 'homePerformanceFullChart',
  dataChecks: ['HasDisplay'],
  disabled: null,
  dataSource: 'HOME.FullMetric',
  breakpoints: ['lg12', 'sm12'],
  name: 'genericLineChart',
  title: mainTitle,
  __typename: 'genericLineChart',
  chartColorIndex: null,
  chartType: null,
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: false,
  lineSeriesKeys: ['Actual period', 'Preceding period'],
  legendKeys: [],
  showyAxisSplitLine: null,
  total: null,
  useTacticSummaryData: null,
  xAxisIntervalCount: 1,
  xAxisMaxItems: null,
  xAxisShowAll: true,
  xAxisSplitLineHide: null,
  yAxisHide: null,
  yAxisLineHide: null,
  xAxisRotateDeg: null,
  showOneLine: null,
  chartColors: null,
  sideTotalMetrics: null,
  tooltips: null,
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thisweek',
  fetchData: [
    { method: 'getActiveClients', title: 'Active Clients', name: 'Clients' },
    { method: 'getActiveOrders', title: 'Active Orders', name: 'Orders' },
    { method: 'getActiveCampaigns', title: 'Active Campaigns', name: 'Campaigns' },
    { method: 'getImpressions', title: 'Impressions', name: 'Impressions' },
  ],
  tooltipsTitle: [
    {
      title: mainTitle,
      message:
        'This module is a bigger view of each of the graphs in the “Metrics Overview” module. You can choose the different dimensions and date ranges in the upper right corner.',
    },
  ],
};

export const componentHeight = '410px';
