
import Vue from 'vue';
import ChartTooltip from '../tooltip/chartTooltip.vue';
import { AdPerformance } from '../../../../store/modules/customer/types';
import utils from '../../../../util';
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
// import { format } from 'date-fns';

interface BarMetric {
  title: string;
  impressions: number;
  clicks: number;
  total: number;
}

export default Vue.extend({
  inheritAttrs: false,
  name: 'sideSummary',
  components: {
    ChartTooltip,
    EditModuleBtn,
  },
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'insideChart',
  ],
  computed: {
    adData(): AdPerformance | null {
      return utils.adDataForKey(this);
    },
    // NOTE: probably will need to be more dynamic in terms of what tactics to include
    progressBarData(): BarMetric[] {
      const tactics = ['Preroll', 'Display', 'Social', 'SEM'];
      const data = tactics.reduce((all: BarMetric[], tactic: string) => {
        const tacticData = utils.adDataForKey(this, `${tactic.toUpperCase()}Total`);
        if (tacticData) {
          all.push({
            title: tactic,
            impressions: tacticData.Impressions,
            clicks: tacticData.Clicks,
            total: tacticData.Impressions + tacticData.Clicks,
          });
        }
        return all;
      }, []);

      // sort by total, important to determine width of segments
      data.sort((a: BarMetric, b: BarMetric) => (b.total > a.total ? 1 : -1));

      return data;
    },
    topTotal(): number {
      return this.progressBarData[0].total;
    },
    metricsToShow(): object {
      const metrics: object[] = [];
      const metricsObject = utils.adDataForKey(this, this.componentConfig.dataSource);
      if (typeof metricsObject === 'undefined') return metrics;
      // looks like metrics changed to metricKeys
      const metricKeys: Array<string> = this.componentConfig?.metrics
        ? this.componentConfig.metrics
        : this.componentConfig?.metricKeys;
      if (Array.isArray(metricKeys)) {
        metricKeys.forEach((metric: string) => {
          const noSpecChars = metricsObject[metric].toString().replace(/[^a-zA-Z0-9 ]/g, '');
          // hide zeros if set to
          if (this.hideZeros && parseInt(noSpecChars) < 1) return;
          // hide values if HideSpend is true
          if (metricsObject.HideSpend && (metric === 'Spend' || metric === 'CostPerView')) return;
          const metricValue = metricsObject[metric];
          const obj = {
            title: utils.headerNamesMap(metric),
            value: metricValue,
          };
          metrics.push(obj);
        });
      }
      return metrics;
    },
    showLastUpdated(): boolean {
      if (this.$route.query?.viewCampaigns?.includes(',')) {
        return false;
      } else return true;
    },
    lastModifiedDate(): string {
      return utils.getLastModifiedDate(this).toString();
    },
    hideZeros(): boolean {
      return this.componentConfig.hideZeros;
    },
    metricHeight(): { height: string } {
      const count = this.metricsToShow.length;
      if (this.insideChart) {
        return { height: `${100 / count}%` };
      }
      // adjust for 5% bottom margin
      return { height: `${100 - (count * 5) / count}%` };
    },
    lastUpdatedToolTip(): string {
      return utils.getTooltipsFromMetric('lastupdated')?.message;
    },
  },
  methods: {
    getTooltips(type: string): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(type)];
    },
    getSegmentWidth(data: BarMetric, metric: string): string {
      const width = Math.round((data[metric] / this.topTotal) * 100);
      return `width: ${width < 1 ? 1 : width}%`;
    },
    getFriendlyNumber(number: number): string {
      return utils.formatNumberWithCommas(number);
    },
    getAlphaNumber(number: number): string | number {
      if (number > 1000000) {
        return (number / 1000000).toFixed(0) + 'M';
      } else if (number > 999) {
        return (number / 1000).toFixed(0) + 'K';
      } else {
        return number;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.metricsToShow.length) {
          this.$emit('rendered', { empty: false });
        } else {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    });
  },
});
