
import Vue from 'vue';
// todo: move this to a shared lib or something, not allowed to include code through hard file links across projects
import {
  SideSummary as SideSummaryType,
  LineChart,
  BarChart,
  Table,
} from '../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import SideSummary from '../charts/overviews/sideSummary.vue';
import genericLineChart from '../charts/line/lineChart.vue';
import genericBarChart from '../charts/bar/barChart.vue';
import genericTable from '../charts/tables/table.vue';
import util from '../../../util';

export default Vue.extend({
  name: 'GoogleVideoSummaryView',
  props: ['isExporting', 'isExportDynamic', 'componentConfig', 'exportData', 'exportContext'],
  inheritAttrs: false,
  components: { SideSummary, genericLineChart, genericBarChart, genericTable },
  computed: {
    sideSummaryConfig(): SideSummaryType {
      return this.componentConfig.subcomponents?.sideSummary;
    },
    topChartConfig(): LineChart | BarChart {
      return this.componentConfig.subcomponents?.topChart;
    },
    bottomChartConfig(): LineChart | BarChart {
      return this.componentConfig.subcomponents?.bottomChart;
    },
    tableConfig(): Table {
      return this.componentConfig.subcomponents?.table;
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
  },
  mounted(): void {
    if (this.$store.state.customer?.tacticSummaryPerformance?.GOOGLEVIDEO) {
      return;
    }
    if (this.isExporting) {
      // use existing data if pre-fetched, go get it if not
      if (this.exportData.tacticsSummary) {
        this.$store
          .dispatch('setTacticSummary', this.exportData.tacticsSummary)
          .then(() => {
            setTimeout(() => {
              this.$emit('rendered', { empty: false });
            }, 500);
          })
          .catch(() => {
            setTimeout(() => {
              this.$emit('rendered', { empty: true });
            }, 500);
          });
        return;
      }
      const tactic = util.dataKeyBySectionIdMap(this.exportData.tab);
      this.$store
        .dispatch('getTacticSummary', { tactic, advertiserId: this.exportData.advertiserId })
        .then(() => {
          setTimeout(() => {
            this.$emit('rendered', { empty: false });
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            this.$emit('rendered', { empty: true });
          }, 500);
        });
    } else {
      const tactic = util.dataKeyBySectionIdMap(
        this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab,
      );
      // if sharing we need to pass the email token for access.
      if (this.isShared) {
        this.$store.dispatch('getTacticSummary', {
          tactic,
          advertiserId: this.$route.query.id || this.$store.state.customer?.sharedSelection?.aid,
          token: this.$store.state.customer?.sharedSelection?.etoken,
        });
      } else {
        this.$store.dispatch('getTacticSummary', { tactic, advertiserId: this.$route.query.id });
      }
    }
  },
});
