
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import utils from '@/util';
import { OrderService } from '@/services/order-summary';
import { format } from 'date-fns';

const orderService = new OrderService();

export default Vue.extend({
  name: 'RetargetingModal',
  components: { C360Icon },
  props: {
    showModal: Boolean,
    dashboardId: String,
    advertiserId: String,
  },
  data: (): {
    selectedOption: any;
    campaigns: any[];
    campaignId: String;
    hasError: boolean;
  } => ({
    selectedOption: null,
    campaigns: [],
    campaignId: '',
    hasError: false,
  }),
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.campaigns = [];
        this.getData();
      }
    },
  },
  computed: {
    headers(): any {
      return [
        {
          text: 'Pacing',
          value: 'Pacing',
          align: 'left',
          sortable: false,
          width: '90px',
        },
        {
          text: 'Campaign Name',
          value: 'FriendlyName',
          align: 'left',
          sortable: false,
          width: '300px',
        },
        {
          text: 'Product',
          value: 'AnalyticsType',
          align: 'right',
          sortable: false,
          width: '90px',
        },
        {
          text: 'Start Date',
          value: 'StartDate',
          align: 'left',
          sortable: false,
          width: '110px',
        },
        {
          text: 'End Date',
          value: 'EndDate',
          align: 'left',
          sortable: false,
          width: '110px',
        },
        {
          text: 'Imps All Time',
          value: 'Impressions',
          align: 'left',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Imps Goal',
          value: 'ImpressionGoal',
          align: 'left',
          sortable: false,
          width: '90px',
        },
        {
          text: '% of Goal',
          value: 'ImpressionGoalPercent',
          align: 'left',
          sortable: false,
          width: '90px',
        },
      ];
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
  },
  methods: {
    formatTitle(title: string): string {
      const mapping = {
        originalSummary: 'Main Campaign',
        retargetingSummary: 'Retargeting Campaigns',
      };
      return mapping[title];
    },
    getTacticName(tactic: string): string {
      return utils.getTacticName(tactic);
    },
    closeModal(): void {
      this.$emit('close');
    },
    async getData(): void {
      this.hasError = false;

      const campaignsData = await orderService.getRetargetingSummary({
        advertiserId: this.advertiserId,
        campaignId: this.dashboardId,
        isShared: this.isShared,
      });

      if (campaignsData.error) {
        console.log('getRetargetingSummary error', campaignsData.error);
        this.campaigns = [];
        this.hasError = true;
        return;
      }

      let campaignsArray = [];

      for (const [key, value] of Object.entries(campaignsData)) {
        const title = key;
        let campaigns = [];
        for (const [tacticKey, tacticValue] of Object.entries(value)) {
          campaigns = tacticValue?.CampaignList;
        }
        campaignsArray.push({ title, campaigns });
      }

      // ensure that main campaign is always first
      const sortByRetType = (a, b) => {
        if (a.title === 'originalSummary') return -1;
        if (b.title === 'originalSummary') return 1;
        return 0;
      };
      campaignsArray = campaignsArray.sort(sortByRetType);
      this.campaigns = campaignsArray;
    },
    formatDate(date: string, type: string): string {
      try {
        if (!date) return type === 'end' ? 'No End Date' : 'No Start Date';
        return format(new Date(date), 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err.message || err);
      }
      return '';
    },
    formatNumber(value): string {
      if (value == null) {
        return '-';
      }
      return utils.formatNumberWithCommas(value);
    },
    getPacingStyle(value: string): string {
      if (value === '-') return 'pacing-na';
      if (!value) return 'pacing-none';
      const parsedValue = parseFloat(value);
      const [normalPacingBorder, goodPacingBorder, badPacingBorder] = [-5.0, 0.0, 5.0];
      if (parsedValue >= goodPacingBorder && parsedValue < badPacingBorder) {
        return 'pacing-good';
      } else if (parsedValue >= normalPacingBorder && parsedValue < goodPacingBorder) {
        return 'pacing-normal';
      } else return 'pacing-bad';
    },
    goToCampaign(item): void {
      if (this.isShared) return;
      const tactic = item.AnalyticsType;
      let tab = null;
      switch (tactic) {
        case 'VIDEO':
          tab = 'video';
          break;
        default:
          tab = tactic?.toLowerCase();
          break;
      }

      const query = {
        id: this.advertiserId,
        tab,
        viewCampaigns: item.CampaignId,
      };
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
  },
});
