import commonPkg from '@point/classes';

const TOOLTIPS = commonPkg.tooltips.METRIC_TOOLTIPS;

export const componentConfig = {
  cid: 'ordersummaryPerformanceImpsByGeo',
  id: 'ordersummaryPerformanceImpsByGeo',
  disabled: undefined,
  dataSource: 'ORDERSUMMARY.ByGeoImpression',
  breakpoints: ['lg9', 'sm6'],
  name: 'ImpsByGeo',
  title: 'Performance by Geo',
  __typename: 'ImpsByGeo',
  exportableTableData: null,
  nameKey: '',
  rootValueKey: 'ORDERSUMMARY',
  stacked: false,
  overrides: undefined,
  feedSource: undefined,
  noDataTooltips: ['no data'],
  xAxisRotate: false,
  horizontal: true,
  canvasHeight: '350px',
  sortBy: 'impressions',
  columns: ['city', 'impressions'],
  availableColumns: ['city', 'impressions'],
  searchEnabled: false,
  tooltips: [TOOLTIPS.ORDERS_BY_GEO],
};

export const componentHeight = '562';

export const dataSource = 'ORDERSUMMARY.ByGeoImpression';
