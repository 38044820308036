import commonPkg from '@point/classes';

const TOOLTIPS = commonPkg.tooltips.METRIC_TOOLTIPS;

export const componentConfig = {
  cid: 'ordersummaryPerformanceImpsByClient',
  id: 'ordersummaryPerformanceImpsByClient',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'ORDERSUMMARY.ByWeekdayImpression',
  breakpoints: ['lg9', 'sm6'],
  name: 'ImpsByClient',
  title: 'Performance By Day of Week',
  __typename: 'ImpsByClient',
  exportableTableData: 'xls',
  nameKey: '',
  rootValueKey: 'ORDERSUMMARY',
  stacked: false,
  overrides: undefined,
  feedSource: undefined,
  noDataTooltips: ['no data'],
  legendKeys: ['impressions', 'impressionPercent'],
  barCategory: 'Day',
  commonTweaks: ['daybackfill'],
  xAxisRotate: false,
  horizontal: true,
  canvasHeight: '350px',
  tooltips: [TOOLTIPS.ORDERS_BY_WEEKDAY],
};

export const componentHeight = '430px';

export const dataSource = 'ORDERSUMMARY.ByWeekdayImpression';
