
import Vue from 'vue';
import { format } from 'date-fns';
import utils from '../../../../util';

// components
import PublisherImage from './publisher-image.vue';
import AdPreview from './adPreview.vue';
import DataTableFooter from './DataTableFooter.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import { Tooltip } from '@point/classes/src/tooltips';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import { ExpandedTextAd } from '../../../../types/adPreview';
import tableHeaderC360 from './tableHeaderC360.vue';
import { C360Icon, getCssVar } from '@c360/ui';

let unwatchDataChanges: () => void;

export default Vue.extend({
  name: 'genericTableUI',
  props: [
    'componentConfig',
    'containerCSS',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'isPublisherTable',
    'sectionConfig',
    'selectedTacticFilter',
    'showAdPreview',
    'tableAttributes',
    'tableData',
    'tableHeaders',
    'tacticFilterList',
    'title',
    'titleClass',
    'tweaks',
    'lastModifiedDate',
    'feedSources',
  ],
  components: { PublisherImage, AdPreview, DataTableFooter, Tooltips, EditModuleBtn, tableHeaderC360, C360Icon },
  data: (): {
    timeMetrics: string[];
    phoneNumberMetrics: string[];
    tableOptions: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    };
    search: string;
    filterByTactic: boolean;
    dataWidths: object;
    tooltipText: string;
    searchOpen: boolean;
  } => ({
    timeMetrics: ['Time'],
    phoneNumberMetrics: utils.phoneMetrics,
    tableOptions: {
      page: 0,
      itemsPerPage: 7,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    filterByTactic: false,
    dataWidths: {},
    searchOpen: false,
    tooltipText:
      'Because Google updates Google AdWords data<br /> (specifically Conversions/Conversion Rate)<br /> on an ongoing, after the fact basis,<br /> the data here may be out of date with<br />what you can see on the Google UI',
  }),
  created() {
    if (this.componentConfig.sortBy) {
      this.tableOptions.sortBy = [this.componentConfig.sortBy];
      let sortDesc = true;
      // Reversed by default for Days of week key: DASH-3293
      // Dayparts of day key: DASH-3294
      const hasSpecificTweaks =
        this.tweaks && (this.tweaks?.includes('daybackfill') || this.tweaks?.includes('daypartbackfill'));
      // DailyPerformance Date ASC sorting: DASH-3300
      const isDailyPerformance = this.componentConfig?.dataSource.includes('ByDateImpression');
      if (hasSpecificTweaks || isDailyPerformance) {
        sortDesc = false;
      }
      this.tableOptions.sortDesc = [sortDesc];
      this.tableOptions.mustSort = true;
    }
    if (this.isExporting) {
      const cfg = this.exportData?.userState?.[this.componentConfig.cid];
      if (cfg?.sort) {
        this.tableOptions.sortBy = [cfg?.sort];
        this.tableOptions.sortDesc = [!!cfg?.desc];
      }
    }
    if (this.showAdPreview) {
      this.tableOptions.itemsPerPage = 5;
    }
    if (typeof this.componentConfig.perPage === 'number') {
      this.tableOptions.itemsPerPage = this.componentConfig.perPage;
    }
    if (this.isXLS) {
      this.tableOptions.itemsPerPage = 50000;
    }

    // if we're exporting, check if we should render a specific page index
    if (this.exportContext && this.componentConfig.multiPage) {
      const ctx = this.exportContext[this.componentConfig?.continueFrom];
      if (typeof ctx?.page === 'number') {
        this.tableOptions.page = ctx.page;
      }
    }
  },
  mounted() {
    if (!this.isExporting) {
      if (unwatchDataChanges) {
        unwatchDataChanges();
      }
      unwatchDataChanges = utils.fireOnAdDataChange(
        this,
        () => {
          this.$forceUpdate();
        },
        true,
      );
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    }

    this.$nextTick(() => {
      setTimeout(() => {
        // we have to wait for the data to be available to set the page index
        if (this.isExporting && typeof this.componentConfig.showPage === 'number') {
          this.tableOptions.page = this.componentConfig.showPage;
        }
      }, 100);
      setTimeout(this.getDataTableData, 200);
    });
  },
  beforeDestroy() {
    if (unwatchDataChanges) {
      unwatchDataChanges();
    }
  },
  methods: {
    selectTactic(tactic) {
      this.$emit('setTacticFilter', tactic);
    },
    watchSearch(val): void {
      this.search = val;
    },
    adPreviewData(id: string): ExpandedTextAd | null {
      let ads = utils.adDataForKey(this, this.componentDataSource);
      if (!ads || !Array.isArray(ads)) {
        // todo: if 'admin', message about the data not being right
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ads = ads.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      const found = ads.find(ad => ad?.CreativeId === id);

      let adData;
      if (found) {
        const url = found?.finalURL.split('["')[1].split('//')[1].split('/')[0];

        adData = {
          headline1: found?.headline1,
          headline2: found?.headline2,
          expandedTextAdHeadline3: found?.expandedTextAdHeadline3,
          description: found?.description,
          expandedTextAdDescription2: found?.expandedTextAdDescription2,
          finalURL: `https://${url}`,
        };
      }

      return adData;
    },
    getDataTableData(): void {
      let simpleExport = true;
      if (this.isXLS) {
        simpleExport = false;
      }
      if (simpleExport) {
        if (this.tableData.length === 0) {
          this.$emit('rendered', { empty: true });
        } else {
          // if this component is multi-page, keep track of where we are
          // if last page, don't set 'truncated'
          if (this.componentConfig.multiPage) {
            const perPage = this.tableAttributes['items-per-page'] || 8;
            const currentPage = this.tableOptions?.page || 1;
            if (currentPage * perPage <= this.tableData.length) {
              this.$emit('rendered', {
                id: this.componentConfig?.continueFrom,
                continueFrom: this.componentConfig?._id_version || this.componentConfig?._id,
                empty: false,
                truncated: true,
                page: currentPage,
              });
              return;
            }
            this.$emit('rendered', {
              id: this.componentConfig?.continueFrom,
              empty: false,
              continueFrom: this.componentConfig?._id_version || this.componentConfig?._id,
              truncated: false,
              page: currentPage,
            });
            return;
          }
          this.$emit('rendered', { empty: false });
        }
        return;
      }

      let ref = this.$refs.dataTable;
      const headers = [];
      let data = [];
      if (ref) {
        ref = ref.$el;
        if (ref) {
          const thead = ref.getElementsByTagName('thead');
          if (thead.length > 0) {
            const theaders = thead[0].getElementsByTagName('th');
            Array.from(theaders).forEach((header: HTMLScriptElement) => {
              headers.push(header?.textContent.trim());
            });
          }
          const tbody = ref.getElementsByTagName('tbody');
          if (tbody.length > 0) {
            const trows = tbody[0].getElementsByTagName('tr');
            if (trows.length > 0) {
              Array.from(trows).forEach((trow: HTMLScriptElement) => {
                const row = [];
                const cells = trow.getElementsByTagName('td');
                if (cells.length > 0) {
                  Array.from(cells).forEach((cell: HTMLTableCellElement) => {
                    row.push(cell?.textContent.trim());
                  });
                }
                data.push(row);
              });
            }
          }
        }
      }
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
        return;
      }

      if (this.componentConfig?.exportTweaks?.sortByHour) {
        const helper = {
          '12am': 0,
          '1am': 1,
          '2am': 2,
          '3am': 3,
          '4am': 4,
          '5am': 5,
          '6am': 6,
          '7am': 7,
          '8am': 8,
          '9am': 9,
          '10am': 10,
          '11am': 11,
          '12pm': 12,
          '1pm': 13,
          '2pm': 14,
          '3pm': 15,
          '4pm': 16,
          '5pm': 17,
          '6pm': 18,
          '7pm': 19,
          '8pm': 20,
          '9pm': 21,
          '10pm': 22,
          '11pm': 23,
        };
        data = data.sort((a, b) => helper[a[0]] - helper[b[0]]);
      }

      this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
    },
    downdloadData(): void {
      let csv = '';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.tableHeaders.forEach((header: any) => {
        // csv = csv + '"' + header.text + '",';
        csv = csv + header?.text + '\t';
      });
      csv = csv.substr(0, csv.length - 1) + '\n';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.tableData.forEach((data: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.tableHeaders.forEach((header: any) => {
          // csv = csv + '"' + data[header.value] + '",';
          csv = csv + data[header?.value] + '\t';
        });
        csv = csv.substr(0, csv.length - 1) + '\n';
      });
      // console.log('headers', this.tableHeaders, 'data', this.tableData);
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;

      const query = { ...this.$route.query };
      const title = this.componentConfig?.title || 'Table Data';
      const advId = query?.id || 'advertiser';
      const campId = query?.viewCampaigns || 'campaign';
      const now = new Date().getTime();
      downloadLink.download = `${title} - ${advId} - ${campId} - ${now}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    formatTime(time: string): string {
      const minutes = time.split(':')[1];
      return utils.formatHour(parseInt(time, 10), false).replace(/(am|pm)/, `:${minutes}$1`);
    },
    formatPhone(phone: string): string {
      return utils.formatPhoneNumber(phone);
    },
    formatFloatToPercent(num: string): string {
      return utils.formatFloatToPercent(num);
    },
    formatNumber(value): string {
      if (value === undefined) return '-';
      if (typeof value !== 'number') {
        let suffix = '';
        if (typeof value === 'string' && parseFloat(value)) {
          if (
            this.feedSources.includes('BEESWAX') && this.componentConfig.cid === 'simpgeofenceGeofencingLocationsTable') {
            // Format with two decimal places and then add commas
            return utils.formatNumberWithCommas(value);
          }
          if (value.includes('%') || value.endsWith('%')) suffix = '%'; // percent sign is lost when converting to a float
          return `${utils.formatNumberWithCommas(parseFloat(value))}${suffix}`;
        } else return value;
      }
      if (value < 1) return value.toString(); // handle decimals
      return utils.formatNumberWithCommas(value);
    },
    formatDate(date: string): string {
      try {
        if (date && date.length > 0 && date !== 'undefined') {
          const parsedDate = utils.normalizeDate(date);
          return format(parsedDate, 'MMM dd, yyyy');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('table date', date, err);
      }
      return '';
    },
    isIconOverriden(type: string): string | boolean {
      if (this.componentConfig.iconOverride) {
        return this.componentConfig.iconOverride[0][type];
      }
      return false;
    },
    getIcon(item: object): string {
      let icon = utils.metricIconMap[this.mainMetricKey];

      // special case to use icon based on value
      if (this.mainMetricKey === 'name') {
        const value = item[this.mainMetricKey];
        icon = utils.iconLibrary[value.toLowerCase()];
      }

      // manual override
      if (this.componentConfig.iconOverride) {
        icon = this.componentConfig.iconOverride[0][this.mainMetricKey];
      }
      return icon || '';
    },
    getIconC360(): string {
      const icon = utils.metricIconMapC360[this.mainMetricKey];
      return icon || '';
    },
    mainMetricTitle(item: object): { title: string; tooltip: string } {
      // returns a title and a tooltip. Most of the time the tooltip is the same as the title, but it supports a difference
      const metricTitle = { title: item[this.mainMetricKey], tooltip: item[this.mainMetricKey] };
      if (
        metricTitle?.title?.toLowerCase() === 'other' &&
        this.feedSources.includes('SIMPLIFI') &&
        this.title?.toLowerCase() === 'geofencing locations'
      ) {
        // only show this for Simpli.fi feeds in Geofencing Location module and when 'Other' is the value
        metricTitle.tooltip = 'Locations are not provided by data provider due to privacy reasons';
      } else if (metricTitle?.title?.toLowerCase() === 'other') {
        // for 'Other'
        metricTitle.title = 'Other';
        metricTitle.tooltip = 'Consolidation of records that could not be categorized';
      } else if (this.mainMetricKey === 'GeoFenceName') {
        metricTitle.title = this.fixGeoName(metricTitle?.title);
        metricTitle.tooltip = this.fixGeoName(metricTitle?.title);
      } else if (this.mainMetricKey === 'Date') {
        metricTitle.title = this.formatDate(metricTitle?.title);
        metricTitle.tooltip = this.formatDate(metricTitle?.title);
      }
      return metricTitle;
    },
    getTacticName(tactic: string): string {
      return utils.getTacticName(tactic);
    },
    changePage(direction: number): void {
      if (direction > 0 && this.tableOptions.page < this.totalPages) {
        this.tableOptions.page += 1;
      } else if (direction < 0 && this.tableOptions.page > 1) {
        this.tableOptions.page -= 1;
      }
    },
    fixGeoName(geoName: string): string {
      return geoName.replace('Ő', "'").replace('Đ', '-').replace('Ę', '');
    },
    facebookPostType(type: string): { icon: string; title: string } {
      let obj;
      switch (type) {
        case 'added_photos':
          obj = { icon: 'collections', title: 'Added photos' };
          break;
        case 'shared_story':
          obj = { icon: 'smart_display', title: 'Shared a story' };
          break;
        case 'mobile_status_update':
          obj = { icon: 'notes', title: 'Posted mobile status update' };
          break;
        default:
          obj = { icon: 'help_outline', title: 'Unknown' };
          break;
      }
      return obj;
    },
  },
  computed: {
    dataFilledTooltips(): Array<Tooltip> {
      if (!this.tableData?.length) return [];
      let tips = utils.dataFillTooltips(this.componentConfig?.tooltips, [
        { key: 'count', value: this.tableData.length - 1 },
      ]);
      // Necessary for DASH-2386, hint about what 'Content' is from Google Ads, also 3002 and 3003
      tips = utils.dataFillTooltipsHelper(this.componentConfig?.dataSource, tips);
      // tooltip if ADTONOS campaign exists on AUdio with Magnite Feedsource DASH-3113
      if (
        this.componentConfig?.dataSource.includes('AUDIO.ByPublisherImpression') &&
        this.feedSources.includes('MAGNITE')
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const checkAdtonos = this.tableData.find((data: any) => data?.Publisher?.toLowerCase().includes('adtonos'));
        if (checkAdtonos) {
          tips.push({
            title: 'ADTONOS',
            message:
              `<ul><li>ADTONOS(Google) are ads served on play.google.com</li>` +
              `<li>ADTONOS(iTunes) are ads served on apps.apple.com</li></ul>`,
          });
        }
      }

      // tooltip for Other and CSV/XLSX Export DASH-3837
      if (this.componentConfig?.cid === 'prerollPerformanceByPublisherTable') {
        if (this.feedSources.includes('SIMPLIFI')) {
          tips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Publishers that are not amongst the top 99
              Publishers. This module displays only 100 records.`,
          });
          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains only 100 Publishers. "Other" in the exports represents Publishers that are unknown and
              cannot be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.`,
          });
        } else {
          tips.push({
            title: 'Other',
            message: `Represents Publishers that are unknown and cannot be categorized into one of the other Publishers listed.`,
          });
          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Publishers broken down by "Tactic".`,
          });
        }
      }

      if (this.componentConfig?.cid === 'displayPerformanceBySiteTable') {
        let hasTipOther = false;

        if (this.feedSources.includes('SIMPLIFI')) {
          tips.forEach(tip => {
            if (tip.title === 'Other') {
              hasTipOther = true;
              tip.message =
                tip.message +
                ` In this module, it represents the sum of all the Sites that are not amongst the top 99 Sites.
                This module displays only 100 records.`;
            }
          });

          if (!hasTipOther) {
            tips.push({
              title: 'Other',
              message: `In this module, it represents the sum of all the Sites that are not amongst the top 99 Sites.
                This module displays only 100 records.`,
            });
          }

          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains only 100 Sites. "Other" in the exports represents Sites that are unknown and cannot
              be categorized into one of the other Sites listed or are not amongst the top 99 Sites.`,
          });
        } else {
          tips.forEach(tip => {
            if (tip.title === 'Other') {
              hasTipOther = true;

              if (
                this.feedSources.includes('BEESWAX') ||
                this.feedSources.includes('XANDR') ||
                this.feedSources.includes('ZYP')
              ) {
                tip.message =
                  'Represents Sites that are unknown and cannot be categorized into one of the other Sites listed.';
              } else {
                tip.message =
                  tip.message +
                  ` Represents Sites that are unknown and cannot be categorized into one of the other Sites listed.`;
              }
            }
          });

          if (!hasTipOther) {
            tips.push({
              title: 'Other',
              message: `Represents Sites that are unknown and cannot be categorized into one of the other Sites listed.`,
            });
          }

          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains all Sites broken down by "Tactic".`,
          });
        }
      }

      // tooltip for Other and CSV/XLSX Export DASH-3842
      if (
        this.componentConfig?.cid === 'simpgeofencePerformanceByPublisherTable' &&
        this.feedSources.includes('SIMPLIFI')
      ) {
        tips.push({
          title: 'Other',
          message: `In this module, it represents the sum of all the Publishers that are not amongst the top 99 Publishers.
            This module displays only 100 records.`,
        });
        tips.push({
          title: 'CSV/XLSX export',
          message: `Contains only 100 Publishers. "Other" in the exports represents Publishers that are unknown and cannot
            be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.
            <br><br>
            Publisher Names that are only shown as unique numbers and not names are publishers that are not exposed
            to us by the ad serving platform.`,
        });
      }

      if (this.feedSources.includes('GROUNDTRUTH')) {
        if (
          this.componentConfig?.cid === 'gtdisplayPerformanceByPublisherTable' ||
          this.componentConfig?.cid === 'gtvideoPerformanceByPublisherTable'
        ) {
          tips.push({
            title: 'Other',
            message: `Represents publishers that are unlisted, cannot be categorized into one of the other publishers or sites listed, 
              or are an aggregation of thousands of apps. There are about 15,000 publishers in the ad serving network and there would be too much granularity if all were shown. 
              The more important point is to focus on leveraging location data rather than app categories and sites.
              <br><br>
              Publisher Names that are only shown as unique numbers and not names are publishers
              that are not exposed to us by the ad serving platform.`,
          });
        }

        if (
          this.componentConfig?.cid === 'gtdisplayProductsTable' ||
          this.componentConfig?.cid === 'gtvideoProductsTable'
        ) {
          tips = [
            {
              title: 'Radial',
              message: `Impressions served to people who are near a predefined location. This is the most basic method
                of geofencing and can be customized to any size down to a 0.25-mile radius.`,
            },
            {
              title: 'Geotargeting',
              message: `Impressions served to people who meet specific targeting criteria within a defined radius.
                Unlike radial targeting, geotargeting does not require people to enter or exit a specific area to
                trigger content delivery. Instead, it allows for broader targeting, such as at the city or county level,
                as well as specific targeting at the zip code level.`,
            },
            {
              title: 'Retargeting Audience',
              message: `Impressions served to people who have been retargeted on mobile web, mobile in-app,
                display, desktop and CTV devices.`,
            },
            {
              title: 'Location Audience',
              message: `Impressions served to people on their mobile devices who have visited a specific
                brand or category within a specified timeframe, usually up to 90 days.`,
            },
            {
              title: 'Behavior Audience',
              message: `Impressions served to people based on their online and offline behavior.`,
            },
            {
              title: 'Lookalike Audience',
              message: `Impressions served to people who are categorized as a holistically similar
                audience based on certain shared interests and demographic traits that could result in
                your product or your specific brand resonating with them.`,
            },
          ];
        }
      }

      if (this.feedSources.includes('TRUEGEO')) {
        if (this.componentConfig?.cid === 'trugeofenceGeofencingLocationsTable') {
          tips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Geofencing Locations that are
              not amongst the top 99 Geofencing Locations. This module displays only 100 records.`,
          });
          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains only 100 Geofencing Locations. "Other" in the exports represents Geofencing
              Locations that are unknown and cannot be categorized into one of the other Geofencing Locations
              listed or are not amongst the top 99 Geofencing Locations.`,
          });
        }
      }

      // tooltip for Other and CSV/XLSX Export DASH-3838
      if (this.feedSources.includes('GAMDISPLAY') || this.feedSources.includes('GAMVIDEO')) {
        if (
          this.componentConfig?.cid === 'gamdisplayPerformanceByZipcode' ||
          this.componentConfig?.cid === 'gamvideoPerformanceByZipcode'
        ) {
          tips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Zip Codes that are not amongst the top 99 Zip Codes.
              This module displays only 100 records.`,
          });
          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains only 100 Zip Codes. "Other" in the exports represents Zip Codes that are unknown and cannot be
              categorized into one of the other Zip Codes listed or are not amongst the top 99 Zip Codes.`,
          });
        }

        if (
          this.componentConfig?.cid === 'gamdisplayPerformanceByCity' ||
          this.componentConfig?.cid === 'gamvideoPerformanceByCity'
        ) {
          tips.push({
            title: 'Other',
            message: `In this module, it represents the sum of all the Cities that are not amongst the top 99 Cities.
              This module displays only 100 records.`,
          });
          tips.push({
            title: 'CSV/XLSX export',
            message: `Contains only 100 Cities. "Other" in the exports represents Cities that are unknown and cannot
              be categorized into one of the other Cities listed or are not amongst the top 99 Cities.`,
          });
        }
      }

      if (this.componentConfig?.cid === 'googlevideoPerformanceByPublisherTable') {
        tips.push({
          title: 'Other',
          message: `In this module, it represents the sum of all the Publishers that are not
              amongst the top 99 Publishers. This module displays only 100 records.`,
        });
        tips.push({
          title: 'CSV/XLSX export',
          message: `Contains only 100 Publishers. "Other" in the exports represents Publishers
              that are unknown and cannot be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.`,
        });
      }

      if (
        (this.feedSources.includes('XANDR') ||
          this.feedSources.includes('MAGNITE') ||
          this.feedSources.includes('TRITON')) &&
        this.componentConfig?.cid === 'audioPerformanceByPublisherTable'
      ) {
        tips.push({
          title: 'Other',
          message: `In this module, it represents the sum of all the Publishers that are not amongst the top 99 Publishers.
            This module displays only 100 records.`,
        });
        tips.push({
          title: 'CSV/XLSX export',
          message: `Contains only 100 Publishers. "Other" in the exports represents Publishers that are unknown and cannot
            be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.`,
        });
      }

      const allZeroConversions = this.tableData.every(data => data.Conversions === 0);
      if (allZeroConversions) {
        tips = tips.filter(tip => !['Conversions', 'Conversion Rate'].includes(tip.title));
      }

      if (this.feedSources.includes('YAHOO')) {
        tips = tips.filter(tip => !['VCR', 'Viewing Hours'].includes(tip.title));
      }

      const zeroVisits = this.tableData.every(data => data.Visits === 0);
      if (zeroVisits) {
        tips = tips.filter(tip => !['Visits'].includes(tip.title));
      }

      return tips;
    },
    totalPages(): number {
      return Math.ceil(this.tableData.length / this.tableOptions?.itemsPerPage);
    },
    iconColor(): object {
      if (this.mainMetricKey === 'city') {
        return {
          color: getCssVar('--c360-color-compulse'),
        };
      }
      return {};
    },
    mainMetricKey(): string {
      return this.componentConfig?.columns[0];
    },
    integerValues(): string[] {
      return utils.tableMetricIntegerValues;
    },
    hasSEM(): boolean {
      return this.componentConfig?.dataChecks === 'HasSEM';
    },
    isXLS(): boolean {
      return this.exportData && this.exportData?.layout && this.exportData.layout?.fileType === 'XLS';
    },
    exportPagination(): string {
      if (!this.isExporting || !this.componentConfig?.multiPage || this.totalPages < 2) {
        return null;
      }
      return `${this.tableOptions?.page}/${this.totalPages}`;
    },
  },
  watch: {
    'tableOptions.sortBy': {
      async handler(newSort: string[]): Promise<void> {
        if (this.isExporting) {
          return;
        }
        const exportState = this.$store.getters.exportState || {};
        exportState[this.componentConfig.cid] = {};
        if (newSort?.length) {
          const sort = newSort[0];
          const desc =
            Array.isArray(this.tableOptions?.sortDesc) && this.tableOptions.sortDesc.length
              ? this.tableOptions.sortDesc[0]
              : null;
          exportState[this.componentConfig.cid] = { sort, desc };
        }
        this.$store.dispatch('setExportState', exportState);
      },
      immediate: false,
    },
  },
});
