
import Vue from 'vue';
import { summaryDaterangeDefault } from './constants';
import { mappedRange } from './utils';
import utils from '../../../../util';
import { format } from 'date-fns';
import DateRange from '../../../../types/dateRange';

export default Vue.extend({
  name: 'DateRangeDropdown',
  props: ['selectedRange'],
  data() {
    return {
      showMenu: false,
      customRangeSelected: false,
      dates: [],
    };
  },
  async created() {
    const advertiserId = this.$route.query.id;
    const summarystartdate = this.$route.query.summarystartdate;
    const summaryenddate = this.$route.query.summaryenddate;
    const summarydaterange = this.$route?.query.summarydaterange;

    await this.$store.dispatch('getSummaryDateRanges', { advertiserId, viewName: 'advertiserSummary' })
    
    if (summarystartdate && summaryenddate) {
      this.dates = [summarystartdate, summaryenddate]
    }
    if (summarydaterange === 'customRange') {
      this.customRangeSelected = true
    }
  },
  computed: {
    buttonText(): string {
      return this.customRangeSelected ? 'Custom Range' : `${this.selectedRange.RangeName}`;
    },
    dateRangeList(): Array<DateRange> {
      const dateranges = this.$store.state.customer?.summaryDateRanges?.dateranges;
      return dateranges ? dateranges?.map(rangeKey => mappedRange(rangeKey, summaryDaterangeDefault)) : [];
    },
    startDate(): string {
      return this.$store.state.customer?.summaryDateRanges?.start;
    },
    endDate(): string {
      return this.$store.state.customer?.summaryDateRanges?.end;
    },
    formattedStartDate(): string {
      return this.formatShortDate(this.dates[0]);
    },
    formattedEndDate(): string {
      return this.formatShortDate(this.dates[1]);
    },
  },
  methods: {
    selectRange(range): void {
      this.customRangeSelected = false;
      this.closeMenu();
      this.$emit('select-range', {...range});
    },
    applyCustomRange(): void {
      this.$emit('select-range', {RangeName: 'Custom Range', RangeKey: 'customRange', dates: this.dates});
    },
    isActive(rangeKey): boolean {
      return !this.customRangeSelected && this.selectedRange.RangeKey === rangeKey;
    },
    selectCustomRange(): void {
      this.customRangeSelected = true;
    },
    closeMenu(): void {
      this.showMenu = false;
    },
    formatShortDate(date): string {
      if (!date) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fnsDate;
      try {
        // date may come as a string or an already normalized date object
        if (typeof date === 'string') fnsDate = utils.normalizeDate(date);
        else fnsDate = date;

        return format(fnsDate, 'MMM d, yyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('formattedDataFrom', date, err);
      }
      return '';
    },
  },
});
