export const componentConfig = {
  cid: 'ordersummaryPerformanceSmallChart',
  id: 'ordersummaryPerformanceSmallChart',
  dataChecks: ['HasDisplay'],
  disabled: null,
  dataSource: 'ORDERSUMMARY.smallChartMetrics',
  breakpoints: ['lg3', 'sm12'],
  name: 'genericLineChart',
  title: '',
  __typename: 'genericLineChart',
  chartColorIndex: null,
  chartType: null,
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: true,
  availableLineSeriesKeys: ['Actual', 'Before'],
  lineSeriesKeys: ['Actual', 'Before'],
  showyAxisSplitLine: null,
  total: null,
  useTacticSummaryData: null,
  xAxisIntervalCount: 1,
  xAxisMaxItems: null,
  xAxisShowAll: true,
  xAxisSplitLineHide: null,
  yAxisHide: null,
  yAxisLineHide: null,
  xAxisRotateDeg: null,
  showOneLine: null,
  chartColors: null,
  sideTotalMetrics: null,
  tooltips: null,
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thismonth',
  fetchData: { method: 'getOrderInfo' },
};

export const componentHeight = '120px';
