
import Vue from 'vue';

export default Vue.extend({
  name: 'allCampaignNamesIfNeeded',
  components: {},
  props: ['exportData', 'exportContext'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({}),
  computed: {
    shouldDisplay(): boolean {
      return Array.isArray(this.exportData?.adData?.CampaignList) && this.exportData.adData.CampaignList.length > 1;
    },
  },
  mounted(): void {
    if (this.shouldDisplay) {
      setTimeout(() => {
        this.$emit('rendered', { empty: false });
      }, 1);
      return;
    }
    setTimeout(() => {
      this.$emit('rendered', { empty: true });
    }, 10);
  },
});
