
import Vue from 'vue';
import utils from '../../../../../util';

export default Vue.extend({
  inheritAttrs: false,
  mounted() {
    const src = this.getImgSrc();
    if (src) this.getImage(src.toLowerCase());
  },
  props: ['name', 'lookuplist'],
  data: (): {
    img: string | null;
  } => ({
    img: null,
  }),
  methods: {
    getImgSrc(): string {
      return this.name.split(' ').find((part: string) => {
        return this.lookuplist.has(part);
      });
    },
    // getDeviceLogoImages(): void {
    //   this.pieData.forEach((device: { name: string; imgSrc: string }) => {
    //     if (device.name !== 'Other') {
    //       const deviceName = device.name.split(' ');

    // try to match a part of the device name to a brand, (i.e) Amazon Firestick -> Amazon
    //       const brandName = deviceName.find((namePart: string) => {
    //         return this.brandList.find((brand: string) => brand.toLowerCase() === namePart.toLowerCase());
    //       });

    //       if (brandName) {
    //         this.getImage(brandName).then((imgSrc: string) => (device.imgSrc = imgSrc));
    //       } else {
    //         device.imgSrc = '';
    //       }
    //     }
    //   });
    // },
    async getImage(src: string): Promise<void> {
      // const imgName = publisher
      //   .toLowerCase()
      //   .split('-')
      //   .join('');

      // check if image exists
      try {
        const img = await utils.addImageProcess(`https://cdn-dashboard.adportal.io/dashboard/publishers/${src}.png`);
        this.img = img?.src;
      } catch {
        // eslint-disable-next-line no-console
        console.log(`missing image: https://cdn-dashboard.adportal.io/dashboard/publishers/${src}.png`);
      }
    },
  },
});
