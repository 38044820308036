
import Vue from 'vue';

export default Vue.extend({
  props: ['hideFromUI'],
  data: () => ({
    selection: '',
  }),
  created(): void {
    this.selection = this.hideFromUI;
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'hideFromUI', this.selection);
    },
  },
});
