
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'editCampaignModal',
  components: { C360Icon },
  data: () => ({
    selectedCampaign: null,
    editCampaignOpen: true,
    errorMessage: null,
  }),
  computed: {
    campaign() {
      let campaign = this.$store.state.customer.campaignAdPerformance?.CampaignList?.[0] || null;
      return { id: campaign?.CampaignId, ...campaign };
    },
    PIOID() {
      return this.campaign?.PIOID;
    },
    token(): string {
      return this.$store.state.customer.user.accessToken;
    },
  },
  watch: {
    campaign: {
      immediate: true,
      handler(newCampaign) {
        this.selectedCampaign = newCampaign;
      },
    },
    editCampaignOpen: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.closeModal();
        }
      },
    },
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    onError(error: any): void {
      this.$emit('on-error', error);
    },
    onChange(event: any): void {
      this.$emit('change', event);
    },
    onClosed(): void {
      this.editCampaignOpen = false;
    },
  },
});
