import axios from 'axios';
import { session_id } from '../main';

export type Metrics = {
  day: string;
  active: number;
};

export type Total = {
  activeTotal: number;
  percentage: string;
};

export type MetricsData = {
  total: Total;
  data: Metrics[];
};

export class OrderService {
  async getOrderInfo({ advertiserId, daterange }): Promise<MetricsData> {
    try {
      const headers = { session_id };
      const { data } = await axios.get(`/api/performance-overview/all-order-info/${advertiserId}/${daterange}`, {
        headers,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getSingleOrder({ advertiserId, orderId }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(`/api/advertiser/single-order/${advertiserId}/${orderId}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getSingleOrderMetrics({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(`/api/advertiser/single-order/metrics/${advertiserId}/${orderId}/${daterange}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByTacticAndPeriod({ advertiserId, orderId, daterange, period }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(
        `/api/advertiser/single-order/impressions-by-tactic/${advertiserId}/${orderId}/${daterange}/${period}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderCampaignList({ advertiserId, orderId }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(`/api/order/advertiser/${advertiserId}/order/${orderId}/campaign-static`);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByGeoList({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const headers = { session_id };
      const url = `/api/order/advertiser/${advertiserId}/order/${orderId}/impressions/geo-list/${daterange}`;
      const { data } = await axios.get(url, { headers });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderSummary({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const headers = { session_id };

      const url = `/api/advertiser/${advertiserId}/order/${orderId}/summary/${daterange}`;
      const { data } = await axios.get(url, { headers });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderList(params): Promise<MetricsData> {
    // TODO: add daterange once it's implemented on the backend, uncomment the line below
    const { offset, limit, sortBy, filterBy, orderName, orderStatus, advertiserId } = params;
    try {
      let filterString = '';
      filterString += `limit=${limit ?? 10}`;
      filterString += `&offset=${offset ?? '0'}`;
      if (sortBy) filterString += `&sortBy=${sortBy}`;
      if (filterBy) filterString += `&filterBy=${filterBy}`;
      if (orderName) filterString += `&orderName=${orderName}`;
      if (orderStatus && orderStatus !== 'All') filterString += `&orderStatus=${orderStatus}`;
      const headers = { session_id };
      // if (daterange) filterString += `&daterange=${daterange}`;
      const { data } = await axios.get(`/api/advertiser/order/summary/${advertiserId}/${filterString}`, { headers });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getCampaignList(params): Promise<any> {
    const { advertiserId, orderId, tactic, limit, offset, search, campaignStatus } = params;
    try {
      let filterString = 'sortby=end_asc';
      filterString += `&limit=${limit ?? 5}`;
      filterString += `&offset=${offset ?? '0'}`;
      filterString += `&search=${search ?? ''}`;
      if (campaignStatus && campaignStatus !== 'All') filterString += `&campaignStatus=${campaignStatus}`;
      if (tactic) filterString += `&type=${tactic}`;
      const { data } = await axios.get(
        `/api/advertiser/order/campaign-list/${advertiserId}/${orderId}/${filterString}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByTactic({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(
        `/api/advertiser/single-order/impressions-by-tactic-only/${advertiserId}/${orderId}/${daterange}/`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByWeekday({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(
        `/api/advertiser/single-order/impressions-by-weekday/${advertiserId}/${orderId}/${daterange}/`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByGeo({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(
        `/api/advertiser/single-order/impressions-by-geo/${advertiserId}/${orderId}/${daterange}/`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async getOrderImpressionsByZip({ advertiserId, orderId, daterange }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(
        `/api/advertiser/single-order/impressions-by-zip/${advertiserId}/${orderId}/${daterange}/`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  async updateFriendlyOrderName(advertiserId, orderId, friendlyName): Promise<MetricsData> {
    try {
      const { data } = await axios.post(`/api/order/update-friendly-name/`, {
        advertiserId,
        orderId,
        friendlyName,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  async getRetargetingSummary({ advertiserId, campaignId, isShared }): Promise<MetricsData> {
    try {
      const { data } = await axios.get(`/api/order/retargeting-summary/${advertiserId}/${campaignId}/${isShared}`);
      return data;
    } catch (error) {
      return error;
    }
  }
}
