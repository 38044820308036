
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import analytics from '../../../../mixins/analytics';

export default Vue.extend({
  components: { C360Icon },
  props: ['loading', 'title', 'items', 'value', 'itemText', 'itemKey', 'option', 'moduleName'],
  data(): {
    dropdownMenuOpen: boolean;
  } {
    return {
      dropdownMenuOpen: false,
    };
  },
  mixins: [analytics] as any,
  computed: {
    selectedText() {
      const selectedItem = this.items.find(item => this.getKey(item) === this.getKey(this.value));
      return selectedItem ? this.getItemText(selectedItem) : this.title;
    },
  },
  methods: {
    isActive(key: string): boolean {
      if (!key) return false;
      return this.getKey(this.value) === key;
    },
    getKey(item) {
      return item[this.itemKey];
    },
    getItemText(item) {
      return item[this.itemText];
    },
    select(item): void {
      this.option === 'daterange'
        ? this.analyticTrack(
            this.trackValue.POST_LOGIN,
            `${this.trackValue.CHANGE_DATERANGE}: ${this.moduleName}: ${this.getItemText(item)}`,
          )
        : this.analyticTrack(
            this.trackValue.POST_LOGIN,
            `${this.trackValue.CHANGE_SORTING}: ${this.getItemText(item)}`,
          );
      this.$emit('update:selected', item);
    },
  },
});
