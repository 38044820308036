
import axios, { AxiosResponse } from 'axios';
import { format, parse } from 'date-fns';
import DateRange from '../../../../types/dateRange';
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';
import { Campaign } from '../../../../types/filters';
import { eventBus } from '../../../../main';
import { C360Icon } from '@c360/ui';

export default {
  name: 'exportMenu',
  inheritAttrs: false,
  components: { C360Icon },
  props: ['currentSection', 'selection', 'advertiserView', 'allCampaigns'],
  mixins: [analytics],
  data: (): {} => ({
    menuOpened: false,
    selectedCampaignChoice: null,
    selectedFormatChoice: null,
    qbrLabel: 'Business Review PPTX',
    open: false,
    processing: [],
  }),
  watch: {
    selectedCampaignChoice: {
      handler(): void {
        // if we select multiple campaigns, and if PPTX/QBR is selected, go back to default choice
        if (this.selectedFormatChoice === this.qbrLabel && this.selectedCampaignChoice !== 'Selected') {
          this.selectedFormatChoice = this.exportFormatChoices[0];
        }
      },
    },
    '$store.state.filters.selectedCampaigns': {
      handler(): void {
        if (this.exportCampaignChoices.length > 0) {
          this.selectedCampaignChoice = this.exportCampaignChoices[0];
        }
      },
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stillProcessing(): any[] {
      return this.processing.filter(x => !x.done);
    },
    isButtonActive(): boolean {
      return Boolean(
        (this.selectedCampaignChoice || this.currentSection?.id === 'summary') && this.selectedFormatChoice,
      );
    },
    isSingleButton(): boolean {
      return this.advertiserView === 'campaign';
      // return false;
    },
    isXLSQBRButton(): boolean {
      return this.advertiserView === 'xlsqbr';
      // return true;
    },
    isSingleCampaignSelect(): boolean {
      const id = this.currentSection.id.toLowerCase();
      return ['emailsi'].includes(id);
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    campaignSelectionEnabled(): boolean {
      return !!this.currentSection?.enableCampaignSelection;
    },
    allCampaignsCount(): number {
      return this.currentCampaigns.length;
    },
    selectedCampaignsCount(): number {
      if (this.$store.state.filters?.selectedCampaigns?.length > 0) {
        return this.$store.state.filters.selectedCampaigns.length;
      }
      return 0;
    },
    currentCampaigns(): Array<object> {
      if (this.advertiserView) return this.$store.state.advertiserView?.campaigns?.Campaign;
      return this.$store.getters.currentTabCampaignList;
    },
    allowAll(): boolean {
      return this.currentCampaigns?.length <= 10;
    },
    exportable(): boolean {
      if (this.isShared && !Array.isArray(this.currentCampaigns)) {
        return false;
      }
      return this.pdfExportable || this.pptExportable || this.xlsExportable;
    },
    exportCampaignChoices(): string[] {
      const list: string[] = [];

      if (this.campaignSelectionEnabled && this.selectedCampaignsCount === 1) {
        list.push('Selected');
      }
      if (this.campaignSelectionEnabled && this.selectedCampaignsCount > 1) {
        if (utils.allowSingleCampaignView(this)) {
          list.push(`${this.selectedCampaignsCount} selected`);
        } else if (this.allowAll) {
          list.push('All');
        }
      }
      if (!this.campaignSelectionEnabled && this.allowAll) {
        list.push('All');
      }
      if (
        this.campaignSelectionEnabled &&
        this.allCampaignsCount > 1 &&
        this.allCampaignsCount !== this.selectedCampaignsCount &&
        !this.isSingleCampaignSelect &&
        this.allowAll
      ) {
        list.push(`All ${this.allCampaignsCount}`);
      }
      return list;
    },
    exportFormatChoices(): string[] {
      const list: string[] = [];
      if (this.pdfExportable && !this.isXLSQBRButton) {
        list.push('PDF');
      }
      if (this.xlsExportable) {
        list.push('XLSX');
      }
      if (this.pptExportable) {
        list.push(this.qbrLabel);
      }
      return list;
    },
    pdfExportable(): boolean {
      if (this.currentSection?.pdfExportLocalOnly) {
        return utils.isLocalDev() && !!this.currentSection?.pdfExport;
      }
      if (this.currentSection?.pdfExportDevOnly) {
        return utils.isDevelopment() && !!this.currentSection?.pdfExport;
      }
      return !!this.currentSection?.pdfExport;
    },
    pptExportable(): boolean {
      // same idea for youtube's summary view, enable for individual campaigns, disabled for summary view
      if (this.$route.query?.summaryView) {
        return false;
      }

      // available only to “Sinclair MCs”, “Sinclair Admins” and “Super Users”? Not to “Sinclair Clients” DASH-2515
      const user = this.$store.getters.user;
      if (!user || !utils.hasRight(user, ['QBR_ACCESS'])) {
        return false;
      }

      if (this.currentSection?.pptExportLocalOnly) {
        return utils.isLocalDev() && !!this.currentSection?.pptExport;
      }
      if (this.currentSection?.pptExportDevOnly) {
        return utils.isDevelopment() && !!this.currentSection?.pptExport;
      }
      return !!this.currentSection?.pptExport;
    },
    xlsExportable(): boolean {
      if (this.currentSection?.xlsExportLocalOnly) {
        return utils.isLocalDev() && !!this.currentSection?.xlsExport;
      }
      if (this.currentSection?.xlsExportDevOnly) {
        return utils.isDevelopment() && !!this.currentSection?.xlsExport;
      }
      return !!this.currentSection?.xlsExport;
    },
    selectedCampaignText(): string {
      // if combined view, hide amount of campaigns selected
      const text = utils.allowSingleCampaignView(this)
        ? `${this.selectedCampaignsCount} campaigns selected`
        : 'All Campaigns';
      return text;
    },
    advertiserViewLayout(): string {
      if (this.currentSection?.xlsAdvertiserExportLocalOnly && utils.isLocalDev()) {
        return this.currentSection.xlsAdvertiserExport;
      }
      if (this.currentSection?.xlsAdvertiserExportDevOnly && utils.isDevelopment()) {
        return this.currentSection.xlsAdvertiserExport;
      }
      return this.currentSection?.xlsExport;
    },
  },
  mounted(): void {
    let lastVisitedLinks = localStorage.getItem(`${this.$store.getters.user.email}:recentPages`);
    if (lastVisitedLinks) {
      lastVisitedLinks = JSON.parse(lastVisitedLinks);
      if (lastVisitedLinks[0].query.id === lastVisitedLinks[1].query.id) {
        this.processing = this.$store.state.customer.processing;
      } else {
        this.$store.commit('SET_PROCESSING', []);
      }
    }
    if (this.exportCampaignChoices.length > 0) {
      this.selectedCampaignChoice = this.exportCampaignChoices[0];
    }
    if (this.exportFormatChoices.length > 0) {
      this.selectedFormatChoice = this.exportFormatChoices[0];
    }
    eventBus.$on('exportComponentXLS', this.exportComponentXLS);
    eventBus.$on('exportComponentCSV', this.exportComponentCSV);
  },
  beforeDestroy() {
    eventBus.$off('exportComponentXLS', this.exportComponentXLS);
    eventBus.$off('exportComponentCSV', this.exportComponentCSV);
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportComponentXLS(payload: any) {
      this.exportSelection(payload, 'xls');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportComponentCSV(payload: any) {
      this.exportSelection(payload, 'csv');
    },
    convertSelectedFormatChoice(formatChoice: string): string {
      let fileType;
      if (formatChoice === 'PDF') {
        fileType = 'pdf';
      } else if (formatChoice === this.qbrLabel) {
        fileType = 'ppt';
      } else if (formatChoice === 'XLSX') {
        fileType = 'xls';
      }
      return fileType;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportPage(postData: any): void {
      // add the current timezone
      postData.timeZoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // const { selection, dateRanges /* , campaignAdPerformance */ } = this.$store.state.customer;
      let selection, dateRanges;
      if (this.selection) {
        selection = this.selection;
        dateRanges = { list: [] };
      } else {
        selection = this.$store.state.customer.selection;
        dateRanges = this.$store.state.customer.dateRanges;
      }
      let exportstartdate = selection.startdate;
      let exportenddate = selection.enddate;
      // let exportlastmodifieddate = campaignAdPerformance.LastModifiedDate;
      if (selection.daterange !== 'customRange' && dateRanges?.error !== 'empty') {
        const dateRange = dateRanges.list.find((r: DateRange) => {
          return r.RangeKey === selection.daterange;
        });
        if (dateRange) {
          exportstartdate = dateRange.StartDate;
          exportenddate = dateRange.EndDate;
        }
      }
      if (
        selection.campaigndaterange === 'customRange' &&
        this.$route.query.campaignstartdate &&
        this.$route.query.campaignenddate
      ) {
        exportstartdate = this.$route.query.campaignstartdate;
        exportenddate = this.$route.query.campaignenddate;
      }

      let filename = this.currentSection?.fileNameTemplate || '${advertiserId}-${fileDateTime}';
      // advertiserNameKey is the name of the advertiser, filename friendly characters
      // tacticModule is the 'frienldy' name of the tactic, with the name of the current module or 'all'
      let advertiserNameKey;
      if (this.advertiserView) advertiserNameKey = postData?.advertiserName || postData.id;
      else
        advertiserNameKey =
          this.$store.state.advertiser?.advertiserInfo?.data?.name ||
          this.$store.state.customer?.selection?.advertiserName;

      // const currentTactic =
      //   this.$store.state.customer?.dynamicLayout?.layoutCustomizations?.tactic ||
      //   this.$route.query.tab ||
      //   this.$store.state.customer?.sharedSelection?.tab;

      let campaignKey = this.$route.query.viewCampaigns;
      if (!this.selectedCampaignChoice || this.selectedCampaignChoice === 'All') {
        campaignKey = 'all';
      }
      if (
        this.allCampaignsCount > 1 &&
        this.allCampaignsCount === this.selectedCampaignsCount &&
        !this.isSingleCampaignSelect
      ) {
        campaignKey = 'all';
      }

      const tacticModule = `${utils.tacticTitleMap(postData.tab)}_${
        postData.componentTitle || postData.componentId || campaignKey || 'all'
      }`;
      filename =
        utils.renderTemplate(filename, {
          ...selection,
          ...postData,
          exportstartdate,
          exportenddate,
          currentDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          fileDateTime: format(new Date(), 'yyyy-MM-dd-HH-mm-ss'),
          advertiserNameKey: advertiserNameKey.toLowerCase().replace(/[^a-z0-9]/gi, ' '),
          tacticModule: tacticModule.toLowerCase().replace(/[^a-z0-9]/gi, ' '),
        }) || 'export';
      let extension = '.bin';
      if (postData.format === 'pdf') {
        extension = '.pdf';
      } else if (postData.format === 'ppt') {
        extension = '.pptx';
      } else if (postData.format === 'xls') {
        extension = '.xlsx';
      } else if (postData.format === 'csv') {
        extension = '.csv';
      }
      filename = filename.replace(/[^a-z0-9\-\._]/gi, '_') + extension;
      filename = filename
        .replace(/[_]{2,}/gi, '_')
        .replace(/[-]{2,}/gi, '-')
        .replace(/[-_]{2,}/gi, '-');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const download = (response: AxiosResponse<any>) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        const found = this.processing.find(x => x.title === filename);
        if (found) {
          found.done = true;
          this.$store.commit('SET_PROCESSING', this.processing);
        }
      };

      let prefix = postData.format || '';
      switch (prefix) {
        case 'pdf':
          prefix = 'PDF';
          break;
        case 'ppt':
          prefix = 'QBR';
          break;
        case 'xls':
          prefix = 'XLSX';
          break;
        case 'csv':
          prefix = 'CSV';
          break;
        default:
          break;
      }
      this.processing.push({ title: filename, done: false, error: false, type: prefix });
      this.$store.commit('SET_PROCESSING', this.processing);

      axios({
        method: 'post',
        url: `/api/exportDashboard`,
        data: postData,
        responseType: 'arraybuffer',
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: AxiosResponse<any>) => {
          download(response);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('export error', err);
          // this.$store.dispatch('showError', 'error exporting file, please try again');
          this.$store.dispatch('showError', { errorType: 'exportError' });
          const found = this.processing.find(x => x.title === filename);
          if (found) {
            found.done = true;
            found.error = true;
            this.$store.commit('SET_PROCESSING', this.processing);
          }
        });

      this.$store.dispatch('showError', {
        message: 'export started, please wait for download to start...',
        errorType: 'success',
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportSelection(payload?: any, fileType?: string): void {
      const params = this.getExportParams();
      if (!params) {
        return;
      }

      let componentId = null;
      if (payload?.id) {
        componentId = payload.id;
        params.componentId = componentId;
      }
      if (payload?.title) {
        params.componentTitle = payload.title;
      }

      if (fileType === 'pdf') {
        params.format = 'pdf';
        params.layout = this.currentSection?.pdfExport;
      } else if (fileType === 'ppt') {
        params.format = 'ppt';
        params.layout = this.currentSection?.pptExport;
      } else if (fileType === 'xls') {
        params.format = 'xls';
        params.layout = this.currentSection?.xlsExport;
      } else if (fileType === 'csv') {
        params.format = 'csv';
        params.layout = this.currentSection?.xlsExport;
      }

      if (this.advertiserView === 'campaign') {
        params.format = 'xls';
        params.layout = this.advertiserViewLayout;
      } else if (this.advertiserView === 'xlsqbr') {
        if (payload === 'XLSX') {
          params.format = 'xls';
          params.layout = this.currentSection?.xlsExport;
        } else {
          params.format = 'ppt';
          params.layout = this.currentSection?.pptExport;
        }
      }

      const exportFileType = fileType || this.convertSelectedFormatChoice(this.selectedFormatChoice);
      let campaigns = '';
      if (params.tab !== 'summary') {
        if (
          (!this.selectedCampaignChoice ||
            this.selectedCampaignChoice === 'All' ||
            this.selectedCampaignChoice.includes('All ')) &&
          Array.isArray(this.currentCampaigns)
        ) {
          campaigns = this.currentCampaigns.map((c: Campaign) => c.id).join(',');
          this.analyticExportTrack(
            this.trackValue.EVENT_NAV_EXPORT,
            this.trackValue.TRACK_NAV_EXPORT_ALL_CAMPAIGNS,
            exportFileType,
            utils.tabIdNameMap(params.tab),
            componentId,
          );
        }
        if (
          this.selectedCampaignChoice &&
          (this.selectedCampaignChoice === 'Selected' ||
            this.selectedCampaignChoice.includes(' campaigns selected') ||
            /^[\d]+[\s]+selected$/.test(this.selectedCampaignChoice)) &&
          Array.isArray(this.currentCampaigns)
        ) {
          const query = { ...this.$route.query };
          campaigns = query.viewCampaigns;
          if (!campaigns && Array.isArray(this.$store.state.filters.selectedCampaigns)) {
            campaigns = this.$store.state.filters.selectedCampaigns.map((c: Campaign) => c.id).join(',');
          }
          this.analyticExportTrack(
            this.trackValue.EVENT_NAV_EXPORT,
            this.trackValue.TRACK_NAV_EXPORT_SELECTED_CAMPAIGNS,
            exportFileType,
            utils.tabIdNameMap(params.tab),
            componentId,
          );
        }
      } else {
        this.analyticExportTrack(
          this.trackValue.EVENT_NAV_EXPORT,
          this.trackValue.TRACK_NAV_EXPORT_SELECTED_CAMPAIGNS,
          exportFileType,
          utils.tabIdNameMap(params.tab),
          componentId,
        );
      }
      // advertiser page campagins
      if (this.selection) {
        campaigns = this.selection.campaigns;
      }
      const withCampaigns = { ...params, campaigns };
      setTimeout(() => {
        this.menuOpened = false;
        this.open = false;
      }, 500);
      return this.exportPage(withCampaigns);
    },
    exportSharedCampaigns(): void {
      if (!Array.isArray(this.$store.state.customer?.sharedCampaigns)) {
        return;
      }
      const params = this.getExportParams();
      if (!params) {
        return;
      }
      const campaigns = this.$store.state.customer.sharedCampaigns.map((c: Campaign) => c.id).join(',');
      const withCampaigns = { ...params, campaigns };
      const exportFileType = this.convertSelectedFormatChoice(this.selectedFormatChoice);
      this.analyticExportTrack(
        this.trackValue.EVENT_NAV_EXPORT,
        this.trackValue.TRACK_NAV_EXPORT_ALL_CAMPAIGNS,
        exportFileType,
        utils.tabIdNameMap(params.tab),
      );
      return this.exportPage(withCampaigns);
    },
    getExportParams(): object | null {
      let layout;
      const fileType = this.convertSelectedFormatChoice(this.selectedFormatChoice);
      if (this.selectedFormatChoice === 'PDF') {
        layout = this.currentSection?.pdfExport;
      } else if (this.selectedFormatChoice === this.qbrLabel) {
        layout = this.currentSection?.pptExport;
      } else if (this.selectedFormatChoice === 'XLSX') {
        layout = this.currentSection?.xlsExport;
      }

      if (!layout) {
        // eslint-disable-next-line no-console
        console.error('export layout not found', this.selectedFormatChoice);
        return null;
      }
      let selection, dateRanges;
      if (this.selection) {
        selection = this.selection;
        dateRanges = { list: [] };
      } else {
        selection = this.$store.state.customer.selection;
        dateRanges = this.$store.state.customer.dateRanges;
      }
      // const { selection, dateRanges /* , campaignAdPerformance */ } = this.$store.state.customer;
      const rangeLimits = utils.getCampaignDatePickerRange(this);
      const query = { ...this.$route.query };

      let exportstartdate = selection.startdate;
      let exportenddate = selection.enddate;
      // let exportlastmodifieddate = campaignAdPerformance.LastModifiedDate;
      const exportdaterange = selection.daterange;
      if (selection.daterange !== 'customRange' && dateRanges?.list) {
        const dateRange = dateRanges.list.find((r: DateRange) => {
          return r.RangeKey === selection.daterange;
        });
        if (dateRange) {
          exportstartdate = dateRange.StartDate;
          exportenddate = dateRange.EndDate;
        }
      }

      if (selection.campaigndaterange === 'customRange' && query.campaignstartdate && query.campaignenddate) {
        exportstartdate = query.campaignstartdate;
        exportenddate = query.campaignenddate;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {
        id: selection.advertiserId,
        advertiserName: selection?.advertiserName,
        layout: layout,
        format: fileType,
        tab: query.tab || this.currentSection?.id,
        theme: this.Theme?.name,
        exportstartdate,
        exportenddate,
        // exportlastmodifieddate,
        exportdaterange,
      };
      if (selection.componentId) {
        params.componentId = selection.componentId;
      }
      if (selection.componentTitle) {
        params.componentTitle = selection.componentTitle;
      }
      if (query.view) {
        params.view = query.view;
      }
      if (query.daterange) {
        params.daterange = query.daterange;
      }
      if (query.startdate) {
        params.start = query.startdate;
      }
      if (query.enddate) {
        params.end = query.enddate;
      }
      if (query.summaryView) {
        params.summaryView = query.summaryView;
      }

      if (rangeLimits?.length === 2) {
        const addDayToEndDate = false;
        const readDate = (date: string) => {
          if (!date) return;
          try {
            const formats = {
              monthDayYear: /[0-9]{2}-[0-9]{2}-[0-9]{4}/,
              yearMonthDay: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
            };
            let parsedDate;
            if (formats.yearMonthDay.test(date)) {
              parsedDate = parse(date, 'yyyy-MM-dd', new Date());
              if (addDayToEndDate && date.indexOf('11:59') !== -1) {
                parsedDate.setDate(parsedDate.getDate() + 1);
              }
            } else if (formats.monthDayYear.test(date)) {
              parsedDate = parse(date, 'MM-dd-yyyy', new Date());
              if (addDayToEndDate && date.indexOf('11:59') !== -1) {
                parsedDate.setDate(parsedDate.getDate() + 1);
              }
            } else {
              parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss.SSS a', new Date()); // parse custom date format
              if (addDayToEndDate && date.indexOf('11:59') !== -1) {
                parsedDate.setDate(parsedDate.getDate() + 1);
              }
            }
            return format(parsedDate, 'yyyy-MM-dd');
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log('min max date', date, err);
          }
          return '';
        };
        params.exportcampaignstartdate = readDate(rangeLimits[0]);
        params.exportcampaignenddate = readDate(rangeLimits[1]);
      }

      if (this.$store.state.customer?.sharedSelection?.campaignstartdate) {
        params.campaignstartdate = this.$store.state.customer.sharedSelection.campaignstartdate;
      }
      if (this.$store.state.customer?.sharedSelection?.campaignenddate) {
        params.campaignenddate = this.$store.state.customer.sharedSelection.campaignenddate;
      }
      // pass the valid sections for the summary page.
      if (Array.isArray(this.$store.state?.customer?.validsections)) {
        params.validSections = this.$store.state.customer.validsections.join(',');
      }
      if (query.campaignstartdate) {
        params.campaignstartdate = query.campaignstartdate;
      }
      if (query.campaignenddate) {
        params.campaignenddate = query.campaignenddate;
      }
      if (query.campaigndaterange) {
        params.campaigndaterange = query.campaigndaterange;
      }
      if (this.isShared) {
        params.etoken = this.$store.state.customer?.sharedSelection?.etoken;
      }
      if (this.$store.getters.user?.email) {
        params.email = this.$store.getters.user.email;
      }
      if (this.$store.getters.exportState) {
        params.exportstate = this.$store.getters.exportState;
      }
      // get the agency partner value from selection when shared, fallback to theme is nothing found.
      const agencyPartner = this.isShared
        ? this.$store.state.customer?.selection?.agencyPartner
        : this.$store.state.customer?.dynamicLayout?.agency;
      params.subAgencyPartner = agencyPartner || this.Theme.companyId; // this is the id used for the agency

      params.agencyOfCampaign = this.$store.state?.advertiser?.advertiserInfo?.data?.AgencyPartnerName || '';
      params.rootdomain = this.Theme.rootDomain;

      const lowHost = window.location.hostname.toLowerCase();
      const hostSegments = lowHost.split('.');
      if (hostSegments.includes('adportal')) {
        params.rootdomain = lowHost;
      }

      params.feedSources = utils.feedSources(this);
      return params;
    },
  },
};
