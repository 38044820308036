
import Vue from 'vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
import utils from '../../../../util';

// components
import SiteImpactGraphic from './siteImpactCanvas.vue';

const safeColors = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
];

let unwatchDataChanges: () => void;

export default Vue.extend({
  name: 'siteImpactTableUI',
  props: [
    'componentConfig',
    'currentCampaign',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'sectionConfig',
    'siteImpactCreative',
    'tableData',
    'tableHeaders',
    'title',
    'multipleCampsSelected',
  ],
  components: { SiteImpactGraphic, ExportDownloadBtn },
  data: (): {
    updateCount: number;
    tableRowIndexes: object;
    tableOptions: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    };
  } => ({
    tableRowIndexes: {},
    updateCount: 0,
    tableOptions: {
      page: 0,
      itemsPerPage: 20,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
  }),
  created() {
    if (this.componentConfig?.sortBy) {
      this.tableOptions.sortBy = [this.componentConfig.sortBy];
      this.tableOptions.sortDesc = [true];
      this.tableOptions.mustSort = true;
    }

    if (typeof this.componentConfig?.perPage === 'number') {
      this.tableOptions.itemsPerPage = this.componentConfig.perPage;
    }

    if (this.isExporting) {
      const cfg = this.exportData?.userState?.[this.componentConfig.cid];
      if (cfg?.sort) {
        this.tableOptions.sortBy = [cfg?.sort];
        this.tableOptions.sortDesc = [!!cfg?.desc];
      }
    }
  },
  mounted() {
    if (!this.sectionConfig || !this.componentConfig) {
      // eslint-disable-next-line no-console
      console.error('siteImpactTableUI: missing sectionConfig or componentConfig');
      return; // exit if we're missing these required properties
    }
    if (this.isExporting) {
      if (this.isXLS) {
        this.exportTableData();
        return;
      }
      if (this.multipleCampsSelected) {
        // not waiting on graphic to load
        setTimeout(() => {
          this.$emit('rendered', { empty: false });
        }, 100);
      }
    } else {
      if (unwatchDataChanges) {
        unwatchDataChanges();
      }
      unwatchDataChanges = utils.fireOnAdDataChange(
        this,
        () => {
          this.$forceUpdate();
          this.updateCount += 1;
        },
        true,
      );
      setTimeout(() => {
        this.$forceUpdate();
        this.updateCount += 1;
      }, 1000);
    }
    window.addEventListener('optimizedResize', this.resize);
  },
  beforeDestroy() {
    if (unwatchDataChanges) {
      unwatchDataChanges();
    }
    window.removeEventListener('optimizedResize', this.resize);
  },
  computed: {
    siteImpactColors(): string[] {
      return [...this.Theme.config.chartColors, ...safeColors];
    },
    canExportToXLS(): boolean {
      return !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
  },
  methods: {
    siteImpactLinkColor(link): string | null {
      const colors = this.siteImpactColors;
      const index = parseInt(link.Index, 10);
      if (typeof index === 'number') {
        return colors[index];
      }
      return '';
    },
    resize(): void {
      this.updateCount += 1;
    },
    graphicRenderFailure(): void {
      setTimeout(() => {
        this.$emit('rendered', { empty: true });
      }, 50);
    },
    setTableIndexes(): void {
      if (!this.$refs.siteImpactTable) {
        // eslint-disable-next-line no-console
        console.log('renderLinks, table not found');
        return;
      }

      const tableEl = this.$refs.siteImpactTable.$el;
      const tableCells = tableEl.getElementsByTagName('td');

      let trIndex = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newIndexes: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Array.from(tableCells).forEach((cell: any) => {
        if (cell.children?.length >= 1 && cell.children[0].tagName === 'DIV' && cell.children[0].title) {
          newIndexes[cell.children[0].getAttribute('data-index')] = trIndex;
          trIndex += 1;
        }
      });
      this.tableRowIndexes = newIndexes;

      setTimeout(() => {
        // notify parent rendering complete
        this.$emit('rendered', { empty: false });
      }, 250);
    },
    formatFloatToPercent(num: string): string {
      return utils.formatFloatToPercent(num);
    },
    isIcon(type: string): boolean {
      return this.componentConfig.columns.includes(type);
    },
    exportTableData(): void {
      const headers = this.tableHeaders.map(h => h.text);
      let data = [...this.tableData].sort((a, b) => b.Clicks - a.Clicks);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data = data.map((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.tableHeaders.map((h: any) => {
          if (h.value === 'ClickRate') {
            return this.formatFloatToPercent(item.ClickRate);
          }
          return item[h.value];
        });
      });
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    trackSortStates(): void {
      this.updateCount += 1;

      if (this.isExporting) {
        return;
      }

      const exportState = this.$store.getters.exportState || {};
      exportState[this.componentConfig.cid] = {};
      const desc =
        Array.isArray(this.tableOptions?.sortDesc) && this.tableOptions.sortDesc.length
          ? this.tableOptions.sortDesc[0]
          : null;
      const sort =
        Array.isArray(this.tableOptions?.sortBy) && this.tableOptions.sortBy.length
          ? this.tableOptions.sortBy[0]
          : null;
      exportState[this.componentConfig.cid] = { sort, desc };

      this.$store.dispatch('setExportState', exportState);
    },
  },
});
