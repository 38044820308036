
import Vue from 'vue';
import CampaignDetails from './campaignDetails.vue';
import util from '../../../util';
import { Campaign } from '../../../types/filters';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'CampaignNavList',
  components: { CampaignDetails, C360Icon },
  data(): {
    disableTemp: boolean;
  } {
    return {
      disableTemp: false,
    };
  },
  props: ['campaignList', 'selectedCampaigns', 'searchText', 'showSelected'],
  computed: {
    campaignDetailsById(): object {
      return util.allCampaignDetailsById(this);
    },
    tacticTitle(): string {
      if (this.$store.state.customer?.dynamicLayout?.layoutCustomizations?.tactic) {
        return this.$store.state.customer.dynamicLayout.layoutCustomizations.tactic;
      }
      return this.$route.query.tab || this.$store.state.customer?.sharedSelection?.tab;
    },
    selectedCampaignIds(): Array<string> {
      return this.selectedCampaigns.map(campaign => campaign.id);
    },
    filteredCampaigns(): Array<Campaign> {
      if (this.showSelected) {
        return this.campaignList.filter(campaign => this.selectedCampaignIds.includes(campaign.id));
      }
      return this.campaignList;
    },
    isDisabled(): boolean {
      return this.selectedCampaigns?.length >= 200;
    },
  },
  methods: {
    selectCampaign(campaign): void {
      if (!this.isDisabled || this.isSelected(campaign?.id)) this.$emit('select-campaign', campaign);
    },
    isSelected(id: number | string): boolean {
      return this.selectedCampaigns?.some(c => c.id === id);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    truncateName(campaign: any): string {
      if (campaign?.FriendlyName?.length > 56) {
        return (
          campaign.FriendlyName.substring(0, 20) +
          '[...]' +
          campaign.FriendlyName.substring(campaign.FriendlyName.length - 30, campaign.FriendlyName.length)
        );
      }
      return campaign.FriendlyName;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitCampaign(campaign: any): void {
      if (!this.isDisabled || this.isSelected(campaign?.id)) {
        this.disableTemp = true;
        setTimeout(() => {
          this.disableTemp = false;
        }, 400);
        this.$emit('select-campaign', campaign);
      }
    },
  },
});
