
import Vue from 'vue';
import Cards from '../components/topMetrics/cards.vue';
import Overlay from '../components/topMetrics/overlay.vue';
import List from '../components/topMetrics/list.vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'summaryMetricsController',
  inheritAttrs: false,
  props: [
    'campaignId',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'config',
    'exportData',
    'exportContext',
    'mapAdData',
    'hasMapData',
    'sectionConfig',
    'template',
  ],
  data: (): {} => ({}),
  components: { Cards, Overlay, List },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort(): any {
      return utils.customSort;
    },
    templateStyle(): object {
      const styles = {};
      if (this.template === 'cards' && this.hasMapData) {
        styles['max-height'] = `${this.$store.state.summary.dimensions.mapHeight}px`;
      }
      return styles;
    },
  },
  methods: {
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
});
