import { NormalizedPolygonData } from './types';
import utils from '../../../../util';

export const normalizePolygonData = (data, existingPolygonData, isExporting): NormalizedPolygonData => {
  let hasPolygons = false;
  const newPolygonData = { ...existingPolygonData };

  Object.keys(data).forEach((k: string) => {
    const poly = data[k];
    if (k === 'LocationNotFound') {
      if (!isExporting) {
        // eslint-disable-next-line no-console
        console.log('polygons not found', poly);
      }
      return;
    }
    if (poly.WKT_P100) {
      hasPolygons = true;
      switch (poly.ITYPE) {
        case 'DMA': {
          const key = `dmacodes-${poly.DMA_CODE}`;
          if (!newPolygonData[key]) {
            // eslint-disable-next-line no-console
            console.log('received polygon data for unexpected geo', poly);
            break;
          }
          newPolygonData[key] = {
            ...newPolygonData[key],
            poly: { key: k, ...poly },
          };
          break;
        }
        case 'ZIP': {
          const key = `zips-${k}`;
          if (!newPolygonData[key]) {
            // eslint-disable-next-line no-console
            console.log('received polygon data for unexpected geo', poly);
            break;
          }
          newPolygonData[key] = {
            ...newPolygonData[key],
            poly: { key: k, ...poly },
          };
          break;
        }
        case 'STAT': {
          const key = `states-${k}`;
          if (!newPolygonData[key]) {
            // eslint-disable-next-line no-console
            console.log('received polygon data for unexpected geo', poly);
            break;
          }
          newPolygonData[key] = {
            ...newPolygonData[key],
            poly: { key: k, ...poly },
          };
          break;
        }
        case 'CITY': {
          const key = `cities-${k}`;
          if (!newPolygonData[key]) {
            // eslint-disable-next-line no-console
            console.log('received polygon data for unexpected geo', poly);
            break;
          }
          newPolygonData[key] = {
            ...newPolygonData[key],
            poly: { key: k, ...poly },
          };
          break;
        }
        case 'OUTERMARKET':
          Object.keys(newPolygonData).forEach(geoKey => {
            const geo = newPolygonData[geoKey];
            if (geo.outerMarket?.GeoParamVal === k) {
              newPolygonData[geoKey] = {
                ...geo,
                outerMarket: {
                  ...geo.outerMarket,
                  poly: { key: k, ...poly },
                },
              };
            }
          });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log('not handled polygon ITYPE', poly);
          break;
      }
    }
  });

  return {
    polygonData: newPolygonData,
    hasPolygons,
  };
};

const tacticTitles = {
  PREROLL: 'Video',
  OTT: 'OTT/CTV',
  BROADSTREET: 'Broadstreet - O&O',
  DISPLAY: 'Display',
  SOCIAL: 'Facebook Ads',
  VIDEO: 'Digital Video',
  LINEAR: 'Sinclair RSN',
  GAMVIDEO: 'Video - O&O',
  GAMDISPLAY: 'Display - O&O',
};

export const getTooltipContent = (x, p) => {
  let key = 'Impressions';
  const val = utils.formatNumberWithCommas(x.Impressions);

  if (tacticTitles[x.tactic]) {
    key = tacticTitles[x.tactic];
  }

  return {
    key,
    val,
  };
};
