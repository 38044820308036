
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'ValueKeys',
  props: ['availableValueKeys', 'valueKey'],
  data: (): {
    selection: Array<string>;
  } => ({
    selection: null,
  }),
  created(): void {
    this.selection = this.valueKey;
  },
  computed: {
    keyOptions(): string {
      return this.availableValueKeys?.map(val => {
        return {
          text: utils.headerNamesMap(val),
          value: val,
        };
      });
    },
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'valueKey', this.selection);
    },
  },
});
