
import Vue from 'vue';
import {
  componentConfig,
  dataSource,
  sectionConfig,
  componentHeight,
  isExporting,
  mappingTacticsNames,
} from './utils/configPieChart';
import { OrderService } from '../../../services/order-summary';

const orderService = new OrderService();

interface TransformedFormat {
  name: string;
  sortableValue: number;
  value: string;
  valueKey: string;
  valueKeyData: number;
  percentage: string;
}

export default Vue.extend({
  name: 'ImpressionsByTactic',
  data: (): {
    impressions: any[];
    loading: boolean;
    componentHeight: string;
    componentConfig: any;
    dataSource: string;
    sectionConfig: any;
    isExporting: boolean;
    daterange: string;
  } => ({
    componentHeight: componentHeight,
    loading: false,
    impressions: [],
    componentConfig,
    dataSource,
    sectionConfig,
    isExporting,
    daterange: 'thismonth',
  }),
  components: {
    PieChart: () => import('../../components/charts/pie/pie.vue'),
  },
  watch: {
    daterange: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getImpressionsByTactic(true);
        }
      },
    },
    singleOrderParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any): void {
        if (!newVal?.daterange) return;
        this.daterange = newVal.daterange;
      },
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  computed: {
    singleOrderParams(): any {
      return this.$store.state.order.singleOrderParams;
    },
  },
  methods: {
    async getData() {
      await this.updateImpressionsByTactic(true);
    },
    async updateImpressionsByTactic(withLoading: boolean) {
      await this.getImpressionsByTactic(withLoading);
    },
    transformData(data: any): TransformedFormat {
      return {
        name: mappingTacticsNames[data.type] || data.type,
        sortableValue: parseFloat(data.impressionsPercentage.replace('%', '')),
        value: data.impressionsPercentage.replace('%', ''),
        valueKey: 'Impressions',
        valueKeyData: data.impressions,
        percentage: data.percentageWithPrevious,
      };
    },
    async getImpressionsByTactic(withLoading: boolean) {
      try {
        this.loading = withLoading;
        const impressionsData = await orderService.getOrderImpressionsByTactic({
          daterange: this.daterange,
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
        });
        this.impressions = impressionsData?.map(this.transformData);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
