
import Vue from 'vue';
import { SetCampaignFriendlyName } from '../../../store/modules/customer/types';
import { Campaign } from '../../../types/filters';
import { C360Icon } from '@c360/ui';
import { OrderService } from '../../../services/order-summary';

const orderService = new OrderService();

export default Vue.extend({
  name: 'CampaignFriendlyName',
  props: ['campaign', 'advertiserId', 'isOrder', 'settedFriendlyName'],
  components: { C360Icon },
  data(): {
    friendlyName: string;
    error: string | null;
    loading: boolean;
  } {
    return {
      friendlyName: '',
      error: null,
      loading: false,
    };
  },
  created() {
    const campaign: Campaign = this.campaign;
    this.friendlyName = this.settedFriendlyName || campaign.FriendlyName;
  },
  methods: {
    reset(): void {
      this.friendlyName = this.campaign.name;
    },
    toText(input): string {
      const div = document.createElement('div');
      div.innerHTML = input;
      const text = div.textContent || div.innerText || '';
      return text;
    },
    async saveName(): Promise<void> {
      const campaign: Campaign = Object.assign({}, this.campaign); // create a copy so we can update the friendly name and mutate it
      campaign.FriendlyName = encodeURIComponent(this.toText(this.friendlyName));
      const payload: SetCampaignFriendlyName = {
        advertiserId: this.advertiserId,
        campaignId: campaign.id,
        friendlyName: campaign.FriendlyName,
        campaignSource: campaign.FeedSource,
      };

      this.loading = true;

      const response: { success: boolean; error?: string } = await this.$store.dispatch(
        'setCampaignFriendlyName',
        payload,
      );
      if (!response.success && response.error) {
        this.loading = false;
        this.error = response.error;
      } else {
        interface Campaigns {
          defaultdaterange: string;
          endDate: string;
          startDate: string;
        }

        const state: Campaigns = this.$store.state.customer?.campaigns;

        await this.$store.dispatch('getCampaigns', {
          advertiserId: this.advertiserId,
          daterange: state.defaultdaterange,
          startdate: state.startDate,
          enddate: state.endDate,
          campaigns: [],
        });

        await this.$store.dispatch('getAllTimeCampaigns', {
          advertiserId: this.advertiserId,
          daterange: 'alltime',
        });
        // await this.$store.dispatch('setSelectedCampaigns', [campaign]);
        this.loading = false;
        this.$emit('close');
      }
    },
    async updateOrderFriendlyName(): void {
      this.loading = true;

      const response = await orderService.updateFriendlyOrderName(
        this.advertiserId,
        this.campaign.OrderId,
        this.friendlyName,
      );
      if (response?.Attributes[0]?.Value) {
        this.$store.dispatch('showError', {
          message: 'Order Friendly Name was successfully updated',
          errorType: 'success',
        });
        this.friendlyName = response?.Attributes[0]?.Value;
        this.$emit('setNewFriendlyName', response?.Attributes[0]?.Value);
      } else if (response.error) {
        console.log('error updating order friendly name', response.error);
        this.$store.dispatch('showError', {
          message: 'Error changing Order Friendly Name, please try again',
          errorType: 'error',
        });
      }
      this.$emit('close');
      this.loading = false;
    },
  },
  computed: {
    valid(): boolean {
      return this.friendlyName?.length > 3;
    },
    originalName(): boolean {
      return this.friendlyName === this.campaign.name;
    },
  },
});
