
import { format, parse } from 'date-fns';
import Vue from 'vue';
import utils from '../../../../../util';
import analytics from '../../../../../mixins/analytics';
import Tooltips from '../../../charts/tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../../../charts/buttons/exportDownloadBtn.vue';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import debounce from 'lodash.debounce';
import { C360Icon } from '@c360/ui';
import RetargetingModal from '../../../../order/components/orderRetargetingCampaignsModal.vue';

export default Vue.extend({
  name: 'tacticTables',
  inheritAttrs: false,
  components: {
    Tooltips,
    ExportDownloadBtn,
    C360Icon,
    RetargetingModal,
  },
  props: [
    'table',
    'search',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'tableHeaders',
    'itemsPerPage',
    'template',
    'sectionConfig',
    'componentConfig',
    'tableData',
    'fetchedItems',
    'isProductSummary',
  ],
  data: (): {
    currentPage: number;
    showModal: boolean;
    selectedItem: number;
  } => ({
    currentPage: 1,
    showModal: false,
    selectedItem: null,
  }),
  mixins: [analytics],
  watch: {
    itemsPerPage: {
      handler(): void {
        if (this.isOrder) return;
        // reset to page one when items per page changes
        this.pageChange(0);
      },
    },
    search(): void {
      if (this.isOrder) return;
      this.recordSearchToMP();
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort(): any {
      return utils.customSort;
    },
    isItemBeingFetched(): boolean {
      if (!(this.isOrder || this.isProductSummary)) return false;
      if (this.fetchedItems.includes(this.table.type)) return true;
      return false;
    },
    campaignsLength(): number {
      if (this.isProductSummary) return this.tableData.totalObjects;
      if (this.isOrder) return this.tableData.totalObjects;
      return this.filteredTableCampaigns?.length;
    },
    totalPages(): number {
      if (this.isProductSummary) return this.tableData.totalPages;
      if (this.isOrder) return this.tableData.totalPages;
      return Math.ceil(this.filteredTableCampaigns.length / this.itemsPerPage);
    },
    pageText(): string {
      const totalitems = this.campaignsLength;
      let remainder = 0;

      if (this.isOrder) {
        // let firstNumber = this?.tableData?.campaigns?.length * this?.tableData?.currentPage + 1;
        const firstNumber = this?.tableData?.limit * this?.tableData?.currentPage + 1;

        // if (this?.tableData?.currentPage === this?.tableData?.totalPages) {
        //   firstNumber = this.campaignsLength - this?.tableData?.campaigns?.length;
        // }
        const secondNumber = firstNumber + this.tableData.campaigns.length - 1;
        return `${firstNumber}-${secondNumber} of ${totalitems}`;
      }

      if (this.isProductSummary) {
        const firstNumber = 1 + this.itemsPerPage * (this.currentPage - 1);
        const secondNumber = firstNumber + this.tableData.campaigns.length - 1;
        return `${firstNumber}-${secondNumber} of ${totalitems}`;
      }

      if (totalitems) {
        remainder = totalitems % this.itemsPerPage;
      }

      const firstNumber = 1 + this.itemsPerPage * (this.currentPage - 1);
      let secondNumber;
      if (this.currentPage === this.totalPages && remainder >= 1) {
        secondNumber = (this.currentPage - 1) * this.itemsPerPage + remainder;
      } else {
        secondNumber = this.currentPage * this.itemsPerPage;
      }
      if (firstNumber === secondNumber) {
        return `${firstNumber} of ${totalitems}`;
      }
      return `${firstNumber}-${secondNumber} of ${totalitems}`;
    },
    isOrder(): boolean {
      return this.$route?.query?.tab?.includes('order');
    },
    campaignList() {
      if (this.isProductSummary) return this.tableData?.campaigns;
      if (this.isOrder) return this?.table?.campaigns;
      if (
        this.$store.state.customer.campaigns?.campaignlist &&
        Array.isArray(this.$store.state.customer.campaigns.campaignlist) &&
        this.$store.state.customer.campaigns.campaignlist.length > 0
      ) {
        return this.$store.state.customer.campaigns.campaignlist.filter(
          campaign => campaign.CampaignType.toLowerCase() === this.table.analyticsType.toLowerCase(),
        );
      }
      return [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableCampaigns(): any {
      const campaigns = this.table?.campaigns;
      if (!campaigns) return [];
      return utils.sortByMostRecentDate(campaigns, 'StartDate');
    },
    filteredTableCampaigns() {
      let filteredData = this.tableCampaigns;

      // PRODDASH-1301 related to DASH-3091 filter out 'Amazon_Display' & 'Amazon_Video' for OTT
      filteredData = filteredData.filter(c => {
        if (
          c?.FriendlyName?.toLowerCase().includes('amazon_display') ||
          c?.FriendlyName?.toLowerCase().includes('amazon_video')
        ) {
          return false;
        } else return true;
      });

      return filteredData || [];
    },
    canExportToXLS(): boolean {
      return true;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
  },
  methods: {
    onClick(id) {
      this.selectedItem = id;
      this.showModal = true;
    },
    getImageLink(name: string) {
      name = utils.removeWhiteSpaceLowerCase(name);
      const imageName = utils.parsedTacticNames.includes(name) ? name : 'placeholder';
      return require(`@/assets/summaryIcons/${imageName}.svg`);
    },
    getTooltip(metric: string): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(metric)];
    },
    goToCampaign(item): void {
      const tactic = item.ProductTab;
      let tab = null;
      switch (tactic) {
        case 'VIDEO':
          tab = 'video';
          break;
        case 'SITEIMPACT':
          tab = 'emailsi';
          break;
        default:
          tab = tactic?.toLowerCase();
          break;
      }
      this.$emit('set-current-section', { campaignId: item.CampaignId, tactic: tab });
    },
    formatNumber(value): string {
      if (value == null) {
        return '-';
      }
      return utils.formatNumberWithCommas(value);
    },
    formatDate(date: string, type: string): string {
      try {
        if (!date) return type === 'end' ? 'No End Date' : 'No Start Date';
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err.message || err);
      }
      return '';
    },
    getTacticNames(tactics: string[]): string {
      return tactics
        .map((tactic: string) => {
          return utils.getTacticName(tactic);
        })
        .join(', ');
    },
    getTacticClass(name: string): string[] | null {
      if (!name) return null;
      const classes = [utils.removeWhiteSpaceLowerCase(name), this.template];
      return classes;
    },
    pageChange(change: number): void {
      if (change === 0) {
        // reset to first page
        this.currentPage = 1;
      }

      if (change > 0) {
        // next
        const condition = this.isOrder ? this.currentPage < this.totalPages + 1 : this.currentPage < this.totalPages;
        if (condition) {
          this.currentPage += 1;
        }
      } else {
        // previous
        if (this.currentPage !== 1) {
          this.currentPage -= 1;
        }
      }
      this.$emit('paginate-campaigns', this.table.type, (parseInt(this.currentPage) - 1) * parseInt(this.itemsPerPage));
    },
    disabled(direction: number): boolean {
      if (this.isItemBeingFetched) return true;
      if (direction > 0) {
        if (this.isOrder) return this.currentPage - 1 === this.totalPages;
        return this.currentPage === this.totalPages;
      } else {
        return this.currentPage === 1;
      }
    },
    getPacingStyle(value: string): string {
      if (value === '-') return 'pacing-na';
      if (!value) return 'pacing-none';
      const parsedValue = parseFloat(value);
      const [normalPacingBorder, goodPacingBorder, badPacingBorder] = [-5.0, 0.0, 5.0];
      if (parsedValue >= goodPacingBorder && parsedValue < badPacingBorder) {
        return 'pacing-good';
      } else if (parsedValue >= normalPacingBorder && parsedValue < goodPacingBorder) {
        return 'pacing-normal';
      } else return 'pacing-bad';
    },
    recordSearchToMP: debounce(function (): void {
      this.analyticTrack(this.trackValue.PRODUCT_SUMMARY_SEARCH_CAMPAIGNS, `Search Value: ${this.search}`);
    }, 2000),
  },
});
