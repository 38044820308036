
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'PublisherSelection',
  props: ['componentData'],
  data: (): {
    selection: Array<string> | null;
    availablePubs: Array<string> | null;
  } => ({
    selection: null,
    availablePubs: null,
  }),
  created(): void {
    const data = utils.adDataForKey(
      { sectionConfig: { enableCampaignSelection: true }, ...this },
      this.componentData.dataSource,
    );
    this.availablePubs = data.map(d => d.Publisher);
    this.availablePubs = this.availablePubs.sort((a, b) => a.localeCompare(b));
    this.selection = this.componentData?.publishers || this.availablePubs;
  },
  methods: {
    selectAll(): void {
      if (this.allSelected) {
        this.selection = [this.availablePubs[0]];
      } else {
        this.selection = this.availablePubs;
      }
      this.selectValue();
    },
    selectPublisher(publisher: string): void {
      if (this.isSelected(publisher)) {
        // unselect
        this.selection = this.selection.filter(p => p !== publisher);
      } else {
        // reselect
        this.selection.push(publisher);
      }
      this.selectValue();
    },
    selectValue(): void {
      this.$emit('setOverride', 'publishers', this.selection);
    },
    isSelected(val: string): boolean {
      return this.allSelected || this.selection.includes(val);
    },
  },
  computed: {
    allSelected(): boolean {
      return this.selection?.length === this.availablePubs?.length;
    },
  },
});
