const mainTitle = 'Ending Soon';

export const componentConfig = {
  cid: 'homePerformanceEndingSoon',
  id: 'homePerformanceEndingSoon',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'HOME.ByEndingSoon',
  breakpoints: ['lg6', 'sm6'],
  name: 'EndingSoon',
  title: mainTitle,
  __typename: 'EndingSoon',
  exportableTableData: 'xls',
  filterable: true,
  hasBrandImage: undefined,
  hideIfNoData: undefined,
  nameKey: 'EndingSoon',
  rootValueKey: 'HOME',
  stacked: false,
  overrides: undefined,
  feedSource: undefined,
  noDataTooltips: ['no data'],
  tooltipsTitle: [
    {
      title: mainTitle,
      message: 'This module shows orders that will end within the next 7 days.',
    },
  ],
};

export const componentHeight = '460px';

export const dataSource = 'HOME.ByEndingSoon';
