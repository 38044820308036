
import Vue from 'vue';
import analytics from '../../../../mixins/analytics';
export default Vue.extend({
  name: 'editModuleBtn',
  props: ['sectionId', 'componentId', 'onlyRemove', 'componentTitle'],
  mixins: [analytics],
  data: (): {
    showDeleteDialog: boolean;
  } => ({
    showDeleteDialog: false,
  }),
  methods: {
    trackEdit(): void {
      this.analyticTrack(
        this.trackValue.EVENT_CONFIGURABILITY,
        `${this.trackValue.EDIT_MODULE}: ${this.componentTitle}`,
      );
    },
    deleteComponent(): void {
      const customizations = this.$store.state.layoutEditor.currCustomizations.filter(
        cust => cust?.id?.toLowerCase() !== this.componentId.toLowerCase(),
      );

      const { dynamicLayout } = this.$store.state.customer;
      const dynamicLayoutComps = dynamicLayout.layoutCustomizations.config.components.find(
        c => c.type !== 'summaryonly',
      );
      dynamicLayoutComps.components = dynamicLayoutComps.components.filter(comp => {
        const found = customizations.find(cust => cust?.id?.toLowerCase() === comp?.id?.toLowerCase());
        return found;
      });

      this.$store.dispatch('setLayout', dynamicLayout);
      this.showDeleteDialog = false;
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);
      this.$store.dispatch('layoutEditor/setEnableSave', true);
    },
  },
});
