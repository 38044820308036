const mainTitle = 'Metrics Overview';

export const componentConfig = {
  cid: 'homePerformanceSmallChart',
  id: 'homePerformanceSmallChart',
  dataChecks: ['HasDisplay'],
  disabled: null,
  dataSource: 'HOME.smallChartMetrics',
  breakpoints: ['lg3', 'sm12'],
  name: 'genericLineChart',
  title: mainTitle,
  __typename: 'genericLineChart',
  chartColorIndex: null,
  chartType: null,
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: true,
  availableLineSeriesKeys: ['Actual', 'Before'],
  lineSeriesKeys: ['Actual', 'Before'],
  showyAxisSplitLine: null,
  total: null,
  useTacticSummaryData: null,
  xAxisIntervalCount: 1,
  xAxisMaxItems: null,
  xAxisShowAll: true,
  xAxisSplitLineHide: null,
  yAxisHide: null,
  yAxisLineHide: null,
  xAxisRotateDeg: null,
  showOneLine: null,
  chartColors: null,
  sideTotalMetrics: null,
  tooltips: null,
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thisweek',
  fetchData: [
    { method: 'getActiveClients', title: 'Active Clients', name: 'Clients' },
    { method: 'getActiveOrders', title: 'Active Orders', name: 'Orders' },
    { method: 'getActiveCampaigns', title: 'Active Campaigns', name: 'Campaigns' },
    { method: 'getImpressions', title: 'Impressions', name: 'Impressions' },
  ],
  tooltipsTitle: [{ title: mainTitle, message: 'This module has four sub-modules or graphs. This module is for number of Active Clients, Campaigns, Orders and Total Impressions served. You can select different date ranges in the upper right corner of the module.' }],
};

export const componentHeight = '120px';

export const clientTooltip = { title: 'Active Clients show the total amount of clients that have active orders under this account.' };
