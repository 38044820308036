
import Vue from 'vue';
import utils from '../../../util';
import { SavedRoute } from '../../../types/util';

export default Vue.extend({
  name: 'RecentHistory',
  props: ['recentAdvertisers'],
  data: (): {
    pageHistory: Array<SavedRoute> | [];
  } => ({
    pageHistory: [],
  }),
  mounted() {
    this.getRoutes();
  },
  computed: {
    superUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
    showPageHistory(): boolean {
      return this.pageHistory?.length;
    },
  },
  methods: {
    renamedActivity(str: string) {
      const [titleActivity, subTitleActivity] = str.split(' - ');
      return { titleActivity, subTitleActivity };
    },
    goToAdvertiser(advertiser): void {
      const query = {
        id: advertiser.PropertyId,
        tab: 'orderlist',
      };
      this.$router.push({ name: 'home', query });
    },
    goToPage(page: SavedRoute): void {
      this.$router.push({ name: page.name, query: page.query, params: page.params });
    },
    getRoutes(): void {
      const recentStr = localStorage.getItem(`${this.$store.getters.user.email}:recentPages`);
      if (!recentStr) {
        this.pageHistory = [];
      }
      try {
        const recent: Array<SavedRoute> = JSON.parse(recentStr);
        // don't show home page
        this.pageHistory = recent.filter(page => !page.query?.recent);
      } catch (err) {
        this.pageHistory = null;
        // eslint-disable-next-line no-console
        console.error('getAllRoutes', err);
      }
    },
  },
});
