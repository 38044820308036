
import Vue from 'vue';
import asyncimage from './image.vue';
import utils from '../../../../../util';

export default Vue.extend({
  inheritAttrs: false,
  name: 'legendPlatform',
  props: [
    'compact',
    'componentConfig',
    'editMode',
    'hasBrandImage',
    'iconType',
    'keyIndex',
    'pieColors',
    'pieData',
    'sectionConfig',
    'stacked',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
  ],
  data: (): {
    imageList: string[];
    brandList: Set<string>;
  } => ({
    imageList: [],
    // NOTE: this will probably need to be updated as we add more logos
    brandList: new Set([
      'Samsung',
      'LG',
      'Motorola',
      'ZTE',
      'Amazon',
      'Apple',
      'TCL',
      'Sony',
      'Windows',
      'HTC',
      'RCA',
      'Lenovo',
      'Google',
      'Alcatel',
      'Asus',
      'Kyocera',
      'Huawei',
      'Xiaomi',
      'Android',
      'Acer',
      'Vizio',
      'Panasonic',
      'Magnavox',
      'Nextbook',
      'MXQ',
      'Tivo',
      'Tanix',
      'Coolpad',
      'Hisense',
      'Netgear',
      'Vudu',
      'Philips',
      'Toshiba',
      'Linux',
      'Roku',
      'Microsoft',
      'Jadoo',
      'Nvidia',
      'Fire',
      'ASUS',
      'Tizen',
      'iOS',
    ]),
  }),
  components: { 'async-image': asyncimage },
  methods: {
    getTooltip(name: string): string {
      if (name?.toLowerCase() === 'other') {
        if (this.keyIndex === undefined) {
          return '';
        }
        const val = this.componentConfig.valueKey[this.keyIndex];
        if (!val) {
          return '';
        }

        // gets a metric-specific tooltip based on the keyIndex, which is the filter dropdown selection
        return `Consolidation of all ${utils.headerNamesMap(val.toLowerCase())} beyond the top ${this.pieData.length - 1}.`;
      }
    },
    getLegendStyle(index: number): object {
      // show a border if there aren't any icons or if there are inline icons
      return !this.hasIcons || (this.hasIcons && this.inlineIcons)
        ? { 'border-left': `solid 5px ${this.color(index)}` }
        : {};
    },
    legendHover(name: string): void {
      this.$emit('m-enter', name);
    },
    legendUnHover(name: string): void {
      this.$emit('m-leave', name);
    },
    classDataName(name: string): string {
      if (!name || name.length === 0) {
        return '';
      }
      return name.replace(/\W+/g, '-').toLowerCase();
    },
    color(index: number): string {
      return this.pieColors[index];
    },
    labelValue(value): string {
      const val = isNaN(value) ? value : utils.formatNumberWithCommas(value);
      return value !== undefined ? val : '—';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    iconColor(index: number): any {
      return {
        'background-color': this.color(index),
      };
    },
    legendIcon(value): string {
      if (!value) {
        return 'adjust';
      }
      let icon;
      value.split(' ').forEach((s: string) => {
        if (utils.iconLibrary[s.toLowerCase()]) {
          icon = utils.iconLibrary[s.toLowerCase()];
        }
      });
      return icon !== undefined ? icon : 'adjust';
    },
    legendClass(name: string): Array<string> {
      const arr: string[] = [];
      // const theme = this.editMode ? this.editTheme : this.Theme.config;
      const brandListContainsName = name.split(' ').find((part: string) => {
        return this.brandList.has(part);
      });
      if (this.hasBrandImage && brandListContainsName && name?.toLowerCase() !== 'other') arr.push('has-logo'); // if there is a logo and it's not "Other"
      if (this.stacked) arr.push('stacked');
      if (this.compact) arr.push('compact');
      return arr;
    },
    labelName(name: string): string {
      // I wish I wasn't writing this code, but we have a request to replace "Unknown" with "Other" and can't change the data
      if (name?.toLowerCase() === 'unknown') return 'Other';
      if (this.componentConfig?.dataSource === 'GOOGLEVIDEO.ByAgeImpression') {
        return name === 'Other' ? name : utils.youtubeAgeMapping[name];
      }
      return name;
    },
  },
  computed: {
    sortedPieData() {
      // this is for data with ages like '18-24' that needs to be displayed sorted by age ranges, not by number of impressions or similar
      if (this.iconType === 'age') {
        const pieDataCopy = this.pieData;
        return pieDataCopy.sort(function (a, b) {
          if (a.name === 'Undetermined') {
            return -1;
          } else if (b.name === 'Undetermined') {
            return 1;
          } else {
            const aFirstTwoDigits = parseInt(a.name.substring(0, 2), 10);
            const bFirstTwoDigits = parseInt(b.name.substring(0, 2), 10);
            return aFirstTwoDigits > bFirstTwoDigits ? 1 : bFirstTwoDigits > aFirstTwoDigits ? -1 : 0;
          }
        });
      }
      return this.pieData;
    },
    editTheme() {
      return this.$store.state.customer.editTheme;
    },
    containerClass(): Array<string> {
      const arr: string[] = [];
      if (this.stacked) {
        arr.push('stacked');
      } else if (!this.isExporting) {
        arr.push('side-by-side-c360');
      }
      if (this.compact) arr.push('compact');
      return arr;
    },
    columnCount(): Number {
      return this.stacked ? 3 : 12; // if stacked, give each column full width to force them to stack, otherwise 50% each
    },
    hasIcons(): boolean {
      if (this.isXLS) {
        return false;
      }
      // note: 'name' is for SocialSite data
      const legendsWithIcons: string[] = ['devicetype', 'gender', 'device', 'devicecategory', 'name', 'browser'];
      const found = legendsWithIcons.find((l: string) => l === this.iconType);
      return !!found && !this.hasBrandImage;
    },
    inlineIcons(): boolean {
      const theme = this.editMode ? this.editTheme : this.Theme.config;
      return theme?.pieCharts?.inlineIcon;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
  },
});
