
import Vue from 'vue';
import utils from '../../../../util';
import _clone from 'lodash.clonedeep';
import { validProducts } from '../../../components/summary/utils';
import analytics from '../../../../mixins/analytics';

interface Product {
  title: string;
  analyticsType: string;
}

const newSelection = {
  hideSummaryMap: false,
  disabledProducts: [],
};

export default Vue.extend({
  name: 'SummaryPageOptions',
  mixins: [analytics],
  data: (): {
    disableSave: boolean;
    originalOverrides: object | null;
    showDialog: boolean;
    selection: {
      hideSummaryMap: boolean;
      disabledProducts: Array<String>;
    };
  } => ({
    disableSave: true,
    showDialog: false,
    originalOverrides: null,
    selection: {
      hideSummaryMap: false,
      disabledProducts: [],
    },
  }),
  mounted(): void {
    const { overrides } =
      this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];
    if (overrides) {
      this.originalOverrides = _clone(overrides);
    }
  },
  methods: {
    trackToggleMap(): void {
      this.analyticTrack(
        this.trackValue.EVENT_CHANGE_MODULE_SETTING,
        `Summary: hide map - ${this.selection.hideSummaryMap}`,
      );
    },
    save(): void {
      const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      const found = customizations.find(cust => cust.id === 'summaryController');
      const { overrides } =
        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];
      const newOverrides = _clone(overrides) || {};

      if (found?.overrides) {
        // apply overrides to layout
        Object.keys(found.overrides).forEach((key: string) => {
          newOverrides[key] = found.overrides[key];
        });
      }

      this.$store.dispatch('setOverrides', { compId: 'summaryController', overrides: newOverrides });
      this.$store.dispatch('layoutEditor/setEnableSave', true);
      this.clear();
    },
    setOverride(): void {
      const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      const found = customizations.find(cust => cust.id === 'summaryController');
      let newOverride;
      if (found) {
        newOverride = {};
        Object.keys(this.selection).forEach((key: string) => {
          newOverride[key] = this.selection[key];
        });
        if (found.overrides) {
          found.overrides = { ...found.overrides, ...newOverride };
        } else {
          found['overrides'] = newOverride;
        }
        this.$store.dispatch('layoutEditor/setCurrCustomizations', utils.removeNullFromObjectOrArray(customizations));
        this.disableSave = false;
      }
    },
    toggleProduct(product: Product): void {
      if (this.oneProductLeft && !this.isDisabled(product)) return;

      if (this.selection.disabledProducts.includes(product.analyticsType)) {
        // remove
        this.selection.disabledProducts = this.selection.disabledProducts.filter(p => p !== product.analyticsType);
      } else {
        // add
        this.selection.disabledProducts.push(product.analyticsType);
      }
      this.analyticTrack(
        this.trackValue.EVENT_CHANGE_MODULE_SETTING,
        `Summary: toggle product ${product.analyticsType}`,
      );

      this.setOverride();
    },
    isDisabled(product: Product): boolean {
      return this.selection?.disabledProducts?.includes(product.analyticsType);
    },
    cancel(): void {
      // undo overrides
      const found = this.$store.state.layoutEditor.currCustomizations.find(cust => cust.id === 'summaryController');
      if (found) {
        if (this.originalOverrides) {
          found.overrides = this.originalOverrides;
        } else {
          delete found.overrides;
        }
      }
      this.clear();
    },
    clear(): void {
      this.selection = _clone(newSelection);
      this.disableSave = true;
      this.showDialog = false;
    },
    openDialog(): void {
      // set selection if overrides already exist
      const { overrides } =
        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];

      if (overrides) {
        Object.keys(overrides).forEach((key: string) => {
          if (overrides[key]) {
            this.selection[key] = overrides[key];
          }
        });
      } else {
        this.selection = _clone(newSelection);
      }

      this.showDialog = true;
    },
  },
  computed: {
    availableProducts(): Array<Product> | null {
      const data = this.$store.state.customer?.summaryPerformance;
      let products: Array<Product> | null = null;

      if (data) {
        products = validProducts.reduce((all: Array<Product>, product: string) => {
          // check that there's good summaryData to show the product
          const productData = data[product];
          if (productData) {
            all.push({
              title: utils.getTacticName(product),
              analyticsType: product,
            });
          }
          return all;
        }, []);
      }
      return products;
    },
    whiteToolbar(): boolean {
      return this.Theme?.whiteToolbar;
    },
    oneProductLeft(): boolean {
      return this.selection?.disabledProducts?.length === this.availableProducts.length - 1;
    },
  },
});
