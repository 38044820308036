
import Vue from 'vue';

export default Vue.extend({
  props: ['sort'],
  data: () => ({
    selection: '',
    sortOptions: [
      { text: 'Left-to-right', value: 'ltr' },
      { text: 'Top-to-bottom', value: 'ttb' },
    ],
  }),
  created(): void {
    this.selection = this.sortOptions.find(option => option.value === this.sort);
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'sort', this.selection);
    },
  },
});
