
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import debounce from 'lodash.debounce';

export default Vue.extend({
  name: 'SiteImpactCanvas',
  props: [
    'currentCampaign',
    'siteImpactColors',
    'siteImpactCreative',
    'tableRowIndexes',
    'updateCount',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentConfig',
  ],
  data: (): {
    siteImpactImageReady: boolean;
    siteImpactLinkByUrl: object;
    siteImpactJSONReady: boolean;
    siteImpactLinkByIndex: object;
  } => ({
    siteImpactImageReady: false,
    siteImpactLinkByUrl: {},
    siteImpactJSONReady: false,
    siteImpactLinkByIndex: {},
  }),
  mounted() {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'fetchSiteImpactJSON' });
    this.fetchSiteImpactJSON();
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    siteImpactData(): any {
      if (this.isExporting) {
        return this.exportData?.adData?.SITEIMPACT?.ByLinkImpression;
      }
      return this.$store.state?.customer?.campaignAdPerformance?.SITEIMPACT?.ByLinkImpression;
    },
  },
  methods: {
    fetchSiteImpactJSON(retrying = false): void {
      if (!this.siteImpactData || !this.currentCampaign || !this.currentCampaign?.CreativeJson) {
        if (!retrying) {
          setTimeout(() => this.fetchSiteImpactJSON(true), 1000);
          return;
        }
        // eslint-disable-next-line no-console
        console.log('emailsi: missing data');
        this.$emit('renderFailure');
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.siteImpactData.forEach((link: any) => {
        this.siteImpactLinkByUrl[link.URL] = link;
        this.siteImpactLinkByIndex[link.Index] = link;
      });

      // PROD-7509: we can receive links to 2 different hosts: cdn.compulsemailstats.com and cdn.ecampaignstats.com
      // to avoid CORS errors, always use compulsemailstats path
      const url = new URL(this.currentCampaign.CreativeJson);
      const fileKey = url.pathname;
      const urlString = `/compulsemailstats${fileKey}`;

      axios
        .get(urlString)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: AxiosResponse<any>) => {
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'fetchSiteImpactJSON', completed: true });
          if (resp?.data?.links) {
            this.siteImpactLinks = resp.data;

            Object.keys(resp.data.links).forEach((k: string) => {
              const jsonLink = resp.data.links[k];
              const adLink = this.siteImpactLinkByIndex[jsonLink.index];
              const merged = { ...jsonLink, ...adLink, index: parseInt(jsonLink.index, 10) };
              this.siteImpactLinkByIndex[adLink.Index] = merged;
              this.siteImpactLinkByUrl[adLink.URL] = merged;
            });

            this.siteImpactJSONLoaded();
            return;
          }
          // eslint-disable-next-line no-console
          console.error('expecting links from site impact json', this.currentCampaign.CreativeJson, resp);
          this.$emit('renderFailure');
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'fetchSiteImpactJSON', completed: true });
        });
    },
    siteImpactJSONLoaded(): void {
      if (!this.siteImpactLinks?.links) {
        // eslint-disable-next-line no-console
        console.error('emailsi: no creative links');
      } else if (!this.siteImpactData?.length) {
        // eslint-disable-next-line no-console
        console.error('emailsi: no adData links');
      } else {
        const creativeLinkCount = Object.keys(this.siteImpactLinks.links).length;
        const adDataLinkCount = this.siteImpactData.length;
        if (adDataLinkCount !== creativeLinkCount) {
          // eslint-disable-next-line no-console
          console.error('renderLinks: mismatch between creative and adData links');
        }
      }

      this.siteImpactJSONReady = true;
      setTimeout(this.renderSiteImpactLinks, 50); // wait for data-table to be rendered as well
    },
    siteImpactImageLoaded(): void {
      this.siteImpactImageReady = true;
      setTimeout(this.renderSiteImpactLinks, 50); // wait for data-table to be rendered as well
    },
    renderSiteImpactLinks: debounce(async function () {
      if (!this.siteImpactImageReady || !this.siteImpactJSONReady) {
        setTimeout(this.renderSiteImpactLinks, 250);
        return;
      }

      // wait for this component to settle down before starting to render links
      await Vue.nextTick();
      this.$emit('setTableIndexes');
      // wait for setTableIndexes to have updated the props to this component
      await Vue.nextTick();

      if (!this.siteImpactLinks) {
        // eslint-disable-next-line no-console
        console.log('renderLinks, no data');
        return;
      }

      const colors = this.siteImpactColors;

      const creativeHost = this.$refs.siteImpactCreative;
      if (!creativeHost) {
        // eslint-disable-next-line no-console
        console.log('renderLinks, creative not found');
        return;
      }

      const canvasEl = this.$refs[`siteImpactCanvas${this.componentConfig.id}`];
      if (!canvasEl) {
        // eslint-disable-next-line no-console
        console.log('renderLinks, canvas not found');
        return;
      }

      const imgEl = this.$refs.siteImpactImg;
      if (!imgEl) {
        // eslint-disable-next-line no-console
        console.log('renderLinks, image not found');
        return;
      }

      const localImgSize = { width: imgEl.clientWidth, height: imgEl.clientHeight, left: imgEl.offsetLeft };
      const fullImgSize = {
        width: this.siteImpactLinks.body.scrollWidth,
        height: this.siteImpactLinks.body.scrollHeight,
      };
      const resizeRatio = localImgSize.height / fullImgSize.height;
      canvasEl.width = creativeHost.offsetWidth + 25;
      canvasEl.height = creativeHost.offsetHeight;
      const context = canvasEl.getContext('2d');
      context.clearRect(0, 0, canvasEl.width, canvasEl.height);
      Object.keys(this.siteImpactLinkByIndex).forEach((k: string) => {
        const link = this.siteImpactLinkByIndex[k];
        let trIndex = this.tableRowIndexes[link.Index];
        if (typeof trIndex !== 'number') {
          trIndex = -1;
        }
        const rect = {
          top: link.offset.top * resizeRatio,
          left: link.offset.left * resizeRatio + localImgSize.left,
          width: link.width * resizeRatio,
          height: link.height * resizeRatio,
        };

        // console.log('render box', link, rect);
        context.globalAlpha = 0.6;
        context.beginPath();
        context.setLineDash([]);
        context.rect(rect.left, rect.top, rect.width, rect.height);
        context.lineWidth = 4;
        context.strokeStyle = colors[link.index];
        context.shadowColor = colors[link.index];
        context.shadowBlur = 2;
        context.stroke(); // render box

        if (trIndex >= 0) {
          context.beginPath();
          context.moveTo(rect.left + rect.width, rect.top + rect.height / 2);
          context.lineWidth = 3;
          context.lineCap = 'round';
          context.setLineDash([3, 6]);
          const destHeight = 72 + trIndex * 48;
          context.lineTo(canvasEl.width - 25, destHeight);
          context.lineTo(canvasEl.width, destHeight);
          context.strokeStyle = colors[link.index];
          context.shadowColor = '#FFFFFF';
          context.shadowBlur = 1;
          context.stroke(); // render line
        }
      });
    }, 500),
    gotoImage(): void {
      window.open(this.siteImpactCreative);
    },
  },
  watch: {
    updateCount: {
      handler(): void {
        setTimeout(this.renderSiteImpactLinks, 50);
      },
    },
    currentCampaign: {
      handler(): void {
        const canvasEl = this.$refs[`siteImpactCanvas${this.componentConfig.id}`];
        if (canvasEl) {
          const context = canvasEl.getContext('2d');
          context.clearRect(0, 0, canvasEl.width, canvasEl.height);
        }
        this.siteImpactImageReady = false;
        this.siteImpactJSONReady = false;
        this.fetchSiteImpactJSON();
      },
    },
  },
});
