
import Vue from 'vue';
import analytics from '../../../../mixins/analytics';
export default Vue.extend({
  name: 'ExitEditDialog',
  props: ['showDialog'],
  mixins: [analytics],
  computed: {
    showExitEditDialog(): boolean {
      return this.showDialog;
    },
  },
  methods: {
    exitWithoutSaving(): void {
      const { dynamicLayoutSource, dynamicLayout } = this.$store.state.customer;

      const sourceComponents = dynamicLayoutSource.layoutCustomizations?.config.components.find(
        c => c.type !== 'summaryonly',
      );
      const dynamicComponents = dynamicLayout.layoutCustomizations.config.components.find(
        c => c.type !== 'summaryonly',
      );
      dynamicComponents.components = sourceComponents.components.map(comp => {
        return comp;
      });

      this.$store.dispatch('setLayout', dynamicLayout);
      this.$store.dispatch('layoutEditor/setEnableSave', false);
      this.$store.dispatch('layoutEditor/setCurrCustomizations', null);
      this.$store.dispatch('layoutEditor/toggleEditMode', false);
      this.$emit('hide-dialog', true);
      this.analyticTrack(this.trackValue.EVENT_CONFIGURABILITY, this.trackValue.EXIT_WITHOUT_SAVING);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    },
  },
});
