
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'TableProgressBar',
  props: ['componentData'],
  data: (): {
    selection: Array<string> | null;
  } => ({
    selection: null,
  }),
  created(): void {
    this.selection = this.componentData?.bars || [];
  },
  computed: {
    barOptions(): string {
      return this.componentData.availableBars?.map(col => {
        return {
          text: utils.headerNamesMap(col),
          value: col,
        };
      });
    },
    isCreative(): boolean {
      return this.componentData?.creativeType === 'image' || this.componentData?.creativeType === 'video';
    },
    hasFilter(): string {
      return this.componentData?.filterBy;
    },
  },
  methods: {
    selectValue(): void {
      // if this progress bar is for creative, retain the first two columns, otherwise retain the first column
      const filteredMetrics: Array<string> = this.isCreative
        ? this.componentData?.columns?.slice(0, 2)
        : this.componentData?.columns[0];
      // if we have a filter, we need to remove add it back to the columns
      if (this.hasFilter) filteredMetrics.push(this.hasFilter);
      filteredMetrics.push(...this.selection);
      this.$emit('setOverride', 'columns', filteredMetrics);
      this.$emit('setOverride', 'bars', this.selection);
    },
  },
});
