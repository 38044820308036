export const componentConfig = {
  cid: 'ordersummaryPerformanceImpsByZip',
  id: 'ordersummaryPerformanceImpsByZip',
  disabled: undefined,
  dataSource: 'ORDERSUMMARY.ByZipImpression',
  breakpoints: ['lg9', 'sm6'],
  name: 'ImpsByZip',
  title: 'Performance By Zip/Postal Code',
  __typename: 'ImpsByZip',
  exportableTableData: null,
  nameKey: '',
  rootValueKey: 'ORDERSUMMARY',
  feedSource: undefined,
  noDataTooltips: ['no data'],
  xAxisRotate: false,
  horizontal: true,
  canvasHeight: '350px',
  sortBy: 'impressions',
  columns: ['zipcode', 'impressions', 'vc100', 'vcr'],
  availableColumns: ['zipcode', 'impressions', 'vc100', 'vcr'],
  searchEnabled: false,
};

export const componentHeight = '562';

export const dataSource = 'ORDERSUMMARY.ByGeoImpression';
