
import Vue from 'vue';
export default Vue.extend({
  props: ['demographicsData'],
  computed: {
    demoData() {
      return this.demographicsData?.values;
    },
    showDemographics(): boolean {
      return this.demoData && this.demoData.length;
    },
  },
  methods: {
    listData(data): string {
      return data?.values?.join(', ');
    },
  },
});
