
import Vue from 'vue';
import { componentConfig, componentHeight, clientTooltip } from './utils/configSmallLineChart';
import { updateStorage, splitData, checkDataValidity, getRangePreviousText, getRangeActualText } from './utils/utils';
import { ModuleName } from './types/types';
import { EnhancedDateRange } from './types/types';

export default Vue.extend({
  name: 'Metrics',
  data: (): {
    selectedRange: string | null;
    metrics: Record<string, any>[];
    loading: Record<string, boolean>;
    dateRange: string | null;
    componentHeight: string;
    localStorageName: string;
  } => ({
    selectedRange: null,
    dateRange: null,
    loading: {
      Clients: false,
      Orders: false,
      Campaigns: false,
      Impressions: false,
    },
    metrics: [{ name: 'Clients' }, { name: 'Orders' }, { name: 'Campaigns' }, { name: 'Impressions' }],
    componentHeight,
    localStorageName: 'metric_primary',
  }),
  components: {
    MetricsHeader: () => import('./metricsHeader.vue'),
    LineChartSmall: () => import('../../../components/charts/line/lineChartSmall.vue'),
  },
  mounted() {
    this.setDefaultDateRange();
    this.$nextTick(() => {
      this.getData();
    });
  },
  computed: {
    isAnyLoading() {
      return Object.values(this.loading).some(status => status);
    },
    rangeActualText(): string | null {
      return getRangeActualText(this.dateRange, ModuleName.METRICS);
    },
    rangePreviousText(): string | null {
      return getRangePreviousText(this.dateRange);
    },
    componentConfig() {
      return { ...componentConfig, lineSeriesKeys: [this.rangeActualText, this.rangePreviousText] };
    },
  },
  methods: {
    async getData() {
      const storedData = localStorage.getItem(this.localStorageName);

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const { isDataValid, isRightUser, isRightAgency } = checkDataValidity(
          parsedData,
          this.$store.state.customer.user.email,
          this.$store.state.customer.user.Agency,
        );
        this.metrics = parsedData.data;

        if (!isRightUser || !isRightAgency) {
          localStorage.removeItem(this.localStorageName);
          await this.updateFetchDataFromConfig(true);
        } else if (!isDataValid) {
          await this.updateFetchDataFromConfig(false);
        }
      } else {
        await this.updateFetchDataFromConfig(true);
      }
    },
    async updateFetchDataFromConfig(withLoading: boolean) {
      await this.fetchDataFromConfig(withLoading);
      updateStorage(
        this.metrics,
        this.localStorageName,
        this.$store.state.customer.user.email,
        this.$store.state.customer.user.Agency,
      );
    },
    setDefaultDateRange() {
      this.dateRange = this.componentConfig?.dateRange || 'thisweek';
      this.selectedRange = 'Week';
    },
    async fetchDataItem(item, withLoading) {
      const { method, title, name } = item;
      Vue.set(this.loading, name, withLoading);
      try {
        const metric = await this.fetchFunction(method, { daterange: this.dateRange });
        const metricSplitted = splitData(metric?.data, this.dateRange);

        const tooltips = name === 'Clients' ? [clientTooltip, ...metric?.total?.objects] : [];
        const metricObject = {
          title,
          percentage: metric?.total?.percentage,
          total: metric?.total?.activeTotal,
          chartData: metricSplitted,
          name,
          tooltips,
        };

        const foundedIndex = this.metrics?.findIndex(item => item.name.includes(name));
        if (foundedIndex !== -1 && this.metrics?.length) {
          this.metrics.splice(foundedIndex, 1, metricObject);
        } else {
          this.metrics.push(metricObject);
        }
      } catch (error) {
        console.error(`Error fetching data for ${name}:`, error);
      } finally {
        Vue.set(this.loading, name, false);
      }
    },
    async fetchDataFromConfig(withLoading: boolean) {
      const promises = this.componentConfig.fetchData.map(item => this.fetchDataItem(item, withLoading));
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    },
    selectRange(range: EnhancedDateRange): void {
      if (this.isAnyLoading) {
        return;
      }
      this.dateRange = range?.RangeKey;
      this.selectedRange = range?.Text;
      this.fetchDataFromConfig(true);
    },
    async fetchFunction(method, payload) {
      try {
        const response = await this.$store.dispatch('primary/' + method, payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  },
});
