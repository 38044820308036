
import Vue from 'vue';
import draggable from 'vuedraggable';
import OrderDetailsMap from '../components/maps/orderDetailsMap.vue';
import OrderDetailsMetrics from '../components/orderDetailsMetrics.vue';
import orderDetailsChart from '../components/orderDetailsChart.vue';
import orderDetailsCampaignList from '../components/orderDetailsCampaignList.vue';
import orderDetailsPieChart from '../components/orderDetailsPieChart.vue';
import OrderDetailsWeekday from '../components/orderDetailsWeekdayTable.vue';
import OrderDetailsGeo from '../components/orderDetailsGeoTable.vue';
import OrderDetailsZipCode from '../components/orderDetailsZipCodeTable.vue';

export default Vue.extend({
  name: 'OrderSummaryController',
  props: ['sectionConfig', 'componentConfig', 'isExporting', 'isExportDynamic', 'exportData', 'exportContext'],
  components: {
    draggable,
    OrderDetailsMap,
    OrderDetailsMetrics,
    orderDetailsChart,
    orderDetailsPieChart,
    OrderDetailsWeekday,
    orderDetailsCampaignList,
    OrderDetailsGeo,
    OrderDetailsZipCode,
  },
  data: (): {
    availableProducts: Array<string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedOrder: any;
    orderComponentConfig: any;
    comps: any;
    config: object;
  } => ({
    availableProducts: [],
    selectedOrder: null,
    orderComponentConfig: null,
    comps: [],
    config: {
      legend: null,
      loading: true,
      loadedValidData: true,
      dataCacheKey: null,
      dataSource: null,
      tweaks: {},
      campaignDetails: null,
      mapEnabled: false,
      hoveredLayer: null,
      tooltipTitle: null,
      tooltipContent: null,
      layoutThemes: [],
      showTopAlert: true,
    },
  }),
  created(): void {
    this.getSelectedOrder();
    this.readConfig();
    this.setMapHeight();
  },
  computed: {},
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSelectedOrder(): any {
      // will get from $store

      this.orderComponentConfig =
        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0].configuration;
      const comps = JSON.parse(this.orderComponentConfig)?.[0]?.components || [];
      this.comps = comps;
    },
    getComponentClass(cid): string {
      return this.$store.state.order.hidingOrderModulesWhenNoImps &&
        !['orderDetailsCampaignList', 'orderDetailsMetrics'].includes(cid)
        ? 'hidden-module'
        : '';
    },
    readConfig(): void {
      this.config.layoutThemes = this.componentConfig?.themes;
      this.config.dataSource = 'SummaryAPI';
      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''} ${'summary' || this.$route.query.tab || ''}`;

      this.config.dataCacheKey = `${this.config.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');
      this.config.mapEnabled = true;
      if (this.componentConfig?.mapTweaks === 'hideMap') {
        this.config.mapEnabled = false;
      }
      this.config.loading = false;
    },
    getMapHeight(): number {
      // For now, to simplify config, just return a fixed height for all order map summary details.
      return 600;
    },
    setMapHeight(): void {
      this.$store.dispatch('summary/setMapHeight', this.getMapHeight());
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
  watch: {
    summaryData: {
      handler(): void {
        this.setMapHeight();
      },
    },
    disabledProducts: {
      handler(): void {
        this.setMapHeight();
      },
    },
  },
});
