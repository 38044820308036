import { format } from 'date-fns';
import { Range, RangeText, ModuleName } from '../types/types';

const rangeTextMap = {
  [Range.THISWEEK]: RangeText.THISWEEK,
  [Range.THISMONTH]: RangeText.THISMONTH,
  [Range.THISYEAR]: RangeText.THISYEAR,
  [Range.ALLTIME]: RangeText.ALLTIME,
};

function getRangeText(daterange: Range): RangeText {
  return rangeTextMap[daterange];
}

export const getRangePreviousText = (daterange: Range): string | null => {
  const text = getRangeText(daterange);
  return text ? 'Previous ' + text : '';
};

export const getRangeActualText = (daterange: Range, module: ModuleName): string | null => {
  const dateText = getRangeText(daterange);
  switch (module) {
    case ModuleName.METRICS:
      return `On This Date`;
    case ModuleName.FULLMETRICS:
      return `On This Date`;
    case ModuleName.FULLMETRICS_LEGEND:
      return daterange !== Range.ALLTIME ? `Daily Values This ${dateText}` : '';
    case ModuleName.CLIENTS:
      return daterange !== Range.ALLTIME ? `This ${dateText}` : 'All Time';
    default:
      // eslint-disable-next-line no-console
      console.error('Chosen dateText doesn`t exist:', dateText);
      return null;
  }
};

export const checkDataValidity = (
  parsedData,
  userKey,
  agencyKey,
): { isRightUser: boolean; isDataValid: boolean; isRightAgency: boolean } => {
  const { timestamp, user, data } = parsedData;
  const isDataFresh = checkIfDataFresh(timestamp);
  const isRightUser = user?.userKey === userKey;
  const isRightAgency = user?.agencyKey === agencyKey;
  const errorAppear = data?.error || !data?.length;
  const isDataValid = isDataFresh && !errorAppear && data?.length && timestamp && user;
  return { isRightUser, isDataValid, isRightAgency };
};

export const updateStorage = (data, dataKey, userKey, agencyKey) => {
  // Update local storage with new data
  localStorage.setItem(
    dataKey,
    JSON.stringify({
      data: data,
      timestamp: new Date().getTime(),
      user: {
        userKey,
        agencyKey,
      },
    }),
  );
};

export const checkIfDataFresh = timestamp => {
  const currentTime = new Date().getTime();
  const thirtyMinutesInMilliseconds = 30 * 60 * 1000; // 30 minutes in milliseconds
  return currentTime - timestamp < thirtyMinutesInMilliseconds;
};

export const splitData = (data, dateRange) => {
  if (!data) {
    return [];
  }
  if (data.length % 2 !== 0) {
    data.shift();
  }
  const daysArray = [];
  const firstPartArray = [];
  const remainingPartArray = [];
  const midpoint = Math.floor(data.length / 2);
  const firstHalf = data.slice(0, midpoint);
  const secondHalf = data.slice(midpoint);
  if (dateRange !== Range.ALLTIME) {
    firstHalf.forEach(entry => {
      const { active, impressions } = entry;
      firstPartArray.push(active || impressions);
    });
    secondHalf.forEach(entry => {
      const { day, date, active, impressions } = entry;
      const parsedDate = new Date(day ?? date);
      const formattedDay = format(parsedDate, 'M/d/yy');
      daysArray.push(formattedDay);
      remainingPartArray.push(active || impressions);
    });
    return [daysArray, remainingPartArray, firstPartArray];
  } else {
    data.forEach(entry => {
      const { day, date, active, impressions } = entry;
      const parsedDate = new Date(day ?? date);
      const formattedDay = format(parsedDate, 'M/d/yy');
      daysArray.push(formattedDay);
      firstPartArray.push(active || impressions);
    });
    return [daysArray, firstPartArray];
  }
};
