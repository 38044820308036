
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import utils from '@/util';
import { OrderService } from '@/services/order-summary';
const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderEditCampaigns',
  components: { C360Icon },
  props: {
    showModal: Boolean,
    advertiserId: String,
    orderId: String,
  },
  data: (): {
    selectedOption: any;
    campaigns: any[];
    perPage: Number;
    currentPage: Number;
    totalPages: Number;
    editCampaignOpen: Boolean;
    token: String;
    campaignId: String;
  } => ({
    selectedOption: null,
    campaigns: [],
    perPage: 10,
    currentPage: 1,
    totalPages: 0,
    editCampaignOpen: false,
    token: '',
    campaignId: '',
  }),
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.campaigns = [];
        this.getData();
        this.token = this.$store.state.customer.user.accessToken;
      }
    },
  },
  computed: {
    paginatedCampaigns(): any[] {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.campaigns.slice(start, end);
    },
  },
  methods: {
    onError(error: any): void {
      this.$emit('on-error', error);
    },
    openEditCampaign(campaignId: string): void {
      this.campaignId = campaignId;
      this.$nextTick(() => {
        this.editCampaignOpen = true;
      });
    },
    getTacticName(tactic: string): string {
      return utils.getTacticName(tactic);
    },
    pageText(): string {
      const pageStartIndex = this.perPage * (this.currentPage - 1) + 1;
      let pageEndIndex = this.perPage * this.currentPage;
      if (pageEndIndex > this.campaigns.length) {
        pageEndIndex = this.campaigns.length;
      }
      return `${pageStartIndex} - ${pageEndIndex} of ${this.campaigns.length}`;
    },
    pageChange(direction: number): void {
      this.currentPage += direction;
    },
    disabled(direction: number): boolean {
      if (!this.campaigns.length) return true;
      if (direction > 0) {
        return this.currentPage === this.totalPages;
      } else {
        return this.currentPage === 1;
      }
    },
    closeModal(): void {
      this.$emit('close');
    },
    async getData(): void {
      const campaignsData = await orderService.getOrderCampaignList({
        advertiserId: this.advertiserId,
        orderId: this.orderId,
      });
      this.campaigns = campaignsData || [];
      if (this.campaigns.length) {
        this.currentPage = 1;
        this.totalPages = Math.ceil(this.campaigns.length / this.perPage);
      }
    },
  },
});
