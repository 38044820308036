
import { C360Icon } from '@c360/ui';

export default {
  name: 'AgencySwitchMenu',
  inheritAttrs: false,
  components: { C360Icon },
  props: [],
  data: (): {} => ({
    open: false,
    selectedAgency: null,
  }),
  computed: {
    currentAgency() {
      return this.$store.state.customer.user.Agency;
    },
    availableAgencies() {
      return this.$store.state.customer.user.AvailableAgencies;
    },
  },
  methods: {
    changeAgency(agencyId) {
      this.open = false;
      const agency = this.availableAgencies.find(a => a.id === agencyId);
      const currentHostname = window.location.hostname;
      const hostnameParts = currentHostname.split('.');
      // Replace the first part (subdomain) with agency theme code
      hostnameParts[0] = agency?.theme_code;
      const agencyHostname = hostnameParts.join('.');
      window.location.href =
        window.location.protocol + '//' + agencyHostname + window.location.pathname + window.location.search;
      return;
    },
  },
};
