
import Vue from 'vue';
export default Vue.extend({
  props: ['geoData'],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: Array<any>;
    tab: null;
    // itemsPerPage: number;
  } => ({
    headers: [
      {
        text: 'Code',
        value: 'code',
        sortable: false,
      },
      {
        text: 'City',
        value: 'city',
        sortable: false,
      },
    ],
    tab: null,
    // itemsPerPage: 6,
  }),
});
