
import Vue from 'vue';

export default Vue.extend({
  name: 'advertiserBarC360',
  props: ['isShared', 'isExporting'],
  computed: {
    isOrderPages(): boolean {
      return ['orderlist', 'ordersummary'].includes(this.$route?.query?.tab);
    },
    isSummary(): boolean {
      return this.$store.state.customer.currentNavTab === 'summary';
    },
    showAdvertiserLogo(): boolean {
      if (this.isExporting || this.isShared) return true;
      return this.$route?.name === 'home' || this.$route?.name === 'share';
    },
    advertiserLogo(): string {
      if (this.isShared) return this.$store.state.customer?.sharedSelection?.advertiserLogo;
      if (this.isExporting) return this.$store?.state?.customer?.exportingData?.advertiser?.advertiserLogo;
      return this.$store?.state?.advertiser?.advertiserInfo?.data?.advertiserLogo || '';
    },
    advertiserName(): string {
      if (this.isShared) return this.$store.state.customer?.sharedSelection?.advertiserName;
      if (this.isExporting) return this.$store?.state?.customer?.exportingData?.advertiser?.name;
      return this.$store?.state?.advertiser?.advertiserInfo?.data?.name || 'Loading...';
    },
    advertiserAliases(): string[] {
      if (this.isShared) return this.$store.state.customer?.sharedSelection?.aliases;
      return this.$store?.state?.advertiser?.advertiserInfo?.data?.aliases;
    },
  },
});
