
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'adData',
    'publisher',
    'useIcon',
    'withLabel',
    'isExporting',
    'publisherLogoUrl',
  ],
  data: (): {
    publisherImg: string | null;
  } => ({
    publisherImg: null,
  }),
  mounted() {
    this.getPublisherImage(this.publisher);
  },
  methods: {
    async getPublisherImage(): Promise<string | null> {
      const defaultImageSrc = `https://cdn-dashboard.adportal.io/dashboard/publisher-logos-square/default.png`;
      this.publisherImg = defaultImageSrc;

      if (!this.publisher || typeof this.publisher !== 'string') {
        console.log('Publisher name is required and must be a string.');
        return null;
      }

      // remove (live) or (premium), XANDR suffix, TLDs
      let publisherName = this.publisher
        .split('(')[0]
        .trim()
        .toLowerCase()
        .replace(/\((live|premium)\)|(-[\d]+)$|([^\w\s]+)|\s/g, '')
        .replace(/\.(com|org|net|edu|gov|int|mil|us|biz|info|ca|uk|au|co|ios|android)$/, '')
        .trim();

      const suffixesToRemove = [
        ' apple tv',
        ' android tv',
        ' ott fire tv',
        ' fire tv',
        ' lg',
        ' lg tv',
        ' ott mobile android',
        ' ott mobile ios',
        ' mobile android',
        ' mobile ios',
        ' pv channel',
        ' roku',
        ' roku ott',
        ' roku tv',
        ' samsung tv',
        ' samsung tv plus',
        ' vizio tv',
      ];
      // Apply known suffix removal
      suffixesToRemove.forEach(suffix => {
        if (publisherName.endsWith(suffix)) {
          publisherName = publisherName.slice(0, -suffix.length);
        }
      });
      publisherName = publisherName.replace(/([^\w]+)/g, '');

      const prefixes = ['com', 'app', 'web'];
      prefixes.forEach(prefix => {
        // Check if the string starts with the specified prefix
        if (publisherName.startsWith(prefix)) {
          // Remove the prefix
          publisherName = publisherName.replace(prefix, '');
        }
      });

      // Attempt to apply alias or prefix
      if (!knownImages.includes(publisherName)) {
        publisherName = logoAliases[publisherName] || publisherName;

        Object.keys(logoPrefixes).forEach(prefix => {
          if (publisherName.startsWith(prefix)) {
            publisherName = logoPrefixes[prefix];
          }
        });
      }
      // publisher-logos-square
      const imageSrc = `https://cdn-dashboard.adportal.io/dashboard/publisher-logos-square/${publisherName}.png`;
      // Attempt to fetch and set image
      try {
        const img = await utils.addImageProcess(imageSrc);
        this.publisherImg = this.publisherLogoUrl;

        return img.src;
      } catch (err) {
        if (utils.isDevelopment() || utils.isLocalDev()) {
          console.log(`Error fetching logo for ${this.publisher}: ${err.message}. Attempting TLD fallback.`);
        }

        // Fallback to TLD if the specific logo is not found
        const extractedTLD = this.extractTLD(this.publisher);
        if (extractedTLD) {
          const tldImageSrc = `https://cdn-dashboard.adportal.io/dashboard/publisher-logos-square/${extractedTLD}.png`;
          try {
            const tldImg = await utils.addImageProcess(tldImageSrc);
            this.publisherImg = tldImg.src;
            return tldImg.src;
          } catch (tldErr) {
            if (utils.isDevelopment() || utils.isLocalDev()) {
              console.log(`Also failed fetching TLD logo for ${this.publisher}: ${tldErr.message}`);
            }
          }
        }

        // Log or handle failure to fetch any image
        if (utils.isDevelopment() || utils.isLocalDev()) {
          console.log(`Ultimately failed to fetch any logo for ${this.publisher}.`);
        }
        return defaultImageSrc;
      }
    },
    extractTLD(publisherName: string): string {
      if (typeof publisherName !== 'string') {
        return 'Invalid input';
      }
      const segments = publisherName.split(' ').filter(Boolean);

      let tld = '';
      for (let i = segments.length - 1; i >= 0; i--) {
        const part = segments[i].split('.').pop();

        if (part && ['com', 'net', 'org', 'mobi', 'co'].includes(part)) {
          tld = part;
          break;
        }
      }

      return tld;
    },
    parsePublisherName(value: string): string {
      let name = value.replace('www.', '');
      name = name.replace('https://', '');
      return name;
    },
    getTooltip(publisher: string): string {
      if (publisher.toLowerCase() === 'other') {
        return utils.getTooltipsFromMetric('other').message;
      }
      return publisher;
    },
  },
  watch: {
    publisher(): void {
      // handle paging
      this.publisherImg = null;
      this.getPublisherImage(this.publisher);
    },
  },
});

// if publisherName starts from this prefix
const logoPrefixes = {
  a9: 'a9',
  abc: 'abc30',
  adultswim: 'adultswim',
  accuweather: 'accuweather',
  alphonso: 'alphonso',
  amazon: 'amazon',
  amagi: 'amagi',
  amity: 'amity',
  amc: 'amc',
  amcnetworks: 'amcnetworks',
  amcplus: 'amcplus',
  amcpv: 'amcnetworks',
  ampcheezburger: 'cheezburger',
  animalplanet: 'animalplanet',
  atttv: 'atttv2',
  aenetworksatt: 'a&etelevisionnetworks',
  abpv: 'abpv',
  ae: 'a-e',
  appsapple: 'apple',
  apple: 'apple',
  appletv: 'appletv',
  appnexus: 'appnexus',
  arkadium: 'arkadium',
  espn: 'espn',
  espnnews: 'espn',
  autoreview: 'autoreviewed',
  bbc: 'bbc',
  beachfront: 'beachfront',
  betnow: 'betnow',
  betplus: 'betplus',
  bitcentral: 'bitcentral',
  bloomberg: 'bloomberg',
  blogic: 'blogic',
  bounce: 'bounce',
  brainjolt: 'brainjolt',
  bravo: 'bravo',
  blitz: 'blitz',
  canela: 'canelatv',
  cbsnews: 'cbsnews',
  cbsinteractive: 'cbsinteractive',
  wwwcbsnews: 'cbsnews',
  cbs: 'cbs',
  sling: 'sling',
  cafemedia: 'cafemedia',
  cheddarnetworks: 'cheddar',
  cheezburger: 'cheezburger',
  cnn: 'cnn',
  content: 'content',
  cinedigm: 'cinedigm',
  coredailymailus: 'dailymail',
  consumable: 'consumable',
  column6: 'column6',
  cookingchannel: 'cookingchannel',
  crackle: 'crackleplus',
  criteo: 'criteo',
  christmasplus: 'christmasplus',
  ctv: 'ctv',
  dacarparos: 'dacarparos',
  dailymail: 'dailymail',
  dailymotion: 'dailymotion',
  decido: 'decido',
  discovery: 'discovery',
  disneychannel: 'disney-channel',
  disneyplus: 'disneyplus',
  disneyatt: 'disneystreamingservices',
  dishnetwork: 'dishnetworkllc',
  direct: 'directv',
  dmr: 'dmr',
  elementa: 'elemental',
  entertainmentstudiosnetworks: 'entertainmentstudios',
  estrella: 'estrellamedia',
  exorigos: 'exorigos',
  rokufawesome: 'fawesometv',
  fawesome: 'fawesometv',
  fanbytevideovidazoo: 'vidazoo',
  fandango: 'fandango',
  fgteev: 'fgteev',
  filmrise: 'filmrise',
  firetvfawesome: 'fawesometv',
  firetv: 'firetv',
  pcfiretv: 'firetv',
  fitpro: 'fitpro',
  flux: 'flux',
  foodnetwork: 'foodnetwork',
  foxbusiness: 'foxbusiness',
  foxnews: 'foxnews',
  wwwfoxnews: 'foxnews',
  foxnow: 'foxnews',
  foxsports: 'foxsports',
  foxnetworksatt: 'fox',
  foxlocal: 'fox',
  freewheel: 'freewheel',
  free_movies: 'freemoviesplus',
  freevee: 'freevee',
  freemovie: 'freemoviesplus',
  freemoviesplus: 'freemoviesplus',
  freetubemovies: 'freetubemovies',
  freetvclub: 'freetvclub',
  frndly: 'frndlytv',
  fubotv: 'fubotv',
  fubo: 'fubotv',
  fusion: 'fusion',
  fx: 'fx',
  fxx: 'fxx',
  germane: 'germane',
  glewed: 'glewedtv',
  grayctv: 'graymedia',
  graymedia: 'graymedia',
  gsnnetworksatt: 'gameshownetwork',
  gusto: 'gustotv',
  grocery: 'grocery',
  hafo: 'hafo',
  hallmarkchannel: 'hallmarkchannel',
  haystack: 'haystacktvinc',
  hgtvgo: 'hgtvgo',
  hgtv: 'hgtv',
  history: 'history',
  inuvo: 'inuvo',
  jukinmedia: 'jukinmedia',
  ipg: 'ipg',
  ifunny: 'ifunny',
  investigationdiscovery: 'investigationdiscovery',
  itunes: 'itunes',
  kidoodle: 'kidoodle',
  krush: 'krush',
  lg: 'lg',
  lifetime: 'lifetime',
  localnow: 'localnow',
  uslgappstv: 'lg',
  loopmedia: 'loopmedia',
  wwwlegacy: 'legacy',
  megapureyoga: 'megapureyoga',
  magnite: 'magnite',
  max: 'max',
  mediafuse: 'mediafuse',
  minutemedia: 'minutemedia',
  mlb: 'mlbtv',
  msn: 'msn',
  mumsnet: 'mumsnet',
  ngc: 'natgeo',
  national: 'natgeo',
  natgeo: 'natgeo',
  nbc: 'nbc',
  nbcsn: 'nbcsn',
  nbcu: 'nbcuniversal',
  nbcnews: 'nbcnews',
  newson: 'newsonmediallc',
  newsy: 'newsy',
  newyorkpost: 'nypost',
  netflix: 'netflix',
  nexxen: 'nexxen',
  nhl: 'nhltv',
  nypost: 'nypost',
  nfl: 'nfl',
  nosey: 'nosey',
  olympusat: 'olympusat',
  omgstudios: 'omgstudios',
  onetag: 'onetag',
  oncore: 'oncore',
  openweb: 'openweb',
  openx: 'openx',
  ottstudios: 'ottstudios',
  paramount: 'paramountplus',
  peacock: 'peacock',
  perpetual: 'perpetual',
  philo: 'philo',
  pfblackcard: 'blackcard',
  pics: 'pics',
  pixel: 'pixel',
  plex: 'plex',
  pluto: 'pluto',
  amazonprime: 'amazonprime',
  prime: 'amazonprimevideo',
  pubnative: 'pubnative',
  pubid: 'pubid',
  pubmatic: 'pubmatic',
  rtbexperts: 'rtbexperts',
  theroku: 'roku',
  channelstoreroku: 'roku',
  roku: 'roku',
  rokutv: 'rokutv',
  ron: 'ron',
  rollingstone: 'rollingstone',
  samsung: 'samsung',
  samsungcontentnetworkapps: 'samsungtv',
  samsungelectronicsamericainc_193280: 'samsungtv',
  samsungtvplus: 'samsungtvplus',
  scrabble: 'scrabble',
  segment: 'segment',
  selectmedia: 'selectmedia',
  sendtonews: 'sendtonews',
  seven: 'seven',
  sharethrough: 'sharethrough',
  shinez: 'shinez',
  shemedia: 'shemedia',
  sinclairbroadcastingnewson: 'sinclair',
  simcity: 'simcity',
  smartadserver: 'smartadserver',
  smartnews: 'smartnews',
  smartline: 'smartline',
  sny: 'sny',
  scripps: 'scripps',
  sony: 'sony',
  sonycrackle: 'sonycrackle',
  solitaire: 'solitaire',
  sovrn: 'sovrn',
  spike: 'spike',
  spider: 'spider',
  sportx: 'sportx',
  sports: 'sports',
  spotim: 'spotim',
  springserve: 'springserve',
  standalone: 'standalone',
  sundance: 'sundance',
  synacor: 'synacor',
  take1: 'take1',
  taboo: 'taboo',
  taboola: 'taboola',
  tbs: 'tbs',
  tbcwowhead: 'wowhead',
  tcl: 'tcl',
  tegna: 'tegna',
  telly: 'telly',
  telemundo: 'telemundo',
  teevee: 'teevee',
  turner: 'turnernetworks',
  thecw: 'thecwtelevisionnetwork',
  themediasense: 'themediasense',
  thewashingtonpost: 'washingtonpost',
  thefitnesschannel: 'thefitnesschannel',
  timehop: 'timehop',
  timesinternet: 'timesinternet',
  titan: 'titan',
  tivo: 'tivo',
  tiktok: 'tiktok',
  tlc: 'tlc',
  tlcott: 'tlc',
  toongoggles: 'toongoggles',
  triplelift: 'triplelift',
  truvid: 'truvid',
  trutv: 'trutv',
  thepch: 'pch',
  tubi: 'tubi',
  tumblr: 'tumblr',
  tvg: 'tvg2',
  tvloop: 'loop',
  typea: 'typea',
  twitch: 'twitch',
  viacom: 'viacom',
  univision: 'univision',
  usatoday: 'usatoday',
  ucfunnel: 'ucfunnel',
  wwwusatoday: 'usatoday',
  viki: 'viki',
  vrv: 'vrv',
  watchfreeflix: 'watchfreeflix',
  warnerbrothers: 'warnerbrothers',
  xandr: 'xandr',
  xumo: 'xumollc',
  vevo: 'vevo',
  veranda: 'veranda',
  vidazoo: 'vidazoo',
  tvvida: 'vidazoo',
  video: 'video',
  vizio: 'vizio',
  vgi: 'vgi',
  voqally: 'voqally',
  wattpad: 'wattpad',
  weather: 'weather',
  weatherbug: 'weatherbug',
  weatherbyweatherbug: 'weatherbug',
  theweatherchannel: 'weatherchannel',
  wildearth: 'wildearth',
  wordscapes: 'wordscapes',
  woodoku: 'woodoku',
  wwwwowhead: 'wowhead',
  wwwdailymailco: 'dailymail',
  wwwmsn: 'msn',
  wwwranker: 'ranker',
  wwwcnn: 'cnn',
  wwwdailymotion: 'dailymotion',
  wwwtmz: 'tmz',
  wwwsporcle: 'sporcle',
  wwwfactinate: 'factinate',
  yahtzee: 'yahtzee',
  zam: 'zam',
  orka: 'orkatv',
  tvorka: 'orkatv',
  yahoo: 'yahoo',
  variety: 'variety',
  vix: 'vix',
  wordcrush: 'wordcrush',
  yogacure: 'yogacure',
  youtube: 'youtube',
  zepheere: 'zepheere',
  accnetwork: 'accnetwork',
  big10network: 'big10network',
  cbssportsnetwork: 'cbssportsnetwork',
  dazn: 'dazn',
  espnu: 'espnu',
  fueltv: 'fueltv',
  golfchannel: 'golfchannel',
  nbanetwork: 'nbanetwork',
  nhlnetwork: 'nhlnetwork',
  outdoornetwork: 'outdoornetwork',
  secnetwork: 'secnetwork',
  tennischannel: 'tennischannel',
};

const logoNeedles = {
  hulu: 'hulu',
};

// if publisherName match with this aliase
const logoAliases = {
  ae: 'aenetworks',
  aetelevisionnetworks: 'aetelevisionnetworks2',
  adsm: 'adultswim',
  amazonvideonews: 'amazon',
  anml: 'animalplanet',
  bet: 'bet',
  e: 'e',
  bigstar: 'bigstar2',
  brave_direct: 'brave',
  brvo: 'bravo',
  cmg: 'coxmediagroup',
  cmdy: 'comedycentral',
  cook: 'cookingchannel',
  disc: 'discovery',
  estrella: 'estrellamedia',
  food: 'foodnetwork',
  fox: 'fox',
  foxnc: 'fox-news-channel',
  foxnewsfiretv: 'foxnews',
  herringnetworks: 'herringnetworkinc',
  hideouttv: 'hideout',
  hist: 'history',
  id: 'investigationdiscovery',
  imdbtv: 'amazon',
  life: 'lifetime',
  minutemedia: 'minutemediaortb',
  nbanetwork: 'nba',
  ncaaespn: 'espn',
  ncaaespn2: 'espn2',
  ncaaespnu: 'espnu',
  nrs: 'nrs',
  wwwespn: 'espn',
  newson: 'newsonmediallc',
  plutotv: 'plutotv',
  plutoctv: 'plutotv',
  prowomenssoccer: 'womensprosoccer',
  slingfiretv: 'dishnetworkllc',
  spartantv: 'spartan',
  thechive: 'chivemediagroup',
  travelnetwork: 'travelchannel',
  tru: 'trutv',
  tubitvfiretv: 'tubi',
  turnermedia: 'turnerdigital',
  tvld: 'tv-land',
  video_ron: 'ron',
  videobridgelooptv: 'looptv',
  videoron: 'ron',
  xumo: 'xumollc',
  xumonew: 'xumollc',
  twc: 'weatherchannel',
  usa: 'usa',
  we: 'wetv',
  yoga: 'yoga',
};
/*
Object.keys(logoAliases).sort().reduce(
  (obj, key) => {
    obj[key] = logoAliases[key];
    return obj;
  }, {}
);
*/

// to make things faster, keep the list of logo images we have
const knownImages = [
  '120sportsllc',
  '12up',
  '247sports',
  '33across',
  '47',
  '47samurai',
  '9news',
  'a9',
  'abc30',
  'abpv',
  'abscbn',
  'abscbncorporationdigitalmediadivision',
  'accdn',
  'accenthealth',
  'accuweather',
  'acer',
  'adapex',
  'adaptmx',
  'addapinch',
  'adpushup',
  'adrise',
  'adsiduousmediapng',
  'adthrive',
  'adultswim',
  'advanttechnology',
  'aenetworks',
  'aetelevisionnetworks',
  'aetelevisionnetworks2',
  'alarmy',
  'alcatel',
  'aleteia',
  'alphonso',
  'alternet',
  'alticeusa',
  'amagi',
  'amazon',
  'amazonprimevideo',
  'amcifcsundancehallmarkbbc',
  'americanheros',
  'americanmedia',
  'americasvoice',
  'americasvoicenews',
  'amity',
  'android',
  'animalplanet',
  'aniview',
  'anonymous',
  'answermedia',
  'anthymtv',
  'aol',
  'ap',
  'apnews',
  'appaudience',
  'apple',
  'appnexus',
  'aragoncreek',
  'arkadium',
  'asus',
  'atmosphere',
  'atmospheretv',
  'attcurateddeals',
  'atttv2',
  'au0026etelevisionnetworks',
  'autocreated',
  'autoreviewed',
  'avantis',
  'averiecooks',
  'awkward',
  'axiom',
  'axs',
  'baeble',
  'baeblemedia',
  'baronsmedia',
  'barstoolsports',
  'basketballtalks',
  'batanga',
  'bbc',
  'beachfront',
  'bet',
  'bfmctv',
  'biblegateway',
  'biblehub',
  'bigstar2',
  'bigstarmovies',
  'bigstarplatformron',
  'billboard',
  'bitcentral',
  'blackcard',
  'blackenterprise',
  'bleacherreport',
  'blitz',
  'blogic',
  'bloomberg',
  'blueant',
  'bluecig',
  'blusmartphone',
  'bmtmdirect',
  'boldscreen',
  'boonsmedia',
  'bounce',
  'braincore',
  'brainjolt',
  'brainly',
  'brave',
  'bravo',
  'britannica',
  'bsinteractive',
  'bubble',
  'bulbagarden',
  'businessonweb',
  'bxncvewd',
  'cafemedia',
  'callapp',
  'canelamedia',
  'canelamediainc',
  'canelatv',
  'cash',
  'castify',
  'catapultx',
  'cbr',
  'cbsi',
  'cbsnews',
  'cdvpublishers',
  'cheatsheet',
  'cheddar',
  'cheezburger',
  'chicagotribune',
  'chickensoupforthesoul',
  'chivemediagroup',
  'christmasplus',
  'chron',
  'cignalio',
  'cincinnatibell',
  'cincyjungle',
  'cinedigm',
  'citationmachine',
  'classicwowhead',
  'clickdealer',
  'clipmeup',
  'cnn',
  'co',
  'column6',
  'com',
  'comedycentral',
  'comettv',
  'condenast',
  'condenaste',
  'connatix',
  'constative',
  'consumable',
  'content',
  'cookingchannel',
  'coolpad',
  'cortex',
  'couchmore',
  'courttv',
  'coxmediagroup',
  'crackleplus',
  'criteo',
  'crossword',
  'crownhallmarkchannel',
  'crownmediahallmark',
  'crunchyroll',
  'ctv_aniview',
  'customzones',
  'cw',
  'cynogage',
  'dacarparos',
  'dailycamera',
  'dailymail',
  'dailymotion',
  'dailyroosters',
  'dailytimewaste',
  'dailywire',
  'dangertv',
  'dany',
  'darkmattertv',
  'decido',
  'definition',
  'demandmedia',
  'denofgeek',
  'denverpost',
  'dgo',
  'digital1',
  'digitalfairway',
  'digitalmediarights',
  'digitalsports',
  'directv',
  'discoverycomm',
  'dishtv',
  'disqus',
  'distrotv',
  'dmr',
  'dovechannel',
  'drexlstv',
  'duke',
  'dukedigital',
  'e',
  'eatthis',
  'elemental',
  'elevensports',
  'ellation',
  'ellationcrunchyrollplatform',
  'ellationinc',
  'englisharabicdictionary',
  'enterprises',
  'entertainmentstudios',
  'enthusiastgaming',
  'esimedia',
  'espn2',
  'estrellamedia',
  'ew',
  'ewscrippsnewsy',
  'exorigos',
  'factinate',
  'failarmy',
  'fanbyte',
  'fashiontv',
  'faststudios',
  'fawesometv',
  'fgteev',
  'filmrise',
  'filmzie',
  'finance101',
  'fire',
  'firetv',
  'firstdigital',
  'firsttv',
  'fitpro',
  'flickstream',
  'floor8',
  'flosports',
  'flux',
  'fodors',
  'foodie',
  'foodnetwork',
  'footballscoop',
  'fox',
  'fox19',
  'foxbusiness',
  'foxentertainment',
  'foxnetworksgroup',
  'foxnewsnetworkllc',
  'freebietv',
  'freemoviesplus',
  'freetubemovies',
  'freetvclub',
  'freevee',
  'freewheel',
  'frndlytv',
  'fs1',
  'fs2',
  'funimation',
  'funimationglobalgroup',
  'fuse',
  'fusemedia',
  'fusion',
  'futuretoday',
  'futuretodayinc',
  'fxx',
  'fx',
  'game',
  'gamepedia',
  'gamerguides',
  'gameshownetwork',
  'gameshownetwork_atttv',
  'germane',
  'getreelfireplaces',
  'git',
  'gitberry',
  'glewedmedia',
  'glewedtv',
  'go',
  'google',
  'grahammediagroup',
  'graymedia',
  'greenlemon',
  'grittv',
  'grocery',
  'gustotv',
  'hafo',
  'happycolor',
  'haystacktvinc',
  'hbomax',
  'hearst',
  'herringnetworkinc',
  'hgtv',
  'hgtvgo',
  'hideout',
  'hisense',
  'history',
  'historybyday',
  'hollywoodreporter',
  'hometalk',
  'htc',
  'huawei',
  'hubbard',
  'ifc',
  'ifunny',
  'ihrctv',
  'ilovemydogsomuchtv',
  'imgn',
  'infolinks',
  'inimage',
  'intermedia',
  'inuvo',
  'investigationdiscovery',
  'investing',
  'ion',
  'ios',
  'ipg',
  'iq2',
  'itunes',
  'jackhanna',
  'jadoo',
  'jansonmedia',
  'journy',
  'jukinmedia',
  'junctiontvdovechannel',
  'junkinmedia',
  'justapinch',
  'kansascity',
  'kargo',
  'kentuckysportsradio',
  'kidoodle',
  'krush',
  'kueez',
  'kyocera',
  'lawnewz',
  'lawnewzinc',
  'legacy',
  'lemma',
  'lenovo',
  'lg',
  'lifestyleus',
  'lifetime',
  'linux',
  'literallymedia',
  'livingmgz',
  'lo70s',
  'local12',
  'localnow',
  'longtv',
  'loop',
  'loopme',
  'loopmedia',
  'looptv',
  'lovewhatmatters',
  'lucianne',
  'lunamedia',
  'magellan',
  'magnavox',
  'magnite',
  'mailonline',
  'mailonlinetam',
  'maternityweek',
  'mautilus',
  'mautilussro',
  'mcclatchy',
  'mediafuse',
  'medialab',
  'mediavine',
  'megadilla',
  'megapureyoga',
  'mentalfloss',
  'meredith',
  'meredithcorpo',
  'meredithcorporation',
  'metabattle',
  'metax',
  'mgdl',
  'miamiherald',
  'microsoft',
  'minutemedia',
  'mlbtv',
  'mmplus',
  'mobalytics',
  'mobi',
  'mobilityware',
  'monetengine',
  'moneyawaits',
  'moneywise',
  'morehackz',
  'motorola',
  'movieweb',
  'msn',
  'mtv',
  'mumsnet',
  'muxip',
  'mxq',
  'myyearbook',
  'nameberry',
  'natgeo',
  'naviotv',
  'nba',
  'nbclocal',
  'nbcnews',
  'net',
  'netgear',
  'newarena',
  'newid',
  'news12',
  'news12ctv',
  'newsmax',
  'newsonmediallc',
  'newsy',
  'nextbook',
  'nexxen',
  'nfhsnetwork',
  'nfl',
  'nflnetwork',
  'nglmedia',
  'nhltv',
  'nomnommedia',
  'nosey',
  'nrs',
  'nvidia',
  'nvplayer',
  'nypost',
  'obsev',
  'odkmedia',
  'olmeragroupltd',
  'olympusat',
  'omgstudios',
  'omnify',
  'oncore',
  'oneamericanewsnetwork',
  'onetag',
  'oola',
  'openweb',
  'openx',
  'opera',
  'org',
  'originmedia',
  'orkatv',
  'ottera',
  'ottstudios',
  'outdoormedia',
  'outsidetv',
  'own',
  'pac12',
  'pagesix',
  'panasonic',
  'papayads',
  'paramount-network',
  'parentinfluence',
  'particlemedia',
  'pch',
  'performanceone',
  'perpetual',
  'pestoharelshemesh',
  'philips',
  'phonder',
  'pics',
  'pixel',
  'pixfuture',
  'placeholder',
  'playstationvue',
  'playwire',
  'playwiremediallc',
  'playworks',
  'plex',
  'pocketwatch',
  'popcornflix',
  'potatorgator',
  'powerbombmedia',
  'prebid',
  'premium',
  'primis',
  'prizegrab',
  'programmatic',
  'programmaticmodule',
  'projectjewel',
  'proprofs',
  'pubid',
  'publica',
  'pubmatic',
  'pubnation',
  'pubnative',
  'purdue',
  'pursuitmedia',
  'pursuitup',
  'quizlet',
  'racingamerica',
  'rakuten',
  'ranker',
  'raycommediainc',
  'rca',
  'readlightnovel',
  'realamericasvoice',
  'realclearpolitics',
  'redbox',
  'redboxautomatedretail',
  'resignationmedia',
  'richaudience',
  'rokuzypmedia',
  'rollingstone',
  'rollmob',
  'ron',
  'roughmaps',
  'rsn',
  'rtb',
  'rtbexperts',
  'rtmstudios',
  'sambatv',
  'sambatvnewsy',
  'samsung',
  'samsungtest',
  'saymedia',
  'sbnation',
  'scanner',
  'scrabble',
  'scripps',
  'segment',
  'selectmedia',
  'sendtonews',
  'seven',
  'sharethrough',
  'sheknows',
  'shemedia',
  'shinez',
  'simcity',
  'sinclair',
  'sinclairbroadcasting',
  'sinclairlocalnews',
  'sinclairtelevisiongroupinc',
  'sitnjoy',
  'slader',
  'sling',
  'slingwatch',
  'smartadserver',
  'smartline',
  'smartnews',
  'sny',
  'soapdirt',
  'solitaire',
  'someecards',
  'sony',
  'sonycrackle',
  'sovrn',
  'spacemob',
  'spanishdict',
  'spartan',
  'spectrumsportsnet',
  'spider',
  'spike',
  'spectrumsportsnet_atttv',
  'sporcle',
  'sports',
  'sportx',
  'spotim',
  'spotx_ron',
  'springserve',
  'springservellc',
  'ssp',
  'stadium',
  'standalone',
  'staticmedia',
  'stirr',
  'stormcast',
  'streamkey',
  'streamkeytv',
  'sudoku',
  'sundance',
  'sundancevideo',
  'sunday',
  'synacor',
  'syncbak',
  'syncbakinc',
  'syncbaktv',
  'taboo',
  'taboola',
  'take1',
  'tanix',
  'tastemade',
  'tbs',
  'tcl',
  'tegna',
  'tegnainc',
  'telly',
  'thebishopway',
  'thecwtelevisionnetwork',
  'thedesignnetwork',
  'thefitnesschannel',
  'theknowdenverpost',
  'themaven',
  'themediasense',
  'themeetgroup',
  'thespun',
  'theweathergroup',
  'thewindowsclub',
  'thirdpresence',
  'thisoldhouse',
  'thisoldhouseott',
  'timehop',
  'timesinternet',
  'titan',
  'tivo',
  'tizen',
  'tlc',
  'teevee',
  'tmz',
  'tnt',
  'toongoggles',
  'top5top5',
  'toshiba',
  'townsquare',
  'tradingpolitics',
  'travelchannel',
  'travelerdoor',
  'tricoast',
  'tricoasttv',
  'trinity',
  'triplebmedia',
  'triplebmediallc',
  'triplelift',
  'truisony',
  'trutv',
  'truvid',
  'tumblr',
  'turnerbroadcastingsysteminc',
  'turnercnn',
  'turnerdigital',
  'turnerdigitaldesktop',
  'turnernetworks',
  'tv-land',
  'tvb',
  'tvbusa',
  'tvg2',
  'tvs',
  'tvsourcer',
  'twcapp',
  'twist',
  'twitch',
  'typea',
  'ucfunnel',
  'ududigitalududigitalllc',
  'ultimateguitar',
  'unitytechnologies',
  'univision',
  'univisioncommunications',
  'unreelme',
  'urbanone',
  'usa',
  'usatoday',
  'usphonebook',
  'vamplify',
  'variety',
  'vdo',
  'veranda',
  'vevo',
  'vewd',
  'vgi',
  'vh1',
  'viacom',
  'vid20',
  'vidaa',
  'vidazoo',
  'video',
  'videobyte',
  'videoelephant',
  'vidillion',
  'viewdeos',
  'viki',
  'vix',
  'vixitv',
  'vizio',
  'vizioctv',
  'vlb',
  'vlogbox',
  'voicemediagroup',
  'voqally',
  'vox',
  'vrv',
  'vudu',
  'wackojaco',
  'wanderlusttv',
  'warnerbrothers',
  'washingtonpost',
  'watchcartoononlinebz',
  'watchfree',
  'watchfreeandpluto',
  'watchfreeflix',
  'watchmojo',
  'watchtophomeshowshgtvgo',
  'wattpad',
  'wcpo',
  'weather',
  'weatherbug',
  'weatherchannel',
  'weathernation',
  'wetv',
  'whistletv',
  'wildearth',
  'wimp',
  'windows',
  'wkyc',
  'womensprosoccer',
  'woodoku',
  'wordcrush',
  'wordhippo',
  'wordscapes',
  'wowhead',
  'wtol',
  'wurd',
  'wurl',
  'www',
  'wwwcccom',
  'xandr',
  'xfinity',
  'xiaomi',
  'xplore',
  'xumoentertainment',
  'xumofreemovies',
  'xumokidsfamily',
  'xumollc',
  'xumopopculture',
  'xumoros',
  'xumosciencetech',
  'xumosports',
  'yahoo',
  'yahtzee',
  'yoga',
  'yogacure',
  'yupptv',
  'zam',
  'zazoom',
  'zepheere',
  'zeta',
  'zte',
  // new styled logos
  'default',
  'a-e',
  'a&etelevisionnetworks',
  'amazonprime',
  'amc',
  'amcnetworks',
  'amcplus',
  'appletv',
  'betnow',
  'betplus',
  'cbs',
  'cbsinteractive',
  'ctv',
  'discovery',
  'dishnetworkllc',
  'disney-channel',
  'disneyplus',
  'disneystreamingservices',
  'fandango',
  'fox-news-channel',
  'foxnews',
  'fubo',
  'fubotv',
  'hallmarkchannel',
  'hulu',
  'imdb',
  'max',
  'nbc',
  'nbcsn',
  'nbcuniversal',
  'netflix',
  'paramountplus',
  'peacock',
  'philo',
  'pluto',
  'plutotv',
  'roku',
  'rokutv',
  'samsungtv',
  'samsungtvplus',
  'telemundo',
  'tiktok',
  'tubi',
  'youtube',
  'accnetwork',
  'big10network',
  'cbssportsnetwork',
  'dazn',
  'espn',
  'espnu',
  'foxsports',
  'fueltv',
  'golfchannel',
  'nbanetwork',
  'nhlnetwork',
  'outdoornetwork',
  'secnetwork',
  'tennischannel',
];
