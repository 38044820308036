export const componentConfig = {
  cid: 'orderlistOrderList',
  id: 'orderlistOrderList',
  disabled: null,
  dataSource: 'ORDERLIST.orderList',
  breakpoints: ['lg12', 'sm12'],
  dateRange: 'alltime',
  fetchData: { method: 'getOrderList' },
  pagination: true,
  numberOfItemsPerPage: 10,

  // show "View Details ->" text button, to the left from dates & order name
  showOrderButtons: true,

  // if pacing value is '-' show n/a, BUT Arnel requested not to show
  // n/a on order summary - so maybe we don't need it on post login as well, please ask him
  showEmptyPacing: false,

  // is the whole order row clickable - i think we need it for post-login because we don't have go to order button there.
  // BUT i'm not sure please ask Arnel if single order should be clickable
  isClickable: false,
  showPagination: true,
  showTabs: false,

  // i am NOT SURE if we need this, but currently UI for order list item is slightly different
  // on order summary and post-login. we need to ask Arnel/Daniel, do we want this or we can use only 1 of them
  // if we really want to use diff UIs, we can use this prop to distinguish them. if no - we can remove it i think
  isPostLogin: false,
};
