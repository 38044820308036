import { render, staticRenderFns } from "./addModuleC360.vue?vue&type=template&id=4cdcc024&scoped=true"
import script from "./addModuleC360.vue?vue&type=script&lang=ts"
export * from "./addModuleC360.vue?vue&type=script&lang=ts"
import style0 from "./addModuleC360.vue?vue&type=style&index=0&id=4cdcc024&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdcc024",
  null
  
)

export default component.exports