
import Vue from 'vue';
import { format, parse } from 'date-fns';
import DateRange from '../../../../types/dateRange';
import utils from '../../../../util';
import DateRangeDropdown from './dateRangeDropdown.vue';
import analytics from '../../../../mixins/analytics';

export default Vue.extend({
  name: 'campaignDateSelector',
  props: ['selectedId'],
  inheritAttrs: false,
  mixins: [analytics],
  components: { DateRangeDropdown },
  data(): {
    canRoute: boolean;
  } {
    return {
      canRoute: true,
    };
  },
  computed: {
    // get ALL TIME start and end dates
    getCampaignAbsoluteDates(): string[] {
      let campaignAbsoluteDates = ['', ''];
      if (this.isExporting) {
        const analyticsType =
          this.componentConfig.AnalyticsType ||
          this.sectionConfig.AnalyticsType ||
          this.exportData.layout.AnalyticsType;

        if (!analyticsType) {
          return campaignAbsoluteDates;
        }
        const totals = this.exportData.adData[`${analyticsType}Total`];
        if (!totals) {
          return campaignAbsoluteDates;
        }
        return [totals.ContractStartDate, totals.ContractEndDate];
      }
      campaignAbsoluteDates = utils.getCampaignDateRange(this);
      return campaignAbsoluteDates;
    },
    startDate(): string {
      // shared dashboard
      if (this.$store.state.customer?.sharedSelection?.campaignstartdate)
        return this.$store.state.customer.sharedSelection.campaignstartdate;
      // use the URL param
      else if (this.$route.query.campaignstartdate) return this.$route.query.campaignstartdate; // get from the url
      // get from the store
      if (this.rangeLimits && this.rangeLimits.length > 1) return this.formatMinMaxDate(this.rangeLimits[0]);
      else return '';
    },
    endDate(): string {
      // shared dashboard
      if (this.$store.state.customer?.sharedSelection?.campaignenddate)
        return this.$store.state.customer.sharedSelection.campaignenddate;

      // use the URL param
      if (this.$route.query.campaignenddate) return this.$route.query.campaignenddate;

      // get from the store
      if (this.rangeLimits && this.rangeLimits.length > 1) return this.formatMinMaxDate(this.rangeLimits[1]);
      else return '';
    },
    stopRouteChange(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid && this.Theme?.config?.shareDate;
    },
    rangeLimits(): Array<string> {
      return utils.getCampaignDatePickerRange(this);
    },
    isSummaryView(): boolean {
      return this.$store.state.customer?.sharedSelection?.summaryView
        ? this.$store.state.customer?.sharedSelection?.summaryView
        : this.$route.query.summaryView;
    },
  },
  methods: {
    selectRange(range: DateRange): void {
      if (this.canRoute) {
        // track which range is picked, add dates for custom range.
        const rangeName = range.RangeKey === 'customRange' ? 'Custom Range' : `${range.RangeName}`;
        this.analyticTrack(this.trackValue.EVENT_NAV_CAMPAIGN, rangeName);

        this.canRoute = false;
        this.$store.dispatch('resetCampaignAdPerformance');

        const {
          dmas: _1, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          daterange: _2, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          startdate: _3, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          enddate: _4, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          campaigns: _5, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          types: _6, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          campaigndaterange: _7, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          campaignstartdate: _8, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          campaignenddate: _9, // eslint-disable-line @typescript-eslint/no-unused-vars, no-unused-vars
          ...cleanQuery
        } = this.$route.query;

        // CUSTOM RANGE
        if (range.RangeKey === 'customRange') {
          if (this.stopRouteChange) {
            this.setSharedDateRanges({
              daterange: 'customRange',
              startdate: this.$route.query.startdate,
              enddate: this.$route.query.startdate,
              campaigndaterange: range.RangeKey,
              campaignstartdate: range.StartDate,
              campaignenddate: range.EndDate,
            });
          } else {
            this.$router.push(
              {
                query: {
                  ...cleanQuery,
                  daterange: range.RangeKey,
                  // startdate: this.$route.query.startdate,
                  // enddate: this.$route.query.startdate,
                  campaigndaterange: range.RangeKey,
                  campaignstartdate: range.StartDate,
                  campaignenddate: range.EndDate,
                },
              },
              this.finishRoute, // onComplete
              this.abortRoute, // onAbort
            );
          }
        } else {
          // PRESET RANGES
          if (this.stopRouteChange) {
            this.setSharedDateRanges({
              daterange: range.RangeKey,
            });
          } else {
            this.$router.push(
              {
                query: {
                  ...cleanQuery,
                  daterange: range.RangeKey,
                },
              },
              this.finishRoute, // onComplete
              this.abortRoute, // onAbort
            );
          }
        }
      }
    },
    finishRoute(): void {
      this.canRoute = true;
    },
    abortRoute(): void {
      this.canRoute = true;
    },
    async setSharedDateRanges(dates: object): Promise<void> {
      // set the shared date ranges
      // console.log('setting setSharedDateRanges with', dates);
      await this.$store.dispatch('setSharedDateRanges', dates);
    },
    formatMinMaxDate(date: string): string | undefined {
      if (!date) return;
      try {
        const formats = {
          monthDayYear: /[0-9]{2}-[0-9]{2}-[0-9]{4}/,
          yearMonthDay: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
        };
        let parsedDate;
        if (formats.yearMonthDay.test(date)) parsedDate = parse(date, 'yyyy-MM-dd', new Date());
        else if (formats.monthDayYear.test(date)) parsedDate = parse(date, 'MM-dd-yyyy', new Date());
        else parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss.SSS a', new Date()); // parse custom date format
        return format(parsedDate, 'yyyy-MM-dd');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('min max date', date, err);
      }
      return '';
    },
  },
});
