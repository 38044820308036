
import Vue from 'vue';
import AdPreviewText from './adPreviewText.vue';
import util from '../../../../util';

export default Vue.extend({
  inheritAttrs: false,
  props: ['sectionConfig', 'componentConfig', 'adData', 'creativeType'],
  components: { AdPreviewText },
  data: (): {
    dialog: boolean;
    showFullAd: boolean;
    isMobile: boolean;
  } => ({
    dialog: false,
    showFullAd: false,
    isMobile: false,
  }),
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize(): void {
      this.isMobile = window.innerWidth < 1000;
    },
    dialogToggle(): void {
      this.dialog = true;
    },
  },
  computed: {
    isResponsiveSearch(): boolean {
      return this.adData.adType === 'responsive search ad';
    },
    isExpandedText(): boolean {
      return this.adData.adType === 'expanded text ad';
    },
    headlineText(): string {
      let headline = '';
      if (this.isExpandedText) {
        headline = `${this.adData.headline1} | ${this.adData.headline2} | ${this.adData.expandedTextAdHeadline3}`;
      } else if (this.isResponsiveSearch) {
        this.adData.responsiveSearchAdHeadlines.forEach((h: string, i: number) => {
          if (i < 3) headline = `${headline} ${i === 0 ? '' : '|'} ${h}`;
        });
      }
      return headline;
    },
    descriptionText(): string {
      let description = '';
      if (this.isExpandedText) {
        description = `${this.adData.description} ${this.adData.expandedTextAdHeadline3}`;
      } else if (this.isResponsiveSearch) {
        this.adData.responsiveSearchAdDescriptions.forEach((d: string) => {
          description = `${description} ${d}`;
        });
      }
      return description;
    },
  },
});
