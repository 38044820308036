
import Vue from 'vue';
import utils from '../../../../util';
import RuleTypes from '../../../../types/validationRules';

const validationRules = utils.ValidationRules;

export default Vue.extend({
  name: 'ItemLimit',
  props: ['componentData'],
  data: (): {
    limitValue: number;
    enabled: boolean;
    valid: boolean;
    validationRules: RuleTypes;
  } => ({
    limitValue: 0,
    enabled: false,
    valid: false,
    validationRules,
  }),
  created(): void {
    this.limitValue = this.componentData?.itemLimit ? this.componentData.itemLimit : 0;
    if (this.limitValue > 0 || this.componentData.overrides?.itemLimit) this.enabled = true;
  },
  methods: {
    setOverride(): void {
      if (this.enabled) {
        this.$emit('setOverride', 'itemLimit', `${this.limitValue}`);
      } else {
        this.resetValidation();
        this.limitValue = 0;
        this.$emit('setOverride', 'itemLimit', '0');
      }
    },
    resetValidation(): void {
      this.$refs.itemLimitForm.resetValidation();
    },
  },
});
