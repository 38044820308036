
import Vue from 'vue';
import { componentConfig, dataSource, componentHeight } from './utils/configZipCodeTable';
import { OrderService } from '../../../services/order-summary';
import genericTable from '../../components/charts/tables/table.vue';

const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderDetailsGeo',
  data: (): {
    impressions: any[];
    loading: boolean;
    componentHeight: string;
    componentConfig: any;
    dataSource: string;
    daterange: string;
  } => ({
    componentHeight: componentHeight,
    loading: false,
    impressions: [],
    componentConfig,
    dataSource,
    daterange: 'thismonth',
  }),
  components: {
    genericTable,
  },
  watch: {
    daterange: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getImpressionsByZip(true);
        }
      },
    },
    singleOrderParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any): void {
        if (!newVal?.daterange) return;
        this.daterange = newVal.daterange;
      },
      deep: true,
    },
  },
  created() {
    this.updateImpressionsByZip(true);
  },
  computed: {
    singleOrderParams(): any {
      return this.$store.state.order.singleOrderParams;
    },
  },
  methods: {
    async updateImpressionsByZip(withLoading: boolean) {
      await this.getImpressionsByZip(withLoading);
    },
    async getImpressionsByZip(withLoading: boolean) {
      try {
        this.loading = withLoading;
        const impressionsData = await orderService.getOrderImpressionsByZip({
          daterange: this.daterange,
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
        });
        this.impressions = impressionsData;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
