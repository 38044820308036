
import Vue from 'vue';
export default Vue.extend({
  props: ['segmentData', 'isExporting'],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: Array<any>;
  } => ({
    headers: [
      {
        text: 'Segment Type',
        value: 'SegmentType',
        sortable: false,
      },
      {
        text: 'Segment',
        value: 'Segment',
        sortable: false,
      },
    ],
  }),
});
