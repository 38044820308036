
import Vue from 'vue';

export default Vue.extend({
  props: ['hidePieChart'],
  data: () => ({
    selection: '',
    hidePieChartOptions: [
      { text: 'Hide', value: true },
      { text: 'Display', value: false },
    ],
  }),
  created(): void {
    const currSelection = this.hidePieChart;
    this.selection = this.hidePieChartOptions.find(bp => bp.value === currSelection);
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'hidePieChart', this.selection);
    },
  },
});
