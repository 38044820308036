
import Vue from 'vue';
import OrderMetrics from '../components/orderMetrics.vue';
import OrderList from '../components/orderList.vue';
import tableHeaderC360 from '../../components/charts/tables/tableHeaderC360.vue';
import analytics from '../../../mixins/analytics';

export default Vue.extend({
  name: 'OrderListController',
  components: { OrderList, OrderMetrics, tableHeaderC360 },
  mixins: [analytics],
  methods: {},
  mounted(): void {
    this.analyticTrack(this.trackValue.ORDERS_VIEW);
  },
});
