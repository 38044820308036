
import Vue from 'vue';
import { daterangeDefault } from './utils/constants';
import DropdownMenu from './dropdownMenu.vue';
import { Range, RangeText, EnhancedDateRange } from './types/types';

export default Vue.extend({
  components: { DropdownMenu },
  props: ['loading', 'moduleName'],
  data(): {
    selectedRange: { RangeName: string; RangeKey: Range; Text: RangeText } | null;
  } {
    return {
      selectedRange: { RangeName: 'This Week', RangeKey: Range.THISWEEK, Text: RangeText.THISWEEK },
    };
  },
  computed: {
    options(): EnhancedDateRange[] {
      return daterangeDefault;
    },
  },
  methods: {
    handleSelected(item) {
      if (this.selectedRange.RangeKey === item.RangeKey || this.loading) {
        return;
      }
      this.selectedRange = item;
      this.$emit('select-range', item);
    },
  },
});
