
import Vue from 'vue';
import PublisherImage from './publisher-image.vue';
import AdPreview from './adPreview.vue';
import { ExpandedTextAd, ResponsiveSearchAd } from '../../../../types/adPreview';
import utils from '../../../../util';
import { C360Icon } from '@c360/ui';
import axios from 'axios';

export default Vue.extend({
  components: { PublisherImage, AdPreview, C360Icon },
  props: [
    'creative',
    'creativeType',
    'isPublisherImage',
    'showAdPreview',
    'isExporting',
    'isExportDynamic',
    'isXLS',
    'rawData',
  ],
  data: (): {
    dialog: boolean;
    videoLink: string;
    errorMessage: string;
    isVastLoading: boolean;
  } => ({
    dialog: false,
    videoLink: '',
    errorMessage: '',
    isVastLoading: false,
  }),
  computed: {
    creativeId(): string {
      return this.creative.CreativeId;
    },
    showImgContainer(): boolean {
      const isAGraphic = !this.isXLS && (this.isVideo || this.isImage || this.isAudio || this.isYoutubeVideo);
      return isAGraphic || this.showAdPreview;
    },
    isYoutubeVideo(): boolean {
      return this.creativeType === 'youtubevideo';
    },
    isVideo(): boolean {
      return this.creativeType === 'video';
    },
    isImage(): boolean {
      return this.creativeType === 'image';
    },
    isAudio(): boolean {
      return this.creativeType === 'audio';
    },
    isTextValue(): boolean {
      return this.creativeType === 'text';
    },
    isVast(): boolean {
      const filesExtensions = ['mp4', 'jpg', 'jpeg', 'png', 'gif'];
      if (this.creativeType !== 'video') return false;
      const creative = this.rawData?.find((row: object) => row['CreativeId'] === this.creativeId);
      if (!creative?.CreativeUrl) return false;
      if (!filesExtensions.some(ext => creative?.CreativeUrl?.endsWith(ext)) && !creative?.CreativeVideo) return true;
      return false;
    },
    iframeDimensions(): { height: string; width: string } {
      const dimensions = { height: 'auto', width: 'auto' };
      if (this.previewData.image) {
        const heightStr = this.previewData.image.split('height=')[1];
        const widthStr = this.previewData.image.split('width=')[1];
        if (heightStr) {
          dimensions.height = `${parseInt(heightStr)}px`;
        }
        if (widthStr) {
          dimensions.width = `${parseInt(widthStr)}px`;
        }
      }
      return dimensions;
    },
    thumbnailDimensions() {
      const dimensions = { height: 'auto', width: '100%' };
      if (this.previewData.image) {
        const heightStr = this.previewData.image.split('height=')[1];
        if (heightStr) {
          dimensions.height = `${parseInt(heightStr)}px`;
        }
      }
      return dimensions;
    },
    dialogWidth(): number {
      let width = 510;
      if (this.isIFrame && this.isImage && this.previewData.image) {
        const widthStr = this.previewData.image.split('width=')[1];
        if (widthStr) {
          width = parseInt(widthStr) + 50;
        }
      }
      return width;
    },
    noPreview(): boolean {
      let noPreview = false;
      if (this.showAdPreview) {
        noPreview = !this.adPreviewData;
      } else if (this.isImage) {
        noPreview = !this.previewData.image;
      } else if (this.isVideo) {
        noPreview = !this.previewData?.video || this.previewData?.video?.toLowerCase() === 'null';
      } else if (this.isAudio) {
        noPreview = !this.previewData.audio;
      } else if (this.isYoutubeVideo) {
        noPreview = !this.creativeId;
      } else if (this.isIFrame) {
        return this.iframeDimensions.height === '1px' && this.iframeDimensions.width === '1px';
      }
      return noPreview;
    },
    isIFrame(): boolean {
      const { tab } = this.$route.query;
      return tab === 'gamdisplay' || tab === 'gamvideo';
    },
    previewData(): {
      video: string;
      image: string;
      audio: string;
      publisher: string;
      text: string;
      videoimg: boolean;
      publisherLogoUrl: string;
    } {
      const obj = { video: '', image: '', audio: '', publisher: '', text: '', videoimg: false, publisherLogoUrl: '' };

      if (this.isPublisherImage) {
        const logo = this.rawData?.find((row: object) => row['Publisher'] === this.creative.Publisher)?.ImageURL;
        obj.publisher = this.creative.Publisher;
        obj.publisherLogoUrl = logo;
      } else if (this.isTextValue) {
        obj.text = this.creative.Metro;
      } else {
        const found = this.rawData?.find((row: object) => row['CreativeId'] === this.creativeId);
        if (found) {
          obj.image = found.CreativeImage || found.CreativeVideo || found.CreativeUrl; // data is a little inconsistent
          if (obj.image && obj.image.toLowerCase() !== 'null') {
            obj.image = obj.image.replace('http:', 'https:'); // fix data issue
            if (obj.image.toLowerCase().match(/([\w-]+)\.(mp4)+/)) {
              obj.videoimg = true;
            } else if (!this.isIFrame && !obj.image.toLowerCase().match(/([\w-]+)\.(jpg|jpeg|png|gif)+/)) {
              obj.image = '';
            } else if (this.isIFrame) {
              const heightStr = obj.image.split('height=')[1];
              const widthStr = obj.image.split('width=')[1];
              if (parseInt(heightStr) === 1 && parseInt(widthStr) === 1) {
                obj.image = '';
              }
            }
          } else {
            obj.image = '';
          }
          if (this.isVideo) {
            obj.video = found.CreativeVideo || found.CreativeUrl; // data is a little inconsistent
            this.videoLink = obj.video;
          }
          if (this.isAudio) {
            obj.audio = found.CreativeUrl;
          }
        }
      }
      return obj;
    },
    previewDataStyle(): string {
      if (!this.isExporting && !this.noPreview) return 'position: absolute';
      return '';
    },
    youtubeLink(): string {
      return `https://www.youtube.com/embed/${this.creativeId}`;
    },
    isCadent(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('CADENT');
    },
    adPreviewData(): ExpandedTextAd | ResponsiveSearchAd | null {
      let ads = this.rawData;
      if (!ads || !Array.isArray(ads)) {
        // todo: if 'admin', message about the data not being right
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ads = ads.map((x: any) => ({ ...x })); // shallow clone, for transforms to stay localized to this copy of the data
      const found = ads.find(ad => ad.CreativeId === this.creativeId);

      let adData;
      if (found && found.adType) {
        if (found.adType?.toLowerCase() === 'expanded text ad') {
          // expanded text ad
          const properties = [
            'headline1',
            'headline2',
            'expandedTextAdHeadline3',
            'description',
            'expandedTextAdDescription2',
            'finalURL',
          ];
          if (utils.hasAllProperties(found, properties)) {
            const url = found.finalURL.split('["')[1].split('//')[1].split('/')[0];
            adData = {
              adType: found.adType?.toLowerCase(),
              headline1: found.headline1,
              headline2: found.headline2,
              expandedTextAdHeadline3: found.expandedTextAdHeadline3,
              description: found.description,
              expandedTextAdDescription2: found.expandedTextAdDescription2,
              finalURL: `https://${url}`,
            };
          }
        } else if (found.adType?.toLowerCase() === 'responsive search ad') {
          const properties = ['responsiveSearchAdHeadlines', 'responsiveSearchAdDescriptions', 'finalURL'];
          // responsive search ad data
          if (utils.hasAllProperties(found, properties)) {
            const headlines: string[] = [];
            const descriptions: string[] = [];

            const tempHeadlineArr = found.responsiveSearchAdHeadlines.split('[')[1].split(']')[0].split('},');
            const tempDescriptionArr = found.responsiveSearchAdDescriptions.split('[')[1].split(']')[0].split('},');

            tempDescriptionArr.forEach(d => {
              if (d.indexOf('}') === -1) d = `${d}}`;
              try {
                descriptions.push(JSON.parse(d).assetText);
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error('responsiveSearchAdHeadlines data error', err, d);
              }
            });
            tempHeadlineArr.forEach(h => {
              if (h.indexOf('}') === -1) h = `${h}}`;
              try {
                headlines.push(JSON.parse(h).assetText);
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error('responsiveSearchAdDescriptions data error', err, h);
              }
            });

            const url = found.finalURL.split('["')[1].split('//')[1].split('/')[0];

            adData = {
              adType: found.adType?.toLowerCase(),
              responsiveSearchAdHeadlines: headlines,
              responsiveSearchAdDescriptions: descriptions,
              finalURL: `https://${url}`,
            };
          }
        }
      }
      return adData;
    },
  },
  methods: {
    toggleDialog(): void {
      if (this.showAdPreview) {
        this.$refs[this.creativeId].dialogToggle();
      } else {
        this.dialog = !this.dialog;
      }
      if (this.isVast) {
        this.parseVast();
      }
    },
    async parseVast(): Promise<void> {
      const link = this.previewData.video.replace('http', 'https');
      try {
        this.isVastLoading = true;
        const creativeData = await axios.get(link);
        const xml = new DOMParser().parseFromString(creativeData.data, 'text/xml');
        const mfNodes = xml.getElementsByTagName('MediaFile');
        const mf = mfNodes[0]?.childNodes[0]?.data;
        this.videoLink = mf || null;
      } catch (error) {
        console.error('Error fetching VAST', error);
        this.errorMessage = error?.response?.data?.error || 'VAST Creative is unavailable';
      } finally {
        this.isVastLoading = false;
      }
    },
  },
});
