import DateRange from '../../../../../types/dateRange';

export enum Range {
  THISWEEK = 'thisweek',
  THISMONTH = 'thismonth',
  THISYEAR = 'thisyear',
  ALLTIME = 'alltime',
}

export enum RangeText {
  THISWEEK = '7 Days',
  THISMONTH = '30 Days',
  THISYEAR = '365 Days',
  ALLTIME = null,
}

export enum ModuleName {
  METRICS = 'METRICS',
  FULLMETRICS = 'FULLMETRICS',
  FULLMETRICS_LEGEND = 'FULLMETRICS_LEGEND',
  PRODUCTS = 'PRODUCTS',
  CLIENTS = 'CLIENTS',
}

export enum SortType {
  Impressions = 'impressions',
  Increase = 'increase',
}

export interface EnhancedDateRange extends Omit<DateRange, 'Text' | 'RangeKey'> {
  Text: RangeText;
  RangeKey: Range;
}
