
import Vue from 'vue';
import _clone from 'lodash.clonedeep';
import utils from '../../../../util';
import analytics from '../../../../mixins/analytics';

export default Vue.extend({
  name: 'MapLayoutBtns',
  props: ['componentData', 'isMapAndSummary'],
  mixins: [analytics],
  methods: {
    separate(option: string): void {
      const { cid, id } = this.componentData;
      const breakpoints = ['lg6', 'sm12'];
      const bothOption = option === 'both';
      const mapOption = option === 'map';
      const summaryOption = option === 'summary';
      this.analyticTrack(
        this.trackValue.EVENT_CHANGE_MODULE_SETTING,
        `Summary - ${
          this.option === 'both'
            ? 'Separate into summary and map'
            : this.option === 'map'
            ? 'Make into map only'
            : 'Make into summary only'
        }`,
      );
      const onlyMap = {
        cid,
        id: `${id}OnlyMap`,
        overrides: { onlyMap: true, id: `${id}OnlyMap`, breakpoints },
      };
      const onlySummary = {
        cid,
        id: `${id}OnlySummary`,
        overrides: { onlySummary: true, id: `${id}OnlySummary`, breakpoints },
      };

      // add to customizations
      let customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      customizations = customizations.reduce((all, cust) => {
        if (cust.id === this.componentData.id) {
          if (bothOption || summaryOption) all.push(onlySummary);
          if (bothOption || mapOption) all.push(onlyMap);
        } else {
          all.push(cust);
        }
        return all;
      }, []);
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);

      // add to dynamic layout
      const dynamicComponents = this.$store.state.customer?.dynamicLayout?.layoutCustomizations?.config.components.find(
        c => c.type !== 'summaryonly',
      );
      const defaultComponents = utils.getLayoutComponents(this, 'default');
      const found = _clone(defaultComponents.find(comp => comp.cid === this.componentData.cid));
      if (found) {
        const onlyMapComp = {
          ...found,
          overrides: { onlyMap: true, id: `${id}OnlyMap`, title: 'Map Only', breakpoints },
        };
        const onlySummaryComp = {
          ...found,
          overrides: { onlySummary: true, id: `${id}OnlySummary`, title: 'Campaign Summary Only', breakpoints },
        };

        Object.keys(onlyMapComp.overrides).forEach(key => {
          onlyMapComp[key] = onlyMapComp.overrides[key];
        });
        Object.keys(onlySummaryComp.overrides).forEach(key => {
          onlySummaryComp[key] = onlySummaryComp.overrides[key];
        });

        if (dynamicComponents?.components) {
          dynamicComponents.components = dynamicComponents.components.reduce((all, comp) => {
            if (comp.id === this.componentData.id) {
              if (bothOption || summaryOption) all.push(onlySummaryComp);
              if (bothOption || mapOption) all.push(onlyMapComp);
            } else {
              all.push(comp);
            }
            return all;
          }, []);
        }
        this.$store.dispatch('setLayout', this.$store.state.customer.dynamicLayout);
        this.$store.dispatch('layoutEditor/setEnableSave', true);
        this.$store.dispatch('layoutEditor/closeEditComponent');
      }
    },
    combine(): void {
      // get default map
      const defaultMap = utils.getLayoutComponents(this, 'default').find(comp => this.isMap(comp.name));

      // delete separated map and summary
      if (defaultMap) {
        this.deleteComponent(`${defaultMap.id}OnlySummary`);
        this.deleteComponent(`${defaultMap.id}OnlyMap`);
      }

      // add in default map component back in
      const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      customizations.unshift({ id: defaultMap.id, cid: defaultMap.cid, overrides: null });
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);

      this.$store.dispatch('addNewModule', { newModule: defaultMap, makeFirst: true, hasAdvLevel: false });

      this.$store.dispatch('layoutEditor/setEnableSave', true);
      this.$store.dispatch('layoutEditor/closeEditComponent');

      this.analyticTrack(this.trackValue.EVENT_CHANGE_MODULE_SETTING, 'Combine map and summary');

      // scroll to top
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
    },
    deleteComponent(id: string): void {
      const customizations = this.$store.state.layoutEditor.currCustomizations.filter(
        cust => cust?.id?.toLowerCase() !== id.toLowerCase(),
      );

      const { dynamicLayout } = this.$store.state.customer;
      const dynamicLayoutComps = dynamicLayout.layoutCustomizations.config.components.find(
        c => c.type !== 'summaryonly',
      );
      dynamicLayoutComps.components = dynamicLayoutComps.components.filter(comp => {
        const found = customizations.find(cust => cust?.id?.toLowerCase() === comp?.id?.toLowerCase());
        return found;
      });

      this.$store.dispatch('setLayout', dynamicLayout);
      this.$store.dispatch('layoutEditor/setCurrCustomizations', customizations);
    },
    isMap(chart: string) {
      return ['dmaZipMap', 'stationMap', 'genericMap', 'geofenceMap'].includes(chart);
    },
  },
});
