
import Vue from 'vue';
import { C360Header } from '@c360/ui';
import utils from '../../../util';
import { formatDate } from '../../order/orderUtils';

export default Vue.extend({
  name: 'adportal-export-header',
  inheritAttrs: false,
  components: { C360Header },
  props: ['sectionConfig', 'componentConfig', 'exportData', 'exportContext'],
  computed: {
    title(): string {
      const advertiser_name = this.$store.state?.advertiser?.advertiserInfo?.data?.advertiser;
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig?.title, this.exportData)) || advertiser_name;
    },
    subtitle(): string {
      const selection = this.$store.state.customer?.selection;
      const start_date = formatDate(selection?.startdate);
      const end_date = formatDate(selection?.enddate);
      const range = `(${start_date} - ${end_date})`;
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig?.subtitle, this.exportData)) || range;
    },
    documentName(): string {
      let document_name = ''
      const campaign_list = this.$store.state.customer?.campaignAdPerformance?.CampaignList;
      
      if (campaign_list?.length === 1) {
        document_name = campaign_list[0].FriendlyName;

        if (document_name.length > 90) {
          document_name = document_name.substring(0, 90) + '...';
        }
      } else if (campaign_list?.length > 1) {
        document_name = 'Multiple Campaigns';
      }

      return utils.htmlEncode(utils.renderTemplate(this.componentConfig?.documentName, this.exportData)) || document_name;
    },
    documentType(): string {
      const type =  this.$store.state.customer?.currentSection?.title;
      return utils.htmlEncode(utils.renderTemplate(this.componentConfig?.documentType, this.exportData)) || type;
    },
    c360Link(): string {
      return process.env.VUE_APP_C360_URL || '';
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('rendered', { empty: false });
    }, 200); // todo: properly wait for any image to load
  },
});
