
import Vue from 'vue';
import { format, parse } from 'date-fns';
import ColorLabel from '../components/tacticTables/colorLabel.vue';
import CenteredHeader from '../components/tacticTables/centeredHeader.vue';
import Tabs from '../components/tacticTables/tabs.vue';
import utils from '../../../../util';
import { validProducts } from '../utils';
import { CurrentSection } from '../../../../types/layout';
import analytics from '../../../../mixins/analytics';

interface Header {
  align: string;
  text: string;
  value: string;
  sortable?: boolean;
  width?: string;
}

export default Vue.extend({
  name: 'summaryTacticTablesController',
  inheritAttrs: false,
  props: [
    'campaignId',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'mapAdData',
    'sectionConfig',
    'template',
  ],
  components: { ColorLabel, CenteredHeader, Tabs },
  data: (): {
    config: {
      itemsPerPageOptions: number[];
      currentOption: number;
      tables: object[] | null;
      totalCampaignCount: number;
    };
  } => ({
    config: {
      currentOption: 5,
      itemsPerPageOptions: [5, 10, 15],
      tables: null,
      totalCampaignCount: 0,
    },
  }),
  mixins: [analytics],
  created(): void {
    setTimeout(() => {
      this.setTablesData();
      this.config['tableHeaders'] = this.tableHeaders;
      this.config.template = this.template;
    }, 100);
  },
  methods: {
    changeItemsPerPage(option: number): void {
      this.analyticTrack(this.trackValue.PRODUCT_SUMMARY_CHANGE_NUMBER_OF_ITEMS, `New Value: ${this.option}`);
      this.config.currentOption = option;
    },
    setTablesData(): void {
      if (!this.summaryData) return;

      let productList = validProducts;
      if (this.isExporting && this.componentConfig?._id_version) {
        const pair = this.componentConfig?._id_version.split(':');
        if (pair.length === 2 && productList.includes(pair[1])) {
          productList = [pair[1]];
        }
      }

      const tables = productList.reduce((all: object[], product: string) => {
        // check that there's good summaryData to show the product
        // and if it is not disabled in the customizations
        const data = this.summaryData[product];
        if (data && !this.disabledProducts?.includes(product)) {
          const name = utils.getTacticName(data.TagName);
          all.push({
            tacticName: name,
            campaigns: data.CampaignList,
            analyticsType: product,
          });
        }
        return all;
      }, []);

      this.config.tables = tables;
      this.config.totalCampaignCount = this.config?.tables.reduce((sum, table) => {
        if (table?.campaigns?.length) {
          sum += table.campaigns.length;
        }
        return sum;
      }, 0);
    },
    tableHeaders(tactic: string): Header[] {
      const rules = {
        Tactics: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV'],
        Impressions: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Facebook Ads',
          'SEM',
          // 'Digital Video',
          'YouTube',
          'GAM',
          'Sinclair RSN',
          'Video - O&O',
          'Display - O&O',
          'Broadstreet - O&O',
          'Audio',
          'InnovidXP',
          'True Geo',
        ],
        ImpressionGoal: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV', 'Audio', 'True Geo'],
        ImpressionGoalPercent: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Display - O&O',
          'Audio',
          'True Geo',
        ],
        Views: ['Email Marketing'],
        Clicks: ['Email Marketing', 'Broadstreet - O&O'],
        Hovers: ['Broadstreet - O&O'],
        ClickToViewRate: ['Email Marketing'],
        ClickThrough: ['SEM'],
        TotalProcessedCalls: ['Call Tracking'],
        DurationAvg_ProcessedCalls: ['Call Tracking'],
        Aired: ['TV'],
        Visits: ['TV'],
        VisitsAiring: ['TV'],
        Conversions: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV'],
        CVR: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV'],
        all: ['FriendlyName', 'StartDate', 'EndDate', 'Pacing'],
      };

      const orderByValue = [
        'Pacing',
        'FriendlyName',
        'Tactics',
        'StartDate',
        'EndDate',
        'Impressions',
        'ImpressionGoal',
        'ImpressionGoalPercent',
        'Conversions',
        'CVR',
        'Views',
        'Clicks',
        'ClickToViewRate',
        'ClickThrough',
        'TotalProcessedCalls',
        'DurationAvg_ProcessedCalls',
        'Hovers',
        'Aired',
        'Visits',
        'VisitsAiring',
      ];

      const headers = orderByValue.reduce((all: Header[], value: string) => {
        const header = { align: 'left', text: 'Campaign name', value };
        if (value === 'FriendlyName') {
          header['sortable'] = false;
          header['width'] = '35%';
          if (this.template === 'centeredHeader') {
            header.text = '';
          }
        } else {
          if (value === 'Impressions') {
            if (tactic.toLowerCase() === 'sinclair rsn') header['text'] = 'Household Imps';
            else header['text'] = 'Imps All Time';
          } else {
            header['text'] = utils.headerNamesMap(value);
          }
        }

        // DASH-4854: hide Conversions and CVR columns if there is no data for them
        const data = this.config?.tables?.find(obj => obj.tacticName === tactic);
        const hasNoConversionsData = !data?.campaigns?.some(row => row.Conversions);
        if ((rules[value] && rules[value].includes(tactic)) || rules.all.includes(value)) {
          if (!(hasNoConversionsData && ['Conversions', 'CVR', 'CVR %'].includes(header.text))) {
            all.push(header);
          }
        }
        return all;
      }, []);

      headers.forEach(header => {
        const minNumberOfSymbolsInLargePacing = 9;
        const data = this.config?.tables?.find(obj => obj.tacticName === tactic);
        const hasLargePacing = data?.campaigns?.some(
          campaign => campaign?.Pacing?.length > minNumberOfSymbolsInLargePacing,
        );

        if (header['value'] === 'FriendlyName') {
          header['width'] = hasLargePacing ? '38%' : '40%';
          return;
        }
        if (header['value'] === 'Pacing') {
          if (hasLargePacing) {
            header['width'] = '130px';
          } else {
            header['width'] = '90px';
          }
          return;
        }
      });

      return headers;
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    startExportRender(): void {
      this.$nextTick(() => {
        // need some buffer time to load the material icons
        setTimeout(() => {
          if (this.isXLS) {
            this.exportTableData();
            return;
          }
          // see if we need to skip some table rendered on a previous page
          // console.log('currentExportContext', JSON.stringify(this.exportContext));
          const renderedIndexes: number[] = [];
          if (this.componentConfig?.renderedIndexes) {
            const tables = Array.from(document.querySelectorAll('.tactic-table'));
            this.componentConfig.renderedIndexes.forEach((i: number) => {
              const el = tables[i] as HTMLElement;
              el.style.display = 'none';
              renderedIndexes.push(i);
            });
          } else if (this.componentConfig?.continueFrom && this.exportContext) {
            const ctx = this.exportContext[this.componentConfig.id];
            if (ctx && ctx.renderedIndexes) {
              const tables = Array.from(document.querySelectorAll('.tactic-table'));
              ctx.renderedIndexes.forEach((i: number) => {
                const el = tables[i] as HTMLElement;
                el.style.display = 'none';
                renderedIndexes.push(i);
              });
            }
          }

          // if component has height, remove lines that are out of view
          if (this.componentConfig?.size?.height) {
            const host = this.$refs.hostElement;
            if (host) {
              const tables = Array.from(document.querySelectorAll('.tactic-table'));
              tables.forEach((el: HTMLElement, i: number) => {
                if (el.offsetTop + el.offsetHeight > this.componentConfig.size.height) {
                  el.style.display = 'none';
                } else if (el.style.display !== 'none') {
                  renderedIndexes.push(i);
                }
              });
              // console.log('visible tables count', visibleCounter);
              if (tables.length <= renderedIndexes.length) {
                setTimeout(() => {
                  this.$emit('rendered', { empty: false, truncated: true, completed: true, renderedIndexes });
                }, 10);
              } else if (tables.length > renderedIndexes.length) {
                setTimeout(() => {
                  this.$emit('rendered', { empty: false, truncated: true, renderedIndexes });
                }, 10);
              } else {
                setTimeout(() => {
                  if (this.config.tables.length > 0) {
                    this.$emit('rendered', { empty: false });
                  } else {
                    this.$emit('rendered', { empty: true });
                  }
                }, 10);
              }
              return;
            } else {
              // eslint-disable-next-line no-console
              console.log('host element not found', this.$refs);
            }
          }
          setTimeout(() => {
            if (this.config.tables.length > 0) {
              this.$emit('rendered', { empty: false });
            } else {
              this.$emit('rendered', { empty: true });
            }
          }, 10);
        }, 100);
        return;
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportTableCampaigns(campaigns): any {
      if (campaigns[0]?.AnalyticsType === 'SEM') {
        // different data has the campaign end and start dates, so some combining of that data is necessary
        campaigns.forEach(camp => {
          this.exportCampaignList.forEach(c => {
            if (c.FriendlyName === camp.FriendlyName) {
              if (c.ContractEnd) {
                camp.ContractEnd = c.ContractEnd;
              } else {
                camp.ContractEnd = 'noEndDate';
              }
              // also add LastModifiedDate
              if (c.LastModifiedDate) {
                const date: Date | string = utils.normalizeDate(c.LastModifiedDate);
                camp.LastModifiedDate = format(date, 'M/dd/yy h:mm a OOOO');
              }
            }
          });
        });
      }

      if (!campaigns) return [];
      return utils.sortByMostRecentDate(campaigns, 'StartDate');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportTableRow(campaign, headers): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const list = headers.map((h: any) => {
        switch (h.value) {
          case 'StartDate': {
            const date = campaign[h.value];
            try {
              const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
              return format(parsedDate, 'MMM d, yyyy');
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('campaign date', date, err.message || err);
            }
            return '';
          }
          case 'EndDate': {
            const date = campaign[h.value];
            if (!campaign.ContractEnd)
              try {
                const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
                return format(parsedDate, 'MMM d, yyyy');
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log('campaign date', date, err.message || err);
              }
            return '';
          }
          case 'Tactics':
            return campaign[h.value]
              .map((tactic: string) => {
                return utils.getTacticName(tactic);
              })
              .join(', ');
          case 'Completed':
            return campaign[h.value] ? 'Completed' : 'Active';
          case 'Conversions':
            return campaign[h.value] ? campaign[h.value] : 'N/A';
          case 'CVR':
            return campaign[h.value] !== '0.00%' ? campaign[h.value] : 'N/A';
          default:
            return campaign[h.value];
        }
      });
      let tab = null;
      switch (campaign.ProductTab) {
        case 'VIDEO':
          tab = 'video';
          break;
        case 'SITEIMPACT':
          tab = 'emailsi';
          break;
        default:
          tab = campaign.ProductTab?.toLowerCase();
          break;
      }
      list.push(
        `${utils.getRootDomain(this.Theme)}/home/?id=${this.exportData.advertiserId}` +
          `&tab=${tab}&viewCampaigns=${campaign.CampaignId}`,
      );
      return list;
    },
    exportTableData(): void {
      const tables = [];
      let tablesInOrder = this.config?.tables;
      if (Array.isArray(tablesInOrder)) {
        if (this.componentConfig?.tacticTableOrder) {
          tablesInOrder = this.componentConfig?.tacticTableOrder.reduce((all, tactic) => {
            const found = tablesInOrder.find(table => table.analyticsType === tactic);
            if (found) {
              all.push(found);
            }
            return all;
          }, []);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tablesInOrder.forEach((tbl: any) => {
          const tabName = tbl.tacticName;
          const analyticsType = tbl.analyticsType;
          const map = this.tableHeaders(tbl.tacticName);
          const headers = map.map(h => h.text);
          headers.push('URL');
          const tableData = this.exportTableCampaigns(tbl.campaigns);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const table = tableData.map((campaign: any) => {
            return this.exportTableRow(campaign, map);
          });
          tables.push({ tabName, analyticsType, headers, table });
        });
      }

      if (tables.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        if (this.componentConfig?._id_version) {
          const pair = this.componentConfig?._id_version.split(':');
          if (pair.length === 2) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const found = tables.find((table: any) => table.analyticsType === pair[1]);
            if (found) {
              this.$emit('rendered', {
                empty: false,
                data: found.table,
                headers: found.headers,
                config: this.componentConfig,
              });
              return;
            }
          }
        }
        this.$emit('rendered', { empty: false, tables, config: this.componentConfig });
      }
    },
  },
  computed: {
    summaryData(): object {
      return this.isExporting ? this.exportData?.summaryData : this.$store.state.customer?.summaryPerformance;
    },
    isXLS(): boolean {
      return this.isExporting && this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    exportCampaignList() {
      if (
        this.$store.state.customer.campaigns?.campaignlist &&
        Array.isArray(this.$store.state.customer.campaigns.campaignlist) &&
        this.$store.state.customer.campaigns.campaignlist.length > 0
      ) {
        return this.$store.state.customer.campaigns.campaignlist.filter(
          campaign => campaign.CampaignType.toLowerCase() === this.table.analyticsType.toLowerCase(),
        );
      }
      return [];
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
  },
  watch: {
    disabledProducts: {
      handler(): void {
        // reset table data if change in custimization
        this.setTablesData();
      },
    },
  },
});
