const mainTitle = 'Top Clients';

export const componentConfig = {
  cid: 'homePerformanceImpsByClient',
  id: 'homePerformanceImpsByClient',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'HOME.ByClientImpression',
  breakpoints: ['lg9', 'sm6'],
  name: 'ImpsByClient',
  title: mainTitle,
  __typename: 'ImpsByClient',
  exportableTableData: 'xls',
  filterable: true,
  hasBrandImage: undefined,
  hideIfNoData: undefined,
  nameKey: 'ImpsByClient',
  rootValueKey: 'HOME',
  stacked: false,
  overrides: undefined,
  feedSource: undefined,
  noDataTooltips: ['no data'],
  tooltipsTitle: [
    {
      title: mainTitle,
      message:
        'This module shows you all the Active Clients sorted by Total Impressions or by Growth. The date range selector in the upper right corner impacts this module and the “Tactics Overview” module that appear on the same row. ',
    },
  ],
};

export const componentHeight = '660px';

export const dataSource = 'HOME.ByClientImpression';
