
import Vue from 'vue';
import CampaignProgressBar from '../progressBars/progressBar.vue';
import ProgressBarValues from '../../../types/progressBarValues';
import { format, parse } from 'date-fns';
import utils from '../../../util';

export default Vue.extend({
  name: 'CampaignDetails',
  inheritAttrs: false,
  props: ['details', 'tacticTitle'],
  components: { CampaignProgressBar },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      metricFontValues: {
        color: 'white',
      },
    };
  },
  computed: {
    hasProgressBar(): boolean {
      return !!this.details.LifetimeImpressions && !!this.details.ImpressionGoal;
    },
    progressBarValues(): ProgressBarValues {
      return {
        metricValue: this.details.LifetimeImpressions,
        metricTitle: 'Impressions',
        totalValue: this.details.ImpressionGoal,
        totalTitle: 'Goal',
      };
    },
    // necessary because allTimeCampaigns.campaignlist and campaignAdPerformance.CampaignList return dates slightly differently
    formattedStartDate(): string {
      if (this.details.StartDate) {
        return this.formatDate(this.details.StartDate);
      }
      return this.formatDate(this.details.Start_date);
    },
    formattedEndDate(): string {
      if (this.details.StartDate) {
        return this.formatDate(this.details.EndDate);
      }
      return this.formatDate(this.details.End_date);
    },
  },
  methods: {
    formatDate(date: string): string {
      try {
        let parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        // check if invalid date includes milliseconds.
        if (parsedDate.toString() === 'Invalid Date') parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss.SSS a', new Date());
        // check if invalid date is short.
        if (parsedDate.toString() === 'Invalid Date') parsedDate = parse(date, 'yyyy-MM-dd', new Date());
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    tacticTitleMap(tactic: string): string {
      return utils.tacticTitleMap(tactic);
    },
  },
});
