
import Vue from 'vue';
import utils from '../../../../util';
import { Campaign } from '../../../../types/filters';

export default Vue.extend({
  inheritAttrs: false,
  name: 'campaignReportDownload',
  props: ['sectionConfig', 'componentConfig', 'isExporting', 'isExportDynamic', 'exportData', 'exportContext'],
  data: (): {} => ({}),
  computed: {
    details(): object | null {
      let details;
      if (this.isExporting) {
        if (Array.isArray(this.exportData.adData.CampaignList) && this.exportData.adData.CampaignList.length > 0) {
          details = this.exportData.adData.CampaignList[0];
        } else if (
          Array.isArray(this.exportData.advertiser.CampaignList) &&
          this.exportData.advertiser.CampaignList.length > 0
        ) {
          details = this.exportData.advertiser.CampaignList[0];
        }
      } else {
        if (this.campaignIds && this.campaignIds.length > 1) {
          // eslint-disable-next-line no-console
          console.warn(
            'TODO: how do we get combined details for multiple campaigns?. Need to deal with this in many other places',
          );
          return null;
        }
        details = utils.campaignDetailsById(this)[this.campaignIds];
      }
      if (!details) {
        return null;
      }
      return details;
    },
    campaignIds(): string {
      if (this.isExporting) {
        return this.exportData.campaignIds;
      }
      return this.$store.state.filters.selectedCampaigns?.map((c: Campaign) => c.id);
    },
    loading(): boolean {
      return utils.isWaitingOnData(this) && this.$store.state.filters.selectedCampaigns;
    },
  },
  methods: {},
});
