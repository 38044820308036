
import Vue from 'vue';

export default Vue.extend({
  name: 'EditCampaignPermissions',
  props: {
    showModal: Boolean,
    combinedOptions: Array,
    defaultSelectedOption: Object,
  },
  data: (): {
    selectedOption: any;
  } => ({
    selectedOption: null,
  }),
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.setSelectedOption();
      }
    },
    defaultSelectedOption: {
      handler(newValue) {
        this.setSelectedOption();
      },
      immediate: true,
    },
  },
  methods: {
    setSelectedOption() {
      if (this.defaultSelectedOption) {
        this.selectedOption = this.defaultSelectedOption.id;
      }
    },
    closeModal(): void {
      this.$emit('close');
    },
    savePermissions() {
      const selectedType = this.combinedOptions.find(option => option.id === this.selectedOption)?.type;
      this.$emit('save-permissions', {
        selectedOption: this.selectedOption,
        selectedType: selectedType,
      });
    },
  },
});
