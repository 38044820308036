export const componentConfig = {
  cid: 'homePerformanceFullChart',
  id: 'homePerformanceFullChart',
  dataChecks: ['HasDisplay'],
  disabled: null,
  dataSource: 'OrderDetails.FullMetric',
  breakpoints: ['lg12', 'sm12'],
  name: 'genericLineChart',
  title: 'Performance Overview',
  __typename: 'genericLineChart',
  chartColorIndex: null,
  chartType: null,
  commonTweaks: ['datebackfill'],
  dataKey: 'Day',
  exportableTableData: 'xls',
  hideEmptySeriesKeys: null,
  hideIfNoData: null,
  hideLegend: true,
  dynamicLineKeys: true,
  availableLineSeriesKeys: [],
  lineSeriesKeys: [],
  customLegend: true,
  selectedLineSeriesKey: 'all',
  hideEmptyLines: true,
  showyAxisSplitLine: null,
  total: null,
  useTacticSummaryData: null,
  xAxisIntervalCount: 1,
  xAxisMaxItems: null,
  xAxisShowAll: true,
  xAxisSplitLineHide: null,
  yAxisHide: null,
  yAxisLineHide: null,
  xAxisRotateDeg: null,
  showOneLine: null,
  chartColors: [
    '#1F69FF',
    '#432AFD',
    '#A535FB',
    '#F941F6',
    '#F84CA7',
    '#F65664',
    '#F59561',
    '#F4D96B',
    '#D4F376',
    '#A2F280',
    '#89F299',
    '#93F2CC',
    '#9DEDF2',
    '#A6CCF2',
    '#AFB2F2',
    '#CFB8F2',
    '#ECC1F3',
    '#F4C9E7',
    '#F5D1DA',
    '#F6DFDA',
    '#F8F0E2',
    '#F8F9E9',
    '#F6FBF1',
    '#F8FDF8',
    '#9E9E9E',
    '#C2BDBD',
  ],
  sideTotalMetrics: null,
  tooltips: null,
  overrides: null,
  hideTitle: null,
  noDataTooltips: ['no data'],
  dateRange: 'thisweek',
  fetchData: [{ name: 'Daily' }, { name: 'Weekly' }, { name: 'Monthly' }],
  customPercentageLabel: true,
};

export const componentHeight = '410px';
