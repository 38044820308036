
import Vue from 'vue';
import breakpoints from '../editorOptions/breakpoints.vue';
import chartTitle from '../editorOptions/title.vue';
import notifications from '../editorOptions/notifications.vue';
import tableProgressBar from '../editorOptions/tableProgressBar.vue';
import itemLimit from '../editorOptions/itemLimit.vue';
import publishers from '../editorOptions/publishers.vue';
import progressBarColor from '../editorOptions/progressBarColor.vue';
import publisherSorting from '../editorOptions/publisherSorting.vue';

export default Vue.extend({
  components: {
    breakpoints,
    chartTitle,
    notifications,
    tableProgressBar,
    itemLimit,
    publishers,
    progressBarColor,
    publisherSorting
  },
  props: ['componentData'],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      this.$emit('setOverride', key, value);
    },
  },
  computed: {
    hasAvailableBars(): boolean {
      return this.componentData?.availableBars?.length;
    },
    showPublisherSelection(): boolean {
      return [
        'ottPerformanceByPublisherProgressBarTable',
        'digitalvideoPerformanceByPublisherProgressBarTable',
      ].includes(this.componentData.id);
    },
    progressBarColor(): string {
      return this.componentData.progressBarColor;
    },
  },
});
