
import Vue from 'vue';
import TacticTable from './tactic-table.vue';
import { tacticIcons } from '../../utils';
import { CurrentSection } from '../../../../../types/layout';
import utils from '../../../../../util';

export default Vue.extend({
  name: 'tabs',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
  ],
  components: { TacticTable },
  data: (): {
    search: string;
    tab: string | null;
    showSearch: boolean;
  } => ({
    search: '',
    tab: null,
    showSearch: false,
  }),
  mounted(): void {
    if (this.isExporting) {
      this.$emit('start-export-render');
    }
  },
  methods: {
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    getIcon(name: string): string | null {
      if (!name) return null;
      return tacticIcons[utils.removeWhiteSpaceLowerCase(name)];
    },
    closeSearch(): void {
      this.showSearch = false;
      this.search = '';
    },
  },
});
