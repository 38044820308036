
import Vue from 'vue';
import breakpoints from '../editorOptions/breakpoints.vue';
import chartTitle from '../editorOptions/title.vue';
import notifications from '../editorOptions/notifications.vue';
import lineSeriesKeys from '../editorOptions/lineSeriesKeys.vue';
import lineDisplayOptions from '../editorOptions/lineDisplayOptions.vue';
import chartColors from '../editorOptions/chartColors.vue';

export default Vue.extend({
  components: {
    breakpoints,
    chartTitle,
    notifications,
    lineSeriesKeys,
    lineDisplayOptions,
    chartColors,
  },
  props: ['componentData'],
  computed: {
    isMultiLine(): boolean {
      return this.componentData.lineSeriesKeys?.length > 1;
    },
    colorCount(): number {
      return this.componentData.lineSeriesKeys?.length;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      this.$emit('setOverride', key, value);
    },
  },
});
