
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  props: ['breakpoints'],
  data: () => ({
    selection: '',
    breakpointOptions: null,
  }),
  created(): void {
    this.breakpointOptions = utils.breakpointOptions;
    const currSelection = this.breakpoints.includes('lg12') ? 'Full' : 'Half';
    this.selection = this.breakpointOptions.find(bp => bp.text === currSelection);
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'breakpoints', this.selection);
    },
  },
});
