
import Vue from 'vue';
import utils from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'sectionHeader',
  components: { EditModuleBtn },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'chartType',
    'dataSource',
    'isExporting',
    'exportData',
    'componentHeight',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
  } => ({
    chartInstance: undefined,
  }),
  watch: {
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  mounted() {
    if (this.inEditMode) {
      this.$emit('set-display', true);
    } else {
      this.$emit('set-display', !this.isHiddenOnAdvLevel);
    }
    setTimeout(() => {
      if (this.hasEnoughData) {
        this.$emit('rendered', { empty: false });
      } else {
        this.$emit('rendered', { empty: true });
      }
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    isHiddenOnAdvLevel(): boolean {
      return this.componentConfig.hideFromUI;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    calculatedcomponentHeight(): string {
      if (this.inEditMode) return '270px';
      if (this.isExporting) return this.componentHeight || 'auto';
      return '';
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      }
      return this.chartData;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      const data = utils.adDataForKey(this, this.dataSource);
      if (data?.HHImpressions) return true;
      return false;
    },
    breakpoints(): boolean {
      return this.componentConfig.breakpoints;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
  },
});
