
import Vue from 'vue';
import { componentConfig, componentHeight } from './utils/configLineChart';
import { updateStorage, checkDataValidity, splitData, getRangeActualText, getRangePreviousText } from './utils/utils';
import { EnhancedDateRange, ModuleName, Range } from './types/types';
import analytics from '../../../../mixins/analytics';
import { defaultMetrics } from './utils/constants';

export default Vue.extend({
  name: 'FullMetrics',
  data: (): {
    metrics: object[];
    loading: Record<string, boolean>;
    dateRange: string | null;
    componentHeight: string;
    localStorageName: string;
    loadingFromLs: boolean;
    withTracking: boolean;
    defaultMetrics: Record<string, string>[];
  } => ({
    dateRange: null,
    metrics: defaultMetrics,
    loading: {
      Clients: false,
      Orders: false,
      Campaigns: false,
      Impressions: false,
    },
    componentHeight,
    localStorageName: 'metric_full_primary',
    loadingFromLs: true,
    withTracking: true,
    defaultMetrics,
  }),
  mixins: [analytics] as any,
  components: {
    MetricsHeader: () => import('./metricsHeader.vue'),
    LineChart: () => import('../../../components/charts/line/lineChartFull.vue'),
  },
  created() {
    this.setDefaultDateRange();
    this.getData();
  },
  computed: {
    metricsNames(): string[] {
      return this.componentConfig.fetchData.map(item => item.name);
    },
    isAnyLoading() {
      return Object.values(this.loading).some(status => status);
    },
    rangeActualText(): string | null {
      return getRangeActualText(this.dateRange, ModuleName.FULLMETRICS);
    },
    rangeLegendText(): string | null {
      return getRangeActualText(this.dateRange, ModuleName.FULLMETRICS_LEGEND);
    },
    rangePreviousText(): string | null {
      return getRangePreviousText(this.dateRange);
    },
    componentConfig() {
      const showLegend = !!this.rangeLegendText && !!this.rangePreviousText;
      return {
        ...componentConfig,
        legendKeys: [this.rangeLegendText, this.rangePreviousText],
        lineSeriesKeys: [this.rangeActualText, this.rangePreviousText],
        hideLegend: !showLegend,
      };
    },
  },
  methods: {
    async getData() {
      const storedData = localStorage.getItem(this.localStorageName);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const { isDataValid, isRightUser, isRightAgency } = checkDataValidity(
          parsedData,
          this.$store.state.customer.user.email,
          this.$store.state.customer.user.Agency,
        );
        this.metrics = parsedData.data;

        if (!isRightUser || !isRightAgency) {
          localStorage.removeItem(this.localStorageName);
          await this.updateFetchDataFromConfig(true);
        } else if (!isDataValid) {
          await this.updateFetchDataFromConfig(false);
        }
      } else {
        await this.updateFetchDataFromConfig(true);
      }
    },
    async updateFetchDataFromConfig(withLoading: boolean) {
      await this.fetchDataFromConfig(withLoading);
      updateStorage(
        this.metrics,
        this.localStorageName,
        this.$store.state.customer.user.email,
        this.$store.state.customer.user.Agency,
      );
    },
    clear() {
      this.metrics = this.defaultMetrics;
    },
    setDefaultDateRange() {
      this.dateRange = this.componentConfig?.dateRange || Range.THISWEEK;
    },
    async fetchDataFromConfig(withLoading: boolean) {
      const promises = this.componentConfig.fetchData.map(item => this.fetchDataItem(item, withLoading));
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDataItem(item, withLoading) {
      const { method, title, name } = item;
      Vue.set(this.loading, name, withLoading);
      this.loadingFromLs = !this.loading[name];
      try {
        const metric = await this.fetchFunction(method, { daterange: this.dateRange });
        const metricSplitted = splitData(metric?.data, this.dateRange);

        const metricObject = {
          title,
          percentage: metric?.total?.percentage,
          total: metric?.total?.activeTotal,
          chartData: metricSplitted,
          name,
        };
        const foundedIndex = this.metrics?.findIndex(item => item.name.includes(name));
        if (foundedIndex !== -1 && this.metrics?.length) {
          this.metrics.splice(foundedIndex, 1, metricObject);
        } else {
          this.metrics.push(metricObject);
        }
      } catch (error) {
        console.error(`Error fetching data for ${name}:`, error);
      } finally {
        Vue.set(this.loading, name, false);
      }
    },

    selectRange(range: EnhancedDateRange): void {
      if (this.isAnyLoading) {
        return;
      }
      this.clear();
      this.dateRange = range?.RangeKey;
      this.fetchDataFromConfig(true);
    },
    async fetchFunction(method, payload) {
      try {
        const response = await this.$store.dispatch('primary/' + method, payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
    trackSelectedMetric(name: string): void {
      this.analyticTrack(this.trackValue.POST_LOGIN, `${this.trackValue.PERFORMANCE_OVERVIEW_CHANGE_GRAPH}: ${name}`);
    },
  },
});
