
import Vue from 'vue';
import { SortType } from './types/types';
import Tooltips from '../../charts/tooltip/chartTooltip.vue';
import DateSelection from './dateSelection.vue';
import SortSelection from './sortSelection.vue';

export default Vue.extend({
  name: 'MetricHeader',
  props: ['title', 'subtitle', 'loading', 'showSortingButtons', 'tooltipsTitle', 'tooltipSubtitle'],
  components: {
    DateSelection,
    Tooltips,
    SortSelection,
  },
  methods: {
    emitSortType(sortType: SortType): void {
      this.$emit('sort', sortType);
    },
    selectRange(range): void {
      this.$emit('select-range', range);
    },
  },
});
