export interface CampaignSpendingOption {
  id: string;
  model: null | string;
  rules: Array<Function>;
  max?: string;
  suffix: string;
  prefix?: string;
  title?: string;
  textRule: string;
  customWarning: string;
  tooltip: string;
}

const rules = {
  currencyRule: v => {
    // This makes sure a USD currency value with no decimals (max 9 numbers) or two decimals are set.
    const pattern = /^[+-]?[0-9]{1,9}(?:\.[0-9]{2})?$/;
    return pattern.test(v);
  },
  numberRule: v => {
    // This makes sure it's a number between 1 and 100.
    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
    return 'Must be a value of 0 to 100';
  },
};

const options: Array<CampaignSpendingOption> = [
  {
    id: 'Hide',
    model: null,
    rules: [],
    textRule: 'disabled',
    customWarning: '',
    suffix: '',
    tooltip: '',
  },
  {
    id: 'Markup',
    rules: [rules.numberRule],
    max: '100',
    suffix: '%',
    model: null,
    textRule: 'markup {{rate}}%',
    customWarning: 'Markup must be a value of 0 to 100',
    tooltip: `Markup is profit in relation to the cost, shown as a percentage: <br /><br /> Markup % = Profit / Cost * 100 <br /><br />
              Together, the cost of delivering a campaign plus the markup equals the "Spend $" amount. A standard
              split is 60/40 (cost/profit). Therefore, the default Markup value is 66.67%.`,
  },
  {
    id: 'CPM',
    rules: [rules.currencyRule],
    prefix: '$',
    suffix: 'CPM',
    model: null,
    textRule: '${{rate}} CPM',
    customWarning: 'CPM must be a valid currency value',
    tooltip: `If the campaign is CPM-based, then enter the CPM based on the order. The “Spend $” metric is shown in “Campaign Summary” and represents the number of “impressions served” times the “CPM value entered”.`,
  },
  {
    id: 'CPC',
    rules: [rules.currencyRule],
    prefix: '$',
    suffix: 'CPC',
    model: null,
    textRule: '${{rate}} CPC',
    customWarning: 'CPC must be a valid currency value',
    tooltip: `If the campaign is CPC-based, then enter the CPC based on the order. The “Spend $” metric is shown in “Campaign Summary” and represents the number of “clicks” times the “CPC value entered”.`,
  },
  {
    id: 'CPD',
    rules: [rules.currencyRule],
    prefix: '$',
    suffix: 'CPD',
    model: null,
    textRule: '${{rate}} CPD',
    customWarning: 'CPD must be a valid currency value',
    tooltip: `Cost Per Deployment (CPD) is the total fixed cost for this one deployment, email broadcast or campaign.`,
  },
];

export { options };
