
import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';

export default Vue.extend({
  inheritAttrs: false,
  props: ['metric', 'color', 'index', 'cardsLength', 'devTooltipData', 'halfWidth', 'sectionConfig', 'componentConfig'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      colors,
      icons: {
        default: 'check_circle',
        'Total OTT Impressions': 'visibility',
        'Total Impressions': 'visibility',
        'Top Creative by Completion Rate': 'equalizer',
        'OTT 100% Completed': 'timelapse',
        'Linear Airings': 'live_tv',
        'Linear Web Visits': 'web_asset',
        'Total Viewing Hours': 'play_circle_outline',
        'Total Views (100% completed)': 'equalizer',
        'Top Hour by Impression Count': 'schedule',
        'Top Hour by 100% Completion Rate': 'timelapse',
        'Top Daypart by Impressions': 'brightness_5',
        'Top Day of Week by Impression Count': 'calendar_today',
        'Top Day of Week by 100% Completion Rate': 'timelapse',
        'Top Month by Impression Count': 'calendar_today',
        'Top Month by 100% Completion Rate': 'timelapse',
        'Top Device by Impression Count': 'devices',
        'Top Device Type by Impression Count': 'important_devices',
        'Top Publisher': 'trending_up',
        'Top Hour': 'schedule',
        'Top Hour Impressions': 'visibility',
        'Total TV Visits': 'tv',
        'Total Aired Spots': 'live_tv',
        'Average Spot Rate': 'timeline',
        'Total Impression': 'visibility',
        'Total Viewing Hour': 'timer',
        'Top Hour by Visits per airing': 'schedule',
        'Average Web Visits Per Airing': 'vertical_align_center',
        'Total Ad Spots Aired': 'live_tv',
        'Top Web Visits': 'web_asset',
        'Total Web Visits': 'timelapse',
        'Top Month by Visits/Airing': 'calendar_today',
        'Top Day of Week by Visits Per Airing': 'calendar_today',
        'Top Creative': 'stars',
        'Top Creative Visits': 'web_asset',
        'Top Visits': 'touch_app',
        'Top Station by Visits Per Airing': 'stars',
        'Average Web Visits per Airing': 'vertical_align_center',
        'Top Station by Number of Web Visits': 'touch_app',
        'Total Ad Spot Aired': 'live_tv',
        'by Number of Web Visits': 'equalizer',
        'Top Program by Visits per Airing': 'tv',
        'Top Program by Number of Web Visits': 'devices',
        Airings: 'live_tv',
        'Total Aired': 'live_tv',
        'Web Visits': 'web_asset',
        'Total Visits': 'web_asset',
        'View Hours': 'schedule',
        Hours: 'schedule',
        'Completion Rate': 'timelapse',
        'Click-through Rate': 'fa-mouse-pointer',
        Impressions: 'visibility',
        Clicks: 'touch_app',
        'Verified Completion Rate': 'timelapse',
        'Top Location': 'location_on',
        'Top Device': 'phone_android',
        'Top Age Group': 'people_outline',
        'Conversion Rate': 'timelapse',
        'Total Reach': 'people',
        'Total Frequency': 'show_chart',
        'Total Spend': 'payments',
        Conversions: 'fa-filter',
        'Impression Pacing': 'show_chart',
        Pacing: 'directions_run',
        'Google Changes': 'fa-google',
        'Google Rankings': 'insert_chart',
        'Mobile Changes': 'phone_iphone',
        'Mobile Rankings': 'insert_chart_outlined',
        'Search Volumns': 'search',
      },
    };
  },
  computed: {
    iconLabel(): string {
      return this.icons[this.metric.title] ? this.icons[this.metric.title] : this.icons.default;
    },
    subheadingColor(): string {
      return colors.grey.darken2;
    },
    showDevTooltip(): boolean {
      return !!this.devTooltipData;
    },
  },
});
