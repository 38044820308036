
import Vue from 'vue';
import SummaryMetricsController from '../summary/controllers/summaryMetricsController.vue';
import { renderComponent } from '../summary/utils';
import { CurrentSection } from '../../../types/layout';
import gradstop from 'gradstop';
import utils from '../../../util';
import MapComponent from '@point/map-component';
import ERRORS from '../../../errors';

let delayingMap = null;

export default Vue.extend({
  name: 'mapWrapper',
  inheritAttrs: false,
  components: { SummaryMetricsController, MapComponent },
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
    'hasRequiredData',
    'hasMapData',
    'isShared',
  ],
  mounted(): void {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'getProductsForListOnSummary' });
    this.$emit('set-map-height');
    setTimeout(this.start, 1000);
  },
  data: (): {
    mapReady: boolean;
    fullyRendered: number;
    hoveredGeo: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    dmaMarketsHash: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    campaignDetails: object | null;
    renderDots: boolean;
    tooltipTitle: string | null;
    tooltipContent: string | null;
    exportBackgroundImage: string | null;
    mapId: string | null;
    hasPolygons: boolean;
    polygonData: object;
    maxPolygonLimit: number;
    renderMap: boolean;
    initialRender: boolean;
  } => ({
    mapReady: false,
    campaignDetails: null,
    fullyRendered: 0,
    renderDots: true,
    tooltipTitle: null,
    tooltipContent: null,
    exportBackgroundImage: null,
    mapId: null,
    hoveredGeo: null,
    dmaMarketsHash: {},
    hasPolygons: false,
    polygonData: {},
    maxPolygonLimit: 1000,
    renderMap: true,
    initialRender: true,
  }),
  methods: {
    async initData() {
      this.emitRendered();
      const products = await this.fetchProducts();
      const filtered_products = products.filter(product => !this.disabledProducts?.includes(product));

      this.$store
        .dispatch('getAdvertiserGeoSummary', {
          products: filtered_products,
          isShared: this.isShared,
          query: this.$route.query,
        })
        .then(response => {
          const data = this.concatGeoSummaryData(response);

          this.setGeoSummaryData(data);
          this.getPolygons();
          this.renderMapLayers({ clear: true });
        });
    },
    async fetchProducts() {
      const productsResponse = await this.$store.dispatch('getProductsForListOnSummary', {
        advertiserId: this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId,
        daterange: this.$route.query?.summarydaterange || 'alltime',
        isShared: this.isShared,
      });
      this.$store.dispatch('setFetching', {
        cid: this.componentConfig.cid,
        url: 'getProductsForListOnSummary',
        completed: true,
      });
      const productsList = productsResponse?.CampTypes;
      return productsList;
    },
    concatGeoSummaryData(data): Array<any> {
      let arr = [];
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          let geoListArray = data[key].GeoList;
          arr = arr.concat(geoListArray);
        }
      }

      return arr;
    },
    start(): void {
      this.initData();
      setTimeout(() => {
        this.campaignDetails = utils.adDataForKey(this, this.componentConfig.detailsSource);
      }, 10);
      setTimeout(() => {
        if (!this.hasRequiredData) {
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGeoSummaryData(geos = []): void {
      this.polygonData = {};

      const RSNOuterMarkets = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      geos.forEach((geo: any) => {
        if (!geo) return;

        let key, val;
        if (geo.GeoParamKey && geo.GeoParamVal) {
          key = `${geo.GeoParamKey}-${geo.GeoParamVal}`;
          val = geo;
        } else if (geo.Zipcode) {
          key = `zips-${geo.Zipcode}`;
          val = { GeoParamKey: 'zips', GeoParamVal: geo.Zipcode, ...geo };
        } else if (geo.DMAName) {
          key = `dmacodes-${geo.DMA}`;
          val = { GeoParamKey: 'dmacodes', GeoParamVal: geo.DMA, name: geo.DMAName, ...geo };
        } else {
          // eslint-disable-next-line no-console
          console.log('geo without Zipcode or GeoParamKey/GeoParamVal', geo);
          return;
        }

        if (val.name === 'Other' || val?.Type?.toLowerCase() === 'unknown') return;

        if (!this.polygonData[key]) {
          this.polygonData[key] = {
            key: val.GeoParamKey,
            id: val.GeoParamVal,
            name: val.name,
            uid: key,
            score: 0,
            geos: [],
            teams: [],
            legacy: [],
            table: { headers: [], data: [] },
            outerMarket: null,
          };
        }

        this.polygonData[key].geos.push(val);

        if (geo.Lat) this.polygonData[key].lat = parseFloat(geo.Lat);
        if (geo.Long) this.polygonData[key].lon = parseFloat(geo.Long);
        if (geo.Impressions && typeof geo.Impressions === 'number') this.polygonData[key].score += geo.Impressions;

        if (geo.GeoParamKey === 'dmacodes') {
          if (geo.League || geo.Team || geo.RSN) {
            const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
            const outerMarket = RSNOuterMarkets[marketKey];

            if (outerMarket) this.polygonData[key].outerMarket = outerMarket;

            this.polygonData[key].teams.push(geo);
          } else {
            this.polygonData[key].legacy.push(geo);
          }
        }
      });
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.onMapMovedTimer = setTimeout(() => {
        const map = this.$refs[this.mapCacheKey]?.Get();

        if (!map || !map.host || !map.leaflet) return;

        const zoomLevel = map.GetZoom();
        const newVal = !(zoomLevel && zoomLevel > 9);
        if (newVal !== this.renderDots) {
          this.renderDots = newVal;
          this.renderMapLayers({ clear: true });
        }
      }, 500);
    },
    renderMapLayers(
      { clear, redrawing, doneRendering, beforePolygons } = {
        clear: false,
        redrawing: false,
        doneRendering: false,
        beforePolygons: false,
      },
    ): void {
      const dmaGradient = this.Theme?.polygons?.light?.dmaGradient;
      if (!this.mapReady || !dmaGradient) {
        if (delayingMap) clearTimeout(delayingMap);

        delayingMap = setTimeout(() => {
          delayingMap = null;
          this.renderMapLayers({ clear, redrawing, doneRendering, beforePolygons });
        }, 250);
        return;
      }

      if (delayingMap) return;

      if (doneRendering && this.fullyRendered === 0) this.fullyRendered = 1;

      let gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      if (clear) map.ClearMap();

      let hasData = false;
      let useDots = this.renderDots;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let polygons: any[] = Object.values(this.polygonData);

      polygons = this.setScoreAndSort(polygons, useDots);

      this.maxPolygonLimit = this.isExporting && !this.isPrinting ? 100 : this.maxPolygonLimit;
      // Slice the polygon list if it exceeds the max limit and adjust the legend accordingly
      if (polygons.length > this.maxPolygonLimit) {
        polygons = polygons.slice(-1 * this.maxPolygonLimit);
        if (this.componentConfig.legend) {
          this.legend = this.componentConfig.legend + ` (top ${polygons.length})`;
        }
      } else if (this.componentConfig.legend) {
        this.legend = this.componentConfig.legend;
      }

      // Edge case layer only used for Sinclair rsn, optimum sports
      const outerMarketsLayer = map.CreateLayer('outerMarket');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.outerMarket?.poly?.WKT_P100) {
          let hovered, layerPopup;
          const om = p.outerMarket;
          const layer = map.AddToLayer(outerMarketsLayer);
          map.SetWKT(layer, p.outerMarket.poly?.WKT_P100, true);
          layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
          this.dmaMarketsHash[om.GeoKey] = layer;
          layer
            .on('mouseover', () => {
              this.hoveredGeo = om;
              if (!layer) {
                return;
              }
              hovered = true;
              layer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
              const lat = parseFloat(p.lat);
              const lon = parseFloat(p.lon);
              let latlon;
              if (!isNaN(lat) && !isNaN(lon)) {
                latlon = [lat, lon];
              }
              if (!latlon) {
                return;
              }
              this.tooltipTitle = `Outer Market: ${om.name} ${om.League} ${om.Team}`;
              this.tooltipContent = `
                  <div class="tooltipContent">
                    <div class="tooltipContentLine">
                      <span class="grey--text font-weight-medium">Impressions:
                        <span class="primary--text font-weight-bold ma-0">
                        ${utils.formatNumberWithCommas(om.Impressions)}
                        </span>
                      </span>
                    </div>
                  </div>`;
              p.tooltipTimeout = setTimeout(() => {
                if (!hovered) {
                  return;
                }
                const content = this.$refs.mapToolTip;
                if (!content) {
                  layerPopup = null;
                  return;
                }
                layerPopup = map.CreatePopup(content, latlon, {
                  closeButton: false,
                  offset: [2, 4],
                });
              }, 10);
            })
            .on('mouseout', () => {
              if (!layer) {
                return;
              }
              hovered = false;
              setTimeout(() => {
                if (hovered) {
                  return;
                }
                clearTimeout(p.tooltipTimeout);
                layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }, 50);
            });
        }
      });

      const geosLayer = map.CreateLayer('geos');

      let iconClass = 'fenceIcon';

      // If no polygons yet, render one transparent element in the center of US to get map preview
      if (!polygons.length && this.initialRender) {
        this.initialRender = false;
        ({ gradient, iconClass, polygons } = this.mapPreview);
        map.SetZoom(0);
      }

      polygons.forEach((p: any) => {
        if (!p.geos || !Array.isArray(p.geos) || p.geos.length === 0) {
          // eslint-disable-next-line no-console
          console.log('zipPoly no data', p);
          return;
        }
        let layer, style, hoverStyle, color, persistentTooltip;
        if (p.useDot || !p.poly?.WKT_P100) {
          if (isNaN(p.lat) || isNaN(p.lon) || p.lat === 0 || p.lon === 0) {
            // console.log('geoData no lat/lon', p);
            return;
          }
          layer = map.AddDivToLayer({ className: iconClass, zIndexOffset: p.score }, p.lat, p.lon, geosLayer);
          color = gradient[p.score100];
          if (layer._icon) {
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                layer._icon.style.background = color;
              }
            }, 200);
          }
          hasData = true;
        } else {
          layer = map.AddToLayer(geosLayer);
          map.SetWKT(layer, p.poly?.WKT_P100, true);

          color = gradient[p.score100];
          const color1 = gradient[1];
          const color50 = gradient[50];
          const color99 = gradient[99];

          style = {
            ...this.Theme?.polygons?.light?.dma,
            color: color50,
            fillColor: color,
            weight: 1,
            fillOpacity: 0.3,
          };

          hoverStyle = {
            ...this.Theme?.polygons?.light?.dmaHover,
            color: color1,
            fillColor: color99,
            weight: 5,
            fillOpacity: 0.7,
          };
          layer.setStyle(style);
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = p;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }

            let latlon = e.latlng;
            if (p.useDot) {
              // use the dot's center
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
            }
            hovered = true;
            this.tooltipTitle = null;
            this.tooltipContent = null;
            if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }
            if (Array.isArray(p.teams) && p.teams.length > 0) {
              persistentTooltip = true;
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
              p.table.headers = [
                { text: 'Team', value: 'Team' },
                { text: 'League', value: 'League' },
                { text: 'Imps', value: 'Impressions' },
              ];
              p.table.data = p.teams.map(x => ({
                key: x.GeoKey,
                Team: x.Team,
                League: x.League,
                Impressions: utils.formatNumberWithCommas(x.Impressions),
              }));
              if (Array.isArray(p.legacy) && p.legacy.length > 0) {
                const impressions = p.legacy.reduce((sum, geo) => sum + geo.Impressions, 0);
                p.table.data.push({
                  key: 'legacy',
                  Team: 'n/a',
                  League: 'n/a',
                  Impressions: utils.formatNumberWithCommas(impressions),
                });
              }
            } else {
              const first = p.geos[0];
              switch (p.key) {
                case 'zips':
                  this.tooltipTitle = `Zip Code: ${p.id}`;
                  break;
                case 'dmacodes':
                  this.tooltipTitle = `DMA: ${first.name}`;
                  break;
                case 'states':
                  this.tooltipTitle = `${first.name}`;
                  break;
                case 'cities':
                  this.tooltipTitle = `${first.name}`;
                  break;
                default:
                  this.tooltipTitle = `${p.id}`;
                  // eslint-disable-next-line no-console
                  console.error('unhandled polygon type', p);
                  break;
              }
              this.tooltipContent = `<div class="tooltipContent">`;

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p.geos.forEach((x: any) => {
                let key, val;
                switch (x.AnalyticsType) {
                  case 'PREROLL':
                    key = 'Video';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'OTT':
                    key = 'CTV';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'BROADSTREET':
                    key = 'Broadstreet - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'DISPLAY':
                    key = 'Display';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'SOCIAL':
                    key = 'Facebook Ads';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'VIDEO':
                    key = 'Digital Video';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'LINEAR':
                    key = 'Sinclair RSN';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'GAMVIDEO':
                    key = 'Video - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'GAMDISPLAY':
                    key = 'Display - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log('unhandled polygon data type', p, x);
                    key = 'Impressions';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                }
                this.tooltipContent += `<div class="tooltipContentLine"><span class="grey--text font-weight-medium">${key}: <span class="primary--text font-weight-bold ma-0">${val}</span></span></div>`;
              });
              this.tooltipContent += '</div>';
            }
            p.tooltipTimeout = setTimeout(() => {
              if (!hovered) {
                return;
              }
              const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
              if (!content) {
                layerPopup = null;
                return;
              }
              layerPopup = map.CreatePopup(content, latlon, {
                closeButton: false,
                offset: [2, 4],
              });
              const popupEl = layerPopup._wrapper;
              popupEl.style.pointerEvents = 'none';
              if (persistentTooltip) {
                popupEl.style.pointerEvents = 'all';
                // console.log({ popupEl, layerPopup });
                popupEl.addEventListener(
                  'mousemove',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseover',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseout',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = false;
                    setTimeout(() => {
                      if (!hovered) {
                        map.ClosePopup(layerPopup);
                        layerPopup = null;
                      }
                    }, 250);
                  },
                  true,
                );
              }
            }, 20);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (!persistentTooltip && !p.useDot && layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('click', () => {
            // // eslint-disable-next-line no-console
            // console.log('polygon', p);
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            setTimeout(() => {
              if (hovered) {
                return;
              }
              clearTimeout(p.tooltipTimeout);
              if (layer.setStyle) {
                layer.setStyle(style);
              }
              this.legend = null;
              if (!persistentTooltip && layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            }, 50);
          });
        hasData = true;
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);

      const adjustMapPosition = map.initialized && clear && hasData;

      if (adjustMapPosition) {
        const paddingOptions = { paddingTopLeft: [400, 0], paddingBottomRight: [0, 300] };
        setTimeout(() => {
          map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
        }, 10);
      } else {
        map.FitAllLayers({ maxZoom: 0, animate: true });
      }

      this.emitRendered();
    },
    setScoreAndSort(arr, useDots) {
      // create shallow copy to avoid mutation
      let polygons = [...arr];

      let maxScore = 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.score > maxScore) {
          maxScore = p.score;
        }
      });

      // sort polygons by their zIndex, to render them in the right order
      let sorted = [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        // try to never show dots for states and DMAs
        // order layers: high score polygons *below* low score polygons *below* low score dots *below* high score dots
        let score = Math.floor((1000.0 * p.score) / maxScore);
        p.score1000 = score;
        p.score100 = Math.floor(score / 10.0);
        p.useDot = false;

        switch (p.key) {
          case 'states':
            score = 2000 - score;
            break;
          case 'dmacodes':
            score = 3000 - score;
            break;
          case 'cities':
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          case 'zips':
            // cities and zip share the same score offset, as they can be smaller/bigger than other cities/zips
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          default:
            score = score + 5000;
            break;
        }
        sorted.push({ score, p });
      });

      sorted.sort((a, b) => b.score - a.score);
      return sorted.map(kv => kv.p);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHovered(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];

      if (homeLayer) homeLayer.setStyle(this.Theme?.polygons?.light.rsnHover);
      if (outermarketLayer) outermarketLayer.setStyle(this.Theme?.polygons?.light.outerMarketHover);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHoveredOut(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];

      if (homeLayer) homeLayer.setStyle(this.Theme?.polygons?.light?.rsn);
      if (outermarketLayer) outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarket);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(retries = 5) {
      const map = this.$refs[this.mapCacheKey]?.Get();

      if (!map) return;

      if (this.fullyRendered === 1) {
        if (this.isExporting) {
          setTimeout(() => {
            const mapEl = this.$refs[this.mapCacheKey]?.$el;
            if (!mapEl) {
              // eslint-disable-next-line no-console
              console.error('can not export, no map element');
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  this.$emit('rendered', { empty: true });
                }, 10);
              }
              return;
            }
            const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
            if (!tileBackgroundList || tileBackgroundList.length === 0) {
              // eslint-disable-next-line no-console
              console.error('SummaryMap, can not export, no map background');
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  this.$emit('rendered', { empty: true });
                }, 10);
              }
              return;
            }
            const tileBackground = tileBackgroundList[0] as HTMLElement;

            const zoom = map.GetZoom() - 1; // why do we need the -1 ???
            const center = map.GetCenter();
            const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
            var img = new Image();
            img.addEventListener(
              'load',
              () => {
                this.exportBackgroundImage = staticMapByCenter;
                tileBackground.style.display = 'none';
                setTimeout(() => {
                  this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
                  this.$emit('rendered', { empty: false });
                }, 1000);
              },
              false,
            );
            img.src = staticMapByCenter;
            this.fullyRendered = 2;
          }, 1000);
        } else {
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            this.$emit('rendered', { empty: false });
          }, 1000);
        }
      }
    },
    onMapReady(evt): void {
      this.renderMapLayers({ clear: true });

      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      if (!redrawing) map.ZoomControlsOnRight();

      this.mapReady = true;
      this.$store.dispatch('summary/setIsMapReady', true);

      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
      }
      const mapDiv = document.getElementById('summaryMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    renderComponent(cpnt: string): boolean {
      return renderComponent(cpnt, this.componentConfig.subComponent, this.isExporting && !this.isPrinting);
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
    getGeoKeys(mergedGeos): any {
      const geoKeys = { hasLatLongs: false };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mergedGeos.forEach((geo: any) => {
        if (!geoKeys[geo.key]) {
          geoKeys[geo.key] = [];
        }
        if (geoKeys[geo.key].length < 500) {
          if (!geo.id) {
            return;
          }
          if (typeof geo.id === 'string' && geo.id.length > 0) {
            if (geo.id === 'null') {
              return;
            }
          }
          geoKeys[geo.key].push(geo.id);
          if (!geoKeys.hasLatLongs && typeof geo.lat === 'number') {
            geoKeys.hasLatLongs = true;
          }
          if (geo.outerMarket) {
            if (!geoKeys[geo.outerMarket.GeoParamKey]) {
              geoKeys[geo.outerMarket.GeoParamKey] = [];
            }
            geoKeys[geo.outerMarket.GeoParamKey].push(geo.outerMarket.GeoParamVal);
          }
        }
      });
      if (Object.keys(geoKeys).length === 0) {
        // eslint-disable-next-line no-console
        console.log('no geo data', geoKeys, this.dataSource);
        return;
      }

      return geoKeys;
    },
    getPolygons(): void {
      const mergedGeos = Object.values(this.polygonData);
      if (!mergedGeos || mergedGeos.length === 0) return;

      const geoKeys = this.getGeoKeys(mergedGeos);

      this.$store
        .dispatch('getPolygons', geoKeys)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          const map = this.$refs[this.mapCacheKey]?.Get();

          if (!map) return;

          this.setPolygonData(data);

          if (!this.hasPolygons) {
            // eslint-disable-next-line no-console
            console.log('getPolygons', ERRORS.NO_POLYGONS);
            return;
          }

          this.renderMapLayers({ clear: true, doneRendering: true });
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    setPolygonData(data) {
      Object.keys(data).forEach((k: string) => {
        const poly = data[k];
        if (k === 'LocationNotFound') {
          if (!this.isExporting) {
            // eslint-disable-next-line no-console
            console.log('polygons not found', poly);
          }
          return;
        }
        if (poly?.WKT_P100) {
          this.hasPolygons = true;
          switch (poly.ITYPE) {
            case 'DMA':
              {
                const key = `dmacodes-${poly.DMA_CODE}`;
                if (!this.polygonData[key]) {
                  // eslint-disable-next-line no-console
                  console.log('received polygon data for unexpected geo', poly);
                  break;
                }
                this.polygonData[key].poly = { key: k, ...poly };
              }
              break;
            case 'ZIP':
              {
                const key = `zips-${k}`;
                if (!this.polygonData[key]) {
                  // eslint-disable-next-line no-console
                  console.log('received polygon data for unexpected geo', poly);
                  break;
                }
                this.polygonData[key].poly = { key: k, ...poly };
              }
              break;
            case 'STAT':
              {
                const key = `states-${k}`;
                if (!this.polygonData[key]) {
                  // eslint-disable-next-line no-console
                  console.log('received polygon data for unexpected geo', poly);
                  break;
                }
                this.polygonData[key].poly = { key: k, ...poly };
              }
              break;
            case 'CITY':
              {
                const key = `cities-${k}`;
                if (!this.polygonData[key]) {
                  // eslint-disable-next-line no-console
                  console.log('received polygon data for unexpected geo', poly);
                  break;
                }
                this.polygonData[key].poly = { key: k, ...poly };
              }
              break;
            case 'OUTERMARKET':
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Object.values(this.polygonData).forEach((geo: any) => {
                if (geo.outerMarket?.GeoParamVal === k) {
                  geo.outerMarket.poly = { key: k, ...poly };
                }
              });
              break;
            default:
              // eslint-disable-next-line no-console
              console.log('not handled polygon ITYPE', poly);
              break;
          }
        }
      });
    },
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    mapHeight(): number {
      if (this.isMobile) return 200;
      if (this.isPrinting) return 1100;
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    mapPreview(): object {
      return {
        gradient: ['#FFFFFF00', '#FFFFFF00', '#FFFFFF00'],
        iconClass: 'fenceIcon loading',
        polygons: [
          {
            geos: [{}],
            id: 'loading...',
            key: 'zips',
            lat: 37.26,
            lon: -106.35,
            score: 3442,
            score100: 1,
            score1000: 12,
            table: { headers: [], data: [] },
            teams: [],
            uid: 'loading-uid',
            useDot: true,
          },
        ],
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingContainerStyles(): any {
      const styles = { height: `${this.mapHeight}px`, 'margin-top': `-${this.mapHeight}px` };
      if (this.$refs.mapContainer?.clientWidth) {
        styles['width'] = `${this.$refs.mapContainer.clientWidth}px`;
      }

      return styles;
    },
    mapRenderCacheKey(): string {
      return `_summaryMap_${this.config.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_summaryMap_${this.config.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      if (Array.isArray(this.componentConfig?.themes) && this.componentConfig.themes.length) {
        // if only one theme exist
        return utils.getMapId(this.componentConfig.themes[0]);
      }
      return 'hybrid';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
    summaryHeight(): number {
      return this.$store.state?.summary?.dimensions?.summaryHeight || 0;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    hideMap(): boolean {
      return this.componentConfig?.hideSummaryMap;
    },
  },
  watch: {
    mapHeight: {
      handler(): void {
        this.renderMap = false;

        // wait until all sync code is executed and refresh map
        setTimeout(() => {
          this.renderMap = true;
        }, 0);

        if (this.mapReady && this.mapId) {
          const map = this.$refs[this.mapId]?.Get();
          if (!map || !map.host || !map.leaflet) {
            // eslint-disable-next-line no-console
            console.error('map component did not load correctly', map);
            return;
          }
          setTimeout(() => {
            map.Resize();
          }, 20);
        }
      },
    },
    disabledProducts: {
      handler(): void {
        this.$emit('set-map-height');
        this.start();
      },
    },
    summaryHeight: {
      handler(oldHeight, newHeight): void {
        const difference = Math.abs(newHeight - oldHeight);
        if (oldHeight !== newHeight && difference > 10) {
          this.$emit('set-map-height');
        }
      },
    },
  },
});
