
import Vue from 'vue';
import { CurrentSection } from '../../types/layout';

// Summary Components
import availableComponents from './availableComponents.vue';

export default Vue.extend({
  components: {
    availableComponents,
  },
  props: ['validSections', 'currentSection', 'validComponents'],
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentsList(): any {
      return this.validComponents?.components || [];
    },
  },
  methods: {
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
  },
});
