
import Vue from 'vue';
import { TopTacticsWithMetrics } from '../../types';
import utils from '../../../../../util';
import { tacticIcons } from '../../utils';

export default Vue.extend({
  inheritAttrs: false,
  props: ['isExporting', 'isExportDynamic', 'exportData', 'exportContext'],
  mounted(): void {
    setTimeout(() => {
      // needs to wait to fully render
      this.setSummaryHeight();
    }, 200);
  },
  methods: {
    getTacticTitle(id: string) {
      const tactic = this.topTacticData.find(t => t.id === id);
      const title = tactic.metrics.find(m => m.dataKey === 'TagName').value;
      return utils.tacticTitleMap(title);
    },
    setSummaryHeight(): void {
      this.$store.dispatch('summary/setSummaryHeight', this.$refs.container.clientHeight);
    },
    getIcon(name: string): string | null {
      if (!name) return null;
      return tacticIcons[utils.removeWhiteSpaceLowerCase(name)];
    },
  },
  computed: {
    mapHeight(): number {
      return this.$store.state.summary?.dimensions?.mapHeight || 0;
    },
    topTacticData(): Array<TopTacticsWithMetrics> {
      return this.$store.state?.summary?.topTactics;
    },
    hasMapData(): boolean {
      return this.$store.state?.summary?.map?.hasData;
    },
    minHeight(): object {
      const styles = {};
      if (this.isExporting) {
        styles['height'] = `${this.mapHeight}px`;
      } else if (this.hasMapData) {
        styles['min-height'] = `${this.mapHeight}px`;
      }
      return styles;
    },
  },
});
