
import Vue from 'vue';
import breakpoints from '../editorOptions/breakpoints.vue';
import chartTitle from '../editorOptions/title.vue';

export default Vue.extend({
  components: {
    breakpoints,
    chartTitle,
  },
  props: ['componentData'],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      this.$emit('setOverride', key, value);
    },
  },
});
