
import Vue from 'vue';

export default Vue.extend({
  name: 'EditTitle',
  props: ['title'],
  data: (): {
    dataTitle: string;
    valid: boolean;
  } => ({
    dataTitle: '',
    valid: true,
  }),
  created(): void {
    this.dataTitle = this.title;
  },
  watch: {
    dataTitle: 'validateField',
  },
  computed: {
    rules(): Array<string | boolean> {
      const rules = [];
      if (this.dataTitle) {
        const rule = v => !/[*|\":<>[\]{}`\\()';@&$]/g.test(v) || 'Title must be alphanumeric';
        rules.push(rule);
      }
      return rules;
    },
  },
  methods: {
    setOverride(): void {
      if (this.valid) this.$emit('setOverride', 'title', this.dataTitle);
    },
    validateField(): void {
      this.$refs.titleForm.validate();
    },
  },
});
