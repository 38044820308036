
import Vue from 'vue';
import utils from '../../../../../util';

export default Vue.extend({
  name: 'HomeLegend',
  props: ['pieColors', 'pieData'],
  methods: {
    getTooltip(item: Record<string, string>): string {
      return `${item.name} has ${this.totalCount(item.valueKeyData)} ${item.valueKey.toLowerCase()}`;
    },
    totalCount(total): string | null {
      return utils.formatNumberWithCommas(total);
    },
    legendHover(name: string): void {
      this.$emit('m-enter', name);
    },
    legendUnHover(name: string): void {
      this.$emit('m-leave', name);
    },
    color(index: number): string {
      return this.pieColors[index];
    },
    iconColor(index: number): Record<string, string> {
      return {
        'background-color': this.color(index),
      };
    },
  },
  computed: {},
});
