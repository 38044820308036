
import Vue from 'vue';
import { ImpressionsByTactic } from '../../../../store/modules/primary/types';
import {
  componentConfig,
  dataSource,
  sectionConfig,
  componentHeight,
  isExporting,
  mappingTacticsNames,
} from './utils/configPieChart';
import { updateStorage, checkDataValidity } from './utils/utils';

interface TransformedFormat {
  name: string;
  sortableValue: number;
  value: string;
  valueKey: string;
  valueKeyData: number;
}

export default Vue.extend({
  name: 'ImpressionsByTactic',
  props: ['dateRange'],
  data: (): {
    impressions: ImpressionsByTactic[];
    loading: boolean;
    componentHeight: string;
    componentConfig: any;
    dataSource: string;
    sectionConfig: any;
    isExporting: boolean;
    localStorageName: string;
  } => ({
    componentHeight: componentHeight,
    loading: false,
    impressions: [],
    componentConfig,
    dataSource,
    sectionConfig,
    isExporting,
    localStorageName: 'impressions_by_tactic_primary',
  }),
  components: {
    PieChart: () => import('../../charts/pie/pie.vue'),
  },
  watch: {
    dateRange: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.getImpressionsByTactic(true);
        }
      },
    },
    loading(newVal, oldval) {
      if (newVal !== oldval) {
        this.$emit('update:loading', newVal);
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const storedData = localStorage.getItem(this.localStorageName);

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const { isDataValid, isRightUser, isRightAgency } = checkDataValidity(
          parsedData,
          this.$store.state.customer.user.email,
          this.$store.state.customer.user.Agency,
        );
        this.impressions = parsedData.data;

        if (!isRightUser || !isRightAgency) {
          localStorage.removeItem(this.localStorageName);
          await this.updateImpressionsByTactic(true);
        } else if (!isDataValid) {
          this.impressions = parsedData.data;
          await this.updateImpressionsByTactic(false);
        }
      } else {
        await this.updateImpressionsByTactic(true);
      }
    },
    async updateImpressionsByTactic(withLoading: boolean) {
      await this.getImpressionsByTactic(withLoading);
      updateStorage(
        this.impressions,
        this.localStorageName,
        this.$store.state.customer.user.email,
        this.$store.state.customer.user.Agency,
      );
    },
    transformData(data: ImpressionsByTactic): TransformedFormat {
      return {
        name: mappingTacticsNames[data.type] || data.type,
        sortableValue: parseFloat(data.impressionsPercentage.replace('%', '')),
        value: data.impressionsPercentage.replace('%', ''),
        valueKey: 'Impressions',
        valueKeyData: data.impressions,
      };
    },
    async getImpressionsByTactic(withLoading: boolean) {
      try {
        this.loading = withLoading;
        const impressions = await this.$store.dispatch('primary/getImpressionsByTactic', { daterange: this.dateRange });
        this.impressions = impressions?.map(this.transformData);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
