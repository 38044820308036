
import Vue from 'vue';
import TacticTable from './tactic-table.vue';
import { CurrentSection } from '../../../../../types/layout';
import draggable from 'vuedraggable';
import _clone from 'lodash.clonedeep';
import utils from '../../../../../util';
import analytics from '../../../../../mixins/analytics';

export default Vue.extend({
  name: 'colorLabel',
  inheritAttrs: false,
  mixins: [analytics],
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
  ],
  components: { TacticTable, draggable },
  data: (): {
    search: string;
    dragOptions: object;
  } => ({
    search: '',
    dragOptions: {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      chosenClass: 'chosen',
      dragClass: 'drag',
    },
  }),
  methods: {
    trackMovingComponent(): void {
      this.analyticTrack(this.trackValue.EVENT_CONFIGURABILITY, this.trackValue.MOVE_MODULE);
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
  },
  computed: {
    tableList: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get(): any {
        let tablesInOrder = this.config.tables;

        if (this.componentConfig?.tacticTableOrder) {
          tablesInOrder = this.componentConfig?.tacticTableOrder.reduce((all, tactic) => {
            const found = tablesInOrder.find(table => table.analyticsType === tactic);
            if (found) {
              all.push(found);
            }
            return all;
          }, []);
        }

        return tablesInOrder;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(order: any) {
        const newTableOrder = order.map(table => table.analyticsType);

        const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
        const currCustimaztions = customizations.find(cust => cust.id === 'summaryController');

        // add new overrides or to pre-existing overrides
        let newOverrides = { tacticTableOrder: newTableOrder };
        const { overrides } =
          this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];

        if (currCustimaztions?.overrides) {
          newOverrides = { ..._clone(overrides), tacticTableOrder: newTableOrder };
        }

        // update customizations
        currCustimaztions.overrides = newOverrides;

        this.$store.dispatch('layoutEditor/setCurrCustomizations', utils.removeNullFromObjectOrArray(customizations));
        this.$store.dispatch('setOverrides', { compId: 'summaryController', overrides: newOverrides });
        this.$store.dispatch('layoutEditor/setEnableSave', true);
      },
    },
    reponsiveClass(): string {
      return this.isMobile ? 'table-actions mobile flex-column justify-center' : 'table-actions';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
  },
  mounted(): void {
    if (this.isExporting) {
      this.$emit('start-export-render');
    }
  },
});
