
import Vue from 'vue';
import { componentConfig as configImpsByClient } from './utils/configImpsByClient';
import { componentConfig as configPieChart } from './utils/configPieChart';
import { SortType, EnhancedDateRange, Range, RangeText } from './types/types';
import ImpressionsByTactic from './impressionsByTactic.vue';
import ImpressionsByClient from './impressionsByClient.vue';
import MetricsHeader from './metricsHeader.vue';

export default Vue.extend({
  name: 'Impressions',
  data: (): {
    dateRange: Range | null;
    selectedRange: RangeText | null;
    configImpsByClient: any;
    configPieChart: any;
    sorted: SortType;
    loading: boolean;
  } => ({
    dateRange: null,
    selectedRange: null,
    configImpsByClient,
    configPieChart,
    sorted: SortType.Impressions,
    loading: false,
  }),
  components: { ImpressionsByTactic, ImpressionsByClient, MetricsHeader },
  created() {
    this.setDefaultDateRange();
  },
  methods: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    emitSortType(sortedItem: SortType) {
      this.sorted = sortedItem;
    },
    setDefaultDateRange() {
      this.dateRange = Range.THISWEEK;
      this.selectedRange = RangeText.THISWEEK;
    },
    selectRange(range: EnhancedDateRange): void {
      this.dateRange = range?.RangeKey;
      this.selectedRange = range?.Text;
    },
  },
});
