
import Vue from 'vue';
import StandAloneMainPage from './_mainPage.vue';
import EditModuleBtn from '../../../charts/buttons/editModuleBtn.vue';
import FooterData from './_footerData.vue';
import NoChartData from '../../../charts/no-data/NoChartData.vue';
import util from '../../../../../util';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import Tooltips from '../../../charts/tooltip/chartTooltip.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'standAloneCampaignSummary',
  props: [
    'getCampaignDates',
    'selectedCampaigns',
    'replacementTitle',
    'analyticsType',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'tacticNames',
    'bottomMetrics',
    'hasImpressionsProgressBar',
    'hasSiteTrafficProgressBar',
    'impressionMetricFontValues',
    'impressionProgressValues',
    'isSponsoredGAM',
    'siteTrafficProgressValues',
    'sponsoredGamProgress',
    'segmentData',
    'geoData',
    'mapHeight',
    'componentConfig',
    'sectionConfig',
    'sectionId',
    'componentId',
    'hasData',
    'campaignDetails',
    'combinedMetrics',
  ],
  components: {
    StandAloneMainPage,
    EditModuleBtn,
    FooterData,
    NoChartData,
    Tooltips,
  },
  data() {
    return {
      metricOverTotal: null,
      dateRangeSelected: '',
      totalValue: null,
    };
  },
  created() {
    this.metricOverTotal = this.calcMetricOverTotal(this.impressionProgressValues);
    this.dateRangeSelected = this.calcDateRangeSelected();
  },
  methods: {
    getTooltips(key: string): Array<Tooltip> {
      return [util.getTooltipsFromMetric(key)];
    },
    capitalizeWords(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    switchDateRange(newValue) {
      this.metricOverTotal = this.calcMetricOverTotal(newValue);
    },
    calcMetricOverTotal(impressionProgressValues): string {
      let metric = this.impressionProgressValues.metricValue;
      let total = impressionProgressValues.totalValue || 0;
      this.totalValue = impressionProgressValues.totalValue;
      let percent;

      if (!impressionProgressValues.percent) {
        // calculate percent if not given
        if (typeof metric === 'string') {
          metric = parseFloat(impressionProgressValues.metricValue.replace(/[!@#$%^&*]/g, ''));
        }
        if (typeof total === 'string') {
          total = parseFloat(impressionProgressValues.totalValue.replace(/[!@#$%^&*]/g, ''));
        }

        if (total === 0) {
          percent = metric === 0 ? '0%' : '100%';
        } else {
          percent = `${((metric / total) * 100).toFixed(0)}%`;
        }
      } else {
        percent = impressionProgressValues.percent;
      }

      return percent;
    },
    calcDateRangeSelected() {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        const startDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.StartDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.StartDate);
        const endDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.EndDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.EndDate);
        return `${startDate} - ${endDate}`;
      }

      const dateRangeList = this.isExporting
        ? this.$store.state.customer.exportingData?.campaigns.Campaign[0].DateRanges
        : this.$store.state.customer.campaigns.campaignlist[0].DateRanges;
      const range = dateRangeList?.find(item => item.RangeKey === daterange);

      if (range) {
        return this.capitalizeWords(range.RangeName);
      }
      return 'All Time';
    },
    formatDateRange(inputDate) {
      const date = new Date(inputDate);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return formattedDate;
    },
  },
  computed: {
    summaryDisplayName(): string {
      return this.analyticsType?.toLowerCase() === 'broadcast' ? 'Station' : 'Campaign';
    },
    containerHeight(): { height: string } {
      const style = { height: '333px' };
      // differenciate height based on footer content
      style.height = '350px';
      return style;
    },
    showSummaryTooltip(): boolean {
      const accessList = ['gtdisplay', 'gtvideo', 'trugeofence'];

      return accessList.includes(this.componentConfig.dataSource.toLowerCase());
    },
    rangeValue() {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        return `Goal for Date Range`;
      }
      return `${this.dateRangeSelected} Goal`;
    },
    isShowImpressionGoal(): boolean {
      return this.totalValue > 100;
    },
  },
});
