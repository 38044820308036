
import Vue from 'vue';
import OrderList from './controllers/orderListController.vue';
import OrderSummary from './controllers/orderSummaryController.vue';

export default Vue.extend({
  name: 'OrderController',
  components: { OrderList, OrderSummary },
  props: ['sectionConfig', 'componentConfig', 'isExporting', 'isExportDynamic', 'exportData', 'exportContext'],
  data: (): {} => ({}),
  computed: {
    view(): string {
      return this.$route.query?.tab || 'orderlist';
    },
  },
  methods: {},
});
