
import Vue from 'vue';
import summaryMap from '../components/maps/summary-map.vue';
import SummaryMetricsController from '../controllers/summaryMetricsController.vue';
import SummaryTacticTablesController from '../controllers/summaryTacticTablesController.vue';
import { TopTacticGroupings, TopTacticsWithMetrics, TopTacticMetric } from '../types';
import { getTopTacticData, getTopTactics, topTacticGroupings, renderComponent } from '../utils';
import { CurrentSection } from '../../../../types/layout';
import util from '../../../../util';

export default Vue.extend({
  name: 'mapSummaryOverlay',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
    'mapRenderCacheKey',
    'mapCacheKey',
    'hasRequiredData',
    'mapTilerMapId',
    'hasMapData',
  ],
  // data: (): {} => ({}),
  mounted(): void {
    this.setGroupedTopTacticData();
    this.setMapHeight();
  },
  components: { summaryMap, SummaryMetricsController, SummaryTacticTablesController },
  methods: {
    getMapHeight(): number {
      // summary content is larger than map, add room.
      if (this.summaryHeight > 410 && this.componentConfig?.size?.height) {
        return Math.max(this.summaryHeight + 40, this.componentConfig.size.height);
      } else if (this.summaryHeight > 410) {
        return this.summaryHeight + 40;
      } else if (this.componentConfig?.size?.height) {
        return this.componentConfig.size.height;
      }
      return 450;
    },
    setGroupedTopTacticData(): void {
      const groupings = topTacticGroupings.reduce((all: Array<object>, grouping: TopTacticGroupings) => {
        // loop through groups of tactics and match with available summary data
        const tacticData: Array<TopTacticsWithMetrics> = grouping.tactics.reduce(
          (all: Array<TopTacticsWithMetrics>, tacticId: string) => {
            const tacticGroup = getTopTactics().find(x => x.id === tacticId);

            // dont include tactics that have been disabled
            if (tacticGroup && this.summaryData && !this.disabledProducts?.includes(tacticGroup.id)) {
              const data = getTopTacticData(this.summaryData, tacticGroup);
              if (data) all.push(data);
            }
            return all;
          },
          [],
        );

        if (!tacticData.length) return all; // exit if no tactics are available
        const metricCount = tacticData[0]?.metrics?.length;
        const headers = tacticData[0].metrics.map((metric: TopTacticMetric, index: number) => {
          // setting widths of columns if no map.
          if (this.hideMap) {
            if (metric.headerName === 'Product')
              // set Product tab to always be 25%
              return { text: metric.headerName, value: metric.dataKey, width: '25%' };
            else if (metricCount === 3 && index === 2)
              return { text: metric.headerName, value: metric.dataKey, width: '50%' };
            else if (metricCount === 2) return { text: metric.headerName, value: metric.dataKey, width: '75%' };
            else return { text: metric.headerName, value: metric.dataKey, width: '25%' };
          } else return { text: metric.headerName, value: metric.dataKey };
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tactics = tacticData.reduce((all: any[], tactic: any) => {
          const tacticObj = {
            slug: tactic.slug,
          };
          tactic.metrics.forEach(m => {
            tacticObj[m.dataKey] = m.value;
          });
          all.push(tacticObj);
          return all;
        }, []);

        // key is used for the v-for loop)
        all.push({ key: grouping.tactics.join(''), tactics, headers });
        return all;
      }, []);

      this.$store.dispatch('summary/setGroupedTopTactics', groupings);
    },
    setMapHeight(): void {
      this.$store.dispatch('summary/setMapHeight', this.getMapHeight());
    },
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
    renderComponent(cpnt: string): boolean {
      return renderComponent(cpnt, this.componentConfig.subComponent, this.isExporting);
    },
    rendered(config: object): void {
      this.$emit('rendered', config);
    },
  },
  computed: {
    summaryHeight(): number {
      return this.$store.state?.summary?.dimensions?.summaryHeight || 0;
    },
    mapHeight(): number {
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    summaryData(): object {
      return this.isExporting ? this.exportData?.summaryData : this.$store.state.customer?.summaryPerformance;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    hideMap(): boolean {
      return this.componentConfig?.hideSummaryMap;
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
  },
  watch: {
    summaryData: {
      handler(): void {
        this.setGroupedTopTacticData();
        this.setMapHeight();
      },
    },
    disabledProducts: {
      handler(): void {
        // reset table data if change in custimization
        this.setGroupedTopTacticData();
        this.setMapHeight();
      },
    },
    summaryHeight: {
      handler(oldHeight, newHeight): void {
        const difference = Math.abs(newHeight - oldHeight);
        if (oldHeight !== newHeight && difference > 10) {
          this.setMapHeight();
        }
      },
    },
  },
});
