
import Vue from 'vue';
import DropdownMenu from './dropdownMenu.vue';
import { SortType } from './types/types';

export default Vue.extend({
  components: { DropdownMenu },
  props: ['loading'],
  data(): {
    selectedSort: { value: SortType; text: string };
    sortOptions: { value: SortType; text: string }[];
  } {
    return {
      selectedSort: { value: SortType.Impressions, text: 'Impressions' },
      sortOptions: [
        { value: SortType.Impressions, text: 'Impressions' },
        { value: SortType.Increase, text: 'Growth' },
      ],
    };
  },
  created() {
    this.$emit('sort', SortType.Impressions);
  },
  computed: {
    SortType() {
      return SortType;
    },
  },
  methods: {
    handleSelected(item): void {
      if (item.value === this.selectedSort.value || this.loading) return;
      this.selectedSort = item;
      this.$emit('sort', item.value);
    },
  },
});
