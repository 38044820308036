import { Range, RangeText, EnhancedDateRange } from './types';

export const summaryDaterangeDefault: EnhancedDateRange[] = [
    { RangeName: 'This week', RangeKey: Range.THISWEEK, Text: RangeText.THISWEEK },
    { RangeName: 'This month', RangeKey: Range.THISMONTH, Text: RangeText.THISMONTH },
    { RangeName: 'This year', RangeKey: Range.THISYEAR, Text: RangeText.THISYEAR },
    { RangeName: 'All Time', RangeKey: Range.ALLTIME, Text: RangeText.ALLTIME },
    { RangeName: 'Last week', RangeKey: Range.LASTWEEK, Text: RangeText.LASTWEEK },
    { RangeName: 'Last 30 Days', RangeKey: Range.LAST30DAYS, Text: RangeText.LAST30DAYS },
    { RangeName: 'Two month ago', RangeKey: Range.TWOMONTHAGO, Text: RangeText.TWOMONTHAGO },
    { RangeName: 'Last month', RangeKey: Range.LASTMONTH, Text: RangeText.LASTMONTH },
    { RangeName: 'Yesterday', RangeKey: Range.YESTERDAY, Text: RangeText.YESTERDAY },
    { RangeName: 'Last year', RangeKey: Range.LASTYEAR, Text: RangeText.LASTYEAR },
    { RangeName: 'Custom Range', RangeKey: Range.CUSTOM, Text: RangeText.CUSTOM },
];

