
import Vue from 'vue';
import { componentConfig, dataSource, componentHeight } from './utils/configWeekdayBarChart';
import { OrderService } from '../../../services/order-summary';

const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderDetailsWeekday',
  data: (): {
    impressions: any[];
    loading: boolean;
    componentHeight: string;
    componentConfig: any;
    dataSource: string;
    daterange: string;
  } => ({
    componentHeight: componentHeight,
    loading: false,
    impressions: [],
    componentConfig,
    dataSource,
    daterange: 'thismonth',
  }),
  components: {
    barChart: () => import('../../components/charts/bar/barChart.vue'),
  },
  watch: {
    daterange: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getImpressionsByTactic(true);
        }
      },
    },
    singleOrderParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any): void {
        if (!newVal?.daterange) return;
        this.daterange = newVal.daterange;
      },
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  computed: {
    singleOrderParams(): any {
      return this.$store.state.order.singleOrderParams;
    },
  },
  methods: {
    async getData() {
      await this.updateImpressionsByTactic(true);
    },
    async updateImpressionsByTactic(withLoading: boolean) {
      await this.getImpressionsByTactic(withLoading);
    },
    async getImpressionsByTactic(withLoading: boolean) {
      try {
        this.loading = withLoading;
        const impressionsData = await orderService.getOrderImpressionsByWeekday({
          daterange: this.daterange,
          advertiserId: this.$route.query?.id || 'N/A',
          orderId: this.$route.query?.orderId || 'N/A',
        });
        this.impressions = impressionsData;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
