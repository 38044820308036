export default [
  { name: 'ABC', logo: 'abc.png' },
  { name: 'A&E', logo: 'a-e.png' },
  { name: 'AMC', logo: 'amc.png' },
  { name: 'AXS', logo: 'axs-tv.png' },
  { name: 'BBC America', logo: 'bba-america.png' },
  { name: 'BET', logo: 'bet.png' },
  { name: 'Bloomberg Television', logo: 'bloomberg-television.png' },
  { name: 'Bravo', logo: 'bravo.png' },
  { name: 'CBS', logo: 'cbs.png' },
  { name: 'Cheddar Business', logo: 'cheddar-business.png' },
  { name: 'Cheddar Big News', logo: 'cheddar-news.png' },
  { name: 'CMT', logo: 'cmt.png' },
  { name: 'CNN', logo: 'cnn.png' },
  { name: 'CNBC', logo: 'cnbc.png' },
  { name: 'Comedy Central', logo: 'comedy-central.png' },
  { name: 'Discovery Channel', logo: 'discovery.png' },
  { name: 'E!', logo: 'e.png' },
  { name: 'Epix Drive-In', logo: 'epics-drive-in.png' },
  { name: 'ESPN', logo: 'espn.png' },
  { name: 'ESPN2', logo: 'espn2.png' },
  { name: 'ESPN3', logo: 'espn3.png' },
  { name: 'Food Network', logo: 'food-network.png' },
  { name: 'Fox', logo: 'fox.png' },
  { name: 'Fox News', logo: 'fox-news-channel.png' },
  { name: 'Fox Business', logo: 'foxbusiness.png' },
  { name: 'Freeform', logo: 'freeform.png' },
  { name: 'Fuse', logo: 'fuse.png' },
  { name: 'FX', logo: 'fx.png' },
  { name: 'GSN', logo: 'game-show-network.png' },
  { name: 'HGTV', logo: 'hgtv.png' },
  { name: 'History Channel', logo: 'history.png' },
  { name: 'HLN', logo: 'hln.png' },
  { name: 'IFC', logo: 'ifc.png' },
  { name: 'Investigation Discovery', logo: 'investigation-discovery.png' },
  { name: 'Lifetime', logo: 'lifteime.png' },
  { name: 'Local Now', logo: 'local-now.png' },
  { name: 'Logo', logo: 'logo.png' },
  { name: 'Magnolia Network', logo: 'magnolia-network.png' },
  { name: 'MotorTrend Network', logo: 'motortrend.png' },
  { name: 'MSNBC', logo: 'msnbc.png' },
  { name: 'MTV', logo: 'mtv.png' },
  { name: 'MTV2', logo: 'mtv2.png' },
  { name: 'National Geographic', logo: 'national-geographic.png' },
  { name: 'Nat Geo Wild', logo: 'natgeowild.png' },
  { name: 'NBC', logo: 'nbc.png' },
  { name: 'Newsy', logo: 'newsy.png' },
  { name: 'NFL Network', logo: 'nfl-network.png' },
  { name: 'Paramount Network', logo: 'paramount-network.png' },
  { name: 'Revolt TV', logo: 'revolt.png' },
  { name: 'Science Channel', logo: 'sciencechannnel.png' },
  { name: 'Stadium', logo: 'stadium.png' },
  { name: 'Syfy', logo: 'syfy.png' },
  { name: 'TBS', logo: 'tbs.png' },
  { name: 'The Weather Channel', logo: 'the-weather-channel.png' },
  { name: 'TLC', logo: 'tlc.png' },
  { name: 'TNT', logo: 'tnt.png' },
  { name: 'Travel Channel', logo: 'travel-channel.png' },
  { name: 'truTV', logo: 'tru-tv.png' },
  { name: 'TV Land', logo: 'tv-land.png' },
  { name: 'USA Network', logo: 'usa.png' },
  { name: 'Vice TV', logo: 'vice.png' },
];
