
import Vue from 'vue';
import utils from '../../../../../../util';
export default Vue.extend({
  name: 'metricPie',
  data: (): {
    pieWidth: string;
    pieHeight: string;
  } => ({
    pieWidth: '30px',
    pieHeight: '30px',
  }),
  props: {
    firstValue: [String, Number],
    secondValue: [String, Number],
    order: Object,
  },
  computed: {
    firstOverSecond(): string {
      let metric = this.firstValue || 'N/A';
      let total = this.secondValue || 'N/A';
      let percent;

      if (this.order && this.order?.percent !== undefined && this.order?.percent) {
        percent = this.order.percent;
      } else if (this.order && this.order?.ImpressionGoalPercent !== undefined && this.order?.ImpressionGoalPercent) {
        percent = this.order.ImpressionGoalPercent;
      } else {
        // calculate percent if not given
        if (typeof metric === 'string') {
          metric = parseFloat(metric.replace(/[!@#$%^&*]/g, ''));
        }
        if (typeof total === 'string') {
          total = parseFloat(total.replace(/[!@#$%^&*]/g, ''));
        }

        if (total === 0) {
          percent = metric === 0 ? '0%' : '100%';
        } else {
          percent = `${((metric / total) * 100).toFixed(0)}%`;
        }
      }
      return percent;
    },
    primaryColor(): { color: string } {
      return { color: this.Theme.light.primary };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pieStyle(): any {
      let tempNum = parseInt(this.firstOverSecond, 10);
      if (this.firstOverSecond > 100) {
        tempNum = 100;
      }
      return {
        width: this.pieWidth,
        height: this.pieHeight,
        'background-image': 'conic-gradient(#0049B5 0% ' + tempNum + '%,	#FFFFFF ' + tempNum + '% 100%)',
        'border-radius': '50%',
        border: '#fff 2px solid',
      };
    },
    mainTitle(): string | null {
      if (this.order && this.order?.metricTitle) {
        return this.order.metricTitle;
      } else return null;
    },
    mainMetric(): string {
      const formattedFirstValue = utils.formatNumberWithCommas(this.firstValue);
      const formattedSecondValue = utils.formatNumberWithCommas(this.secondValue);
      if (formattedFirstValue && formattedSecondValue) {
        return `${formattedFirstValue} / ${formattedSecondValue}`;
      } else return 'N/A';
    },
  },
});
