
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'TableColumns',
  props: ['availableColumns', 'columns', 'hasFilter', 'title'],
  data: (): {
    selection: Array<string> | null;
  } => ({
    selection: null,
  }),
  created(): void {
    this.selection = this.columns;
  },
  computed: {
    headerTitle(): string {
      return this.title ? this.title : 'Columns';
    },
    columnOptions(): string {
      return this.availableColumns
        ?.map((col, index) => {
          return {
            text: utils.headerNamesMap(col),
            value: col,
            disabled: index === 0,
          };
        })
        .filter(option => option.value !== this.hasFilter);
    },
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'columns', this.selection);
    },
  },
});
