import { render, staticRenderFns } from "./orderDetailsCampaignList.vue?vue&type=template&id=33359c98&scoped=true"
import script from "./orderDetailsCampaignList.vue?vue&type=script&lang=ts"
export * from "./orderDetailsCampaignList.vue?vue&type=script&lang=ts"
import style0 from "./orderDetailsCampaignList.vue?vue&type=style&index=0&id=33359c98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33359c98",
  null
  
)

export default component.exports