
import Vue from 'vue';
import { RecentAdvertiser } from '../../../store/modules/customer/types';
import utils from '../../../util';
import { errorManyAdverts } from '../../../store/modules/primary/constants';
import RecentHistory from './recentHistory.vue';
import Metrics from './components/metrics.vue';
import FullMetrics from './components/fullMetric.vue';
import Impressions from './components/impressions.vue';
import EndingSoon from './components/endingSoon.vue';
import analytics from '../../../mixins/analytics';
import { LayoutSection } from '../../../types/layout';
import DropdownFilter from './components/dropdownFilter.vue';
import Pacing from './components/pacing.vue';

export default Vue.extend({
  name: 'HomeController',
  components: {
    RecentHistory,
    Metrics,
    FullMetrics,
    Impressions,
    DropdownFilter,
    EndingSoon,
    Pacing,
  },
  mixins: [analytics] as any,
  data(): {
    layout: LayoutSection | null;
    hasTracked: boolean;
    loading: boolean;
  } {
    return {
      layout: null,
      hasTracked: false,
      loading: false,
    };
  },
  created() {
    this.layout = this.$store.state?.customer?.dynamicLayout?.layoutCustomizations;

    const hasUpdatedHomeCache = localStorage.getItem('hasUpdatedHomeCache');
    if (hasUpdatedHomeCache) return;
    else {
      localStorage.removeItem('metric_full_primary');
      localStorage.removeItem('metric_primary');
      localStorage.removeItem('impressions_by_tactic_primary');
      localStorage.removeItem('impressions_by_client_primary');
      localStorage.removeItem('ending_soon_orders');
      localStorage.removeItem('pacing_orders');
      localStorage.setItem('hasUpdatedHomeCache', 'true');
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (!this.hasTracked) {
        this.analyticTrack(this.trackValue.POST_LOGIN, this.trackValue.VIEW);
        this.hasTracked = true;
      }
    });
  },
  computed: {
    isManyAdvertisersError(): boolean {
      return this.error === errorManyAdverts;
    },
    error(): string {
      return (
        this.$store.state.primary.metricsError ||
        this.$store.state.primary.impressionsByClientsError ||
        this.$store.state.primary.impressionsByTacticError ||
        this.$store.state.primary.endingOrdersError ||
        this.$store.state.primary.pacingError
      );
    },
    superUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
    userName(): string {
      return this.$store.getters.user.name || '';
    },
    showFilter(): boolean {
      return this.superUser || utils.hasRight(this.$store.state.customer.user, ['SHOW_LANDING_PAGE_FILTER']);
    },
    showLandingPageMetrics(): boolean {
      // We can`t show new metrics to SU users due to limitation of BE (User should have less than 30 advertisers )
      return (
        utils.hasRight(this.$store.state.customer.user, ['SHOW_LANDING_PAGE_METRICS']) && !this.isManyAdvertisersError
      );
    },
    recentAdvertisers(): RecentAdvertiser[] | null {
      if (!this.$store.getters.user.auth) {
        return [];
      }
      if (this.liveRecentAdvertisers && this.liveRecentAdvertisers.length > 0) {
        return this.liveRecentAdvertisers;
      }

      const key = `${this.$store.getters.user.email}:recentAdvertisers`;
      const recentStr: string | null = localStorage.getItem(key);
      if (!recentStr) {
        return null;
      }
      try {
        const recent: RecentAdvertiser[] = JSON.parse(recentStr).map(adv => {
          if (adv.name || adv.id) {
            return { Name: adv.name, PropertyId: adv.id };
          }
          return adv;
        });
        return recent;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('recentAdvertisers', err);
      }
      return null;
    },
  },
  methods: {
    async applyFiltering(advertisersList, userLayout, selectedAdvertisers, selectedUser) {
      this.loading = true;
      try {
        const isSaved = await this.saveLayout(advertisersList, userLayout, selectedAdvertisers, selectedUser);
        if (isSaved) {
          localStorage.removeItem('hasUpdatedHomeCache');
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async saveLayout(advertisersList, userLayout, selectedAdvertisers, selectedUser): Promise<boolean> {
      // remove null/__type values from customizations and layout
      const cLayout = utils.removeNullFromObjectOrArray(this.$store.state.customer.dynamicLayout.layoutCustomizations);
      this.layout = {
        ...cLayout,
        config: { ...cLayout.config, advertisersList, userLayout, selectedAdvertisers, selectedUser },
      };

      try {
        await this.$store.dispatch('saveLayoutCustomizations', {
          layout: this.layout,
          type: 'user',
          userEmail: this.$store.getters.user.email,
          reset: false,
        });
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
});
