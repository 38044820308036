
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: ['sectionConfig', 'componentConfig'],
  data() {
    return {};
  },
  methods: {},
  computed: {},
});
