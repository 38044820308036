
import Vue from 'vue';
import utils from '../../../../util';
import { getCssVar } from '@c360/ui';
export default Vue.extend({
  name: 'ProgressBarColor',
  props: ['progressBarColor'],
  data: (): { selectedColor: number } => ({
    selectedColor: -1,
  }),
  mounted(): void {
    if (this.progressBarColor) {
      this.selectedColor = this.colors.indexOf(this.progressBarColor);
    }
  },
  methods: {
    setOverride(color: string, index: number): void {
      this.selectedColor = index;
      this.$emit('setOverride', 'progressBarColor', color);
    },
    containerStyles(): { background: string } {
      return {
        background: '#eeeeee',
      };
    },
  },
  computed: {
    colors(): string[] {
      return ['#FC2197', '#FFD02A', '#1E5AFF', '#B43AFF'];
    },
    themeProgressBarBg() {
      return getCssVar('--c360-gradient-accent');
    },
  },
});
