
import Vue from 'vue';
import { componentConfig, dataSource, componentHeight } from './utils/configImpsByClient';
import { ImpressionsByClient } from '../../../../store/modules/primary/types';
import { updateStorage, checkDataValidity, getRangePreviousText, getRangeActualText } from './utils/utils';
import { SortType, ModuleName } from './types/types';
import ImpressionRow from './impressionRow.vue';
import NoChartData from '../../charts/no-data/NoChartData.vue';

export default Vue.extend({
  name: 'ImpressionsByClient',
  props: ['dateRange', 'sorted', 'selectedRange'],
  data: (): {
    impressions: ImpressionsByClient[];
    loading: boolean;
    componentHeight: string;
    componentConfig: any;
    dataSource: string;
    currentPage: number;
    perPage: number;
    showNoDataChart: boolean;
    localStorageName: string;
    currentSortType: SortType;
    sortedItems: ImpressionsByClient[];
  } => ({
    componentHeight: componentHeight,
    loading: false,
    impressions: [],
    componentConfig,
    dataSource,
    currentPage: 1,
    perPage: 7,
    showNoDataChart: false,
    localStorageName: 'impressions_by_client_primary',
    currentSortType: SortType.Impressions,
    sortedItems: [],
  }),
  components: {
    ImpressionRow,
    NoChartData,
  },
  watch: {
    dateRange: {
      handler(newVal) {
        if (newVal) {
          this.getImpressionsByClient(true);
        }
      },
    },
    loading(newVal, oldval) {
      if (newVal !== oldval) {
        this.$emit('update:loading', newVal);
      }
    },
  },
  created() {
    this.getData();
  },
  computed: {
    paginatedData() {
      const sortedItems =
        this.impressions && this.impressions.length > 0
          ? this.impressions.slice().sort((a, b) => {
              if (this.sorted === SortType.Impressions) {
                return b.impressions - a.impressions;
              } else {
                return b.impressionsChange - a.impressionsChange;
              }
            })
          : [];

      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return sortedItems.slice(start, end);
    },
    rangeActualText(): string | null {
      return getRangeActualText(this.dateRange, ModuleName.CLIENTS);
    },
    rangePreviousText(): string | null {
      return getRangePreviousText(this.dateRange);
    },
    totalPages() {
      return Math.ceil(this.impressions.length / this.perPage);
    },
  },
  methods: {
    async getData() {
      const storedData = localStorage.getItem(this.localStorageName);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const { isDataValid, isRightUser, isRightAgency } = checkDataValidity(
          parsedData,
          this.$store.state.customer.user.email,
          this.$store.state.customer.user.Agency,
        );
        this.impressions = parsedData.data;
        if (!isRightUser || !isRightAgency) {
          localStorage.removeItem(this.localStorageName);
          await this.updateImpressionsByClient(true);
        } else if (!isDataValid) {
          await this.updateImpressionsByClient(false);
        }
      } else {
        await this.updateImpressionsByClient(true);
      }
    },
    async updateImpressionsByClient(withLoading: boolean) {
      await this.getImpressionsByClient(withLoading);
      updateStorage(
        this.impressions,
        this.localStorageName,
        this.$store.state.customer.user.email,
        this.$store.state.customer.user.Agency,
      );
    },
    async getImpressionsByClient(withLoading: boolean) {
      try {
        this.loading = withLoading;
        const impressions = await this.$store.dispatch('primary/getImpressionsByClient', { daterange: this.dateRange });
        this.impressions = impressions;
        if (this.impressions?.length > 0) {
          this.showNoDataChart = false;
        } else {
          this.showNoDataChart = true;
        }
      } catch (error) {
        this.showNoDataChart = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
