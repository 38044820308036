
import Vue from 'vue';

export default Vue.extend({
  name: 'CampaignSummaryMetrics',
  props: ['availableTopMetrics', 'availableBottomMetrics', 'topMetrics', 'bottomMetrics', 'impressionsProgressBar'],
  data: (): {
    topMetricsSelection: Array<string> | null;
    bottomMetricsSelection: Array<string> | null;
    impsGoal: Array<string>;
  } => ({
    topMetricsSelection: null,
    bottomMetricsSelection: null,
    impsGoal: ['Impressions', 'Goal'],
  }),
  created(): void {
    this.topMetricsSelection = this.topMetrics;
    this.bottomMetricsSelection = this.bottomMetrics;
  },
  methods: {
    selectTopMetricValue(): void {
      let selection: Array<string> = [];
      if (this.topMetricsSelection.length === 0) {
        selection = ['empty'];
      } else {
        // keep same order
        selection = this.availableTopMetrics.reduce((all, metric) => {
          const found = this.topMetricsSelection.find(m => metric.key === m);
          if (found) {
            all.push(metric.key);
          }
          return all;
        }, []);
      }

      this.$emit('setOverride', 'campaignSummary.topMetrics', selection);
    },
    selectBottomMetricValue(): void {
      let selection: Array<string> = [];
      if (this.bottomMetricsSelection.length === 0) {
        selection = ['empty'];
      } else {
        // keep same order
        selection = this.availableBottomMetrics.reduce((all, metric) => {
          const found = this.bottomMetricsSelection.find(m => metric.key === m);
          if (found) {
            all.push(metric.key);
          }
          return all;
        }, []);
      }

      this.$emit('setOverride', 'campaignSummary.bottomMetrics', selection);
    },
  },
});
