
import Vue from 'vue';
// import utils from '../../../../util';

export default Vue.extend({
  name: 'LineDisplayOptions',
  props: ['showOneLine'],
  data: (): {
    selection: string;
  } => ({
    selection: 'all',
  }),
  created(): void {
    if (this.showOneLine) {
      this.selection = this.showOneLine ? 'one' : 'all';
    }
  },
  computed: {},
  methods: {
    selectValue(value: boolean): void {
      this.$emit('setOverride', 'showOneLine', value);
    },
  },
});
