
import Vue from 'vue';
import OrderListItem from '../../components/charts/list/orderList/shared/orderListItem.vue';
import dataTableFooter from '../../components/charts/tables/DataTableFooter.vue';
import { componentConfig } from './utils/configOrderList';
import { OrderService } from '../../../services/order-summary';

const orderService = new OrderService();

export default Vue.extend({
  name: 'OrderList',
  components: { OrderListItem, dataTableFooter },
  data(): {
    componentConfig: any;
    orderlist: any;
    isLoading: boolean;
    totalPages: number;
    totalObjects: number;
    currentPage: number;
  } {
    return {
      componentConfig,
      orderlist: [],
      isLoading: false,
      totalPages: null,
      totalObjects: null,
      currentPage: 0,
    };
  },
  mounted() {
    this.getOrderList(this.orderListParams);
  },
  beforeDestroy() {
    // reset to default orderlist params before leaving the page
    this.$store.dispatch('order/setOrderlistParams', {
      limit: 10,
      offset: 0,
      sortBy: 'end_asc',
      filterBy: 'active',
      orderName: '',
      orderStatus: 'All',
    });
  },
  computed: {
    orderListParams(): any {
      return this.$store.state.order.orderListParams;
    },
  },
  methods: {
    async getOrderList(params) {
      const method = this.componentConfig?.fetchData?.method || 'getOrderList';
      try {
        this.isLoading = true;
        const fetchFunction = orderService[method];
        if (!fetchFunction) {
          this.error = 'Error fetching';
          // eslint-disable-next-line no-console
          console.error('Provide correct service');
          return;
        }

        params = { ...params, advertiserId: this.$route.query?.id || 'N/A' };
        const metrics = await fetchFunction(params);
        this.orderlist = metrics?.OrderList;
        this.totalPages = metrics?.PaginationInfo?.totalPages;
        this.totalObjects = metrics?.PaginationInfo?.totalObjects;
        this.currentPage = metrics?.PaginationInfo?.currentPage + 1;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching', error);
      } finally {
        this.isLoading = false;
      }
    },
    selectOrder(orderId: string) {
      this.$emit('select-order', orderId);
    },
    paginate(pageNumber) {
      const orderParams = this.$store.state.order.orderListParams;
      this.$store.dispatch('order/setOrderlistParams', {
        ...orderParams,
        offset: (pageNumber - 1) * 10,
      });
    },
  },
  watch: {
    orderListParams: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any, oldVal: any): void {
        // TODO: remove this condition when date range for the list is implemented on the BE
        if (oldVal.daterange !== newVal.daterange) {
          return;
        }
        this.getOrderList(newVal);
      },
      deep: true,
    },
    '$route.query.id': {
      // reset order list params when advertiser changes
      handler(): void {
        this.$store.dispatch('order/setOrderlistParams', {
          limit: 10,
          offset: 0,
          sortBy: 'end_asc',
          filterBy: 'active',
          orderName: '',
          orderStatus: 'All',
        });
      },
    },
  },
});
