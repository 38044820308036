import { SectionComponent } from '../../types/layout';
import checkFlag from './checkFlag';

export type validateComponentProps = {
  element: SectionComponent;
  adData: object;
  allTimeAdData: object;
  campaignAdPerformance: object;
  debugMissingData?: boolean;
};
export default function validateComponent({
  element,
  adData, // eslint-disable-line @typescript-eslint/no-unused-vars
  allTimeAdData,
  campaignAdPerformance,
  debugMissingData,
}: validateComponentProps) {
  let isSelectionValid = true;
  let isAllTimeValid = true;
  const elementName = element.id || element.name;

  if (typeof element.dataChecks === 'string') {
    // hardcoded for everything to be considered valid.
    // We previously used the dataChecks and compared it to the loaded data,
    // but in the new approach we don't have all of that data yet.
    // We are forced to assume the sections returned from the {getProductsForAdvertiser} api are valid

    // TODO: remove all references to these fields
    return { validInCurrentSelection: true, validAllTime: isAllTimeValid };
  }
  if (Array.isArray(element.dataChecks)) {
    if (
      element.dataChecks.find((test: string) => {
        if (!checkFlag(campaignAdPerformance, test)) {
          if (debugMissingData) {
            // eslint-disable-next-line no-console
            console.info(
              `not rendering [${elementName}] due to [${element.dataChecks}][${test}]`,
              element,
              campaignAdPerformance,
            );
          }
          return true;
        }
        return false;
      })
    ) {
      isSelectionValid = false;
    }
    if (
      element.dataChecks.find((test: string) => {
        return !checkFlag(allTimeAdData, test);
      })
    ) {
      isAllTimeValid = false;
    }
    return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
  }
  if (element.dataChecks) {
    // eslint-disable-next-line no-console
    console.info(`not rendering [${elementName}] due to unhandled dataChecks`, element, campaignAdPerformance);
    return { validInCurrentSelection: false, validAllTime: false };
  }
  if (typeof element.dataChecksAny === 'string') {
    if (!checkFlag(campaignAdPerformance, element.dataChecksAny)) {
      if (debugMissingData) {
        // eslint-disable-next-line no-console
        console.info(
          `not rendering [${elementName}] due to [${element.dataChecksAny}]`,
          element,
          campaignAdPerformance,
        );
      }
      isSelectionValid = false;
    }
    if (!checkFlag(allTimeAdData, element.dataChecksAny)) {
      isAllTimeValid = false;
    }
    return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
  }
  if (Array.isArray(element.dataChecksAny)) {
    isSelectionValid = false;
    if (element.dataChecksAny.find((test: string) => checkFlag(campaignAdPerformance, test))) {
      isSelectionValid = true;
    }
    isAllTimeValid = false;
    if (element.dataChecksAny.find((test: string) => checkFlag(allTimeAdData, test))) {
      isAllTimeValid = true;
    }
    if (!isSelectionValid && debugMissingData) {
      // eslint-disable-next-line no-console
      console.info(
        `not rendering [${elementName}] due to any [${element.dataChecksAny}]`,
        element,
        campaignAdPerformance,
      );
    }
    return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
  }
  if (element.dataChecksAny) {
    // eslint-disable-next-line no-console
    console.info(`not rendering [${elementName}] due to unhandled dataChecksAny`, element, campaignAdPerformance);
    return { validInCurrentSelection: false, validAllTime: false };
  }

  if (Array.isArray(element.dataChecksByCampaign)) {
    if (
      element.dataChecksByCampaign.find((test: string) => {
        if (!checkFlag(campaignAdPerformance, test)) {
          if (debugMissingData) {
            // eslint-disable-next-line no-console
            console.info(
              `not rendering [${elementName}] due to [${element.dataChecksByCampaign}][${test}]`,
              element,
              campaignAdPerformance,
            );
          }
          return true;
        }
        return false;
      })
    ) {
      isSelectionValid = false;
    }
    if (
      element.dataChecksByCampaign.find((test: string) => {
        return !checkFlag(allTimeAdData, test);
      })
    ) {
      isAllTimeValid = false;
    }
    return { validInCurrentSelection: isSelectionValid, validAllTime: isAllTimeValid };
  }
  return { validInCurrentSelection: true, validAllTime: true };
}
