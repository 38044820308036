import { Range, RangeText, EnhancedDateRange } from '../types/types';

export const daterangeDefault: EnhancedDateRange[] = [
  { RangeName: 'Last 7 Days', RangeKey: Range.THISWEEK, Text: RangeText.THISWEEK },
  { RangeName: 'Last 30 Days', RangeKey: Range.THISMONTH, Text: RangeText.THISMONTH },
  { RangeName: 'Last 365 Days', RangeKey: Range.THISYEAR, Text: RangeText.THISYEAR },
  { RangeName: 'All Time', RangeKey: Range.ALLTIME, Text: RangeText.ALLTIME },
];

export const defaultMetrics = [{ name: 'Clients' }, { name: 'Orders' }, { name: 'Campaigns' }, { name: 'Impressions' }];

