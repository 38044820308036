
import Vue from 'vue';
import utils from '../../../../util';

export default Vue.extend({
  name: 'LineSeriesKeys',
  props: ['availableLineSeriesKeys', 'lineSeriesKeys'],
  data: (): {
    selection: Array<string> | null;
  } => ({
    selection: null,
  }),
  created(): void {
    this.selection = this.lineSeriesKeys;
  },
  computed: {
    columnOptions(): Array<{ text: string; value: string; disabled: boolean }> {
      return this.availableLineSeriesKeys?.map(key => {
        return {
          text: utils.headerNamesMap(key),
          value: key,
          disabled: this.selection.length === 1 && this.selection[0] === key,
        };
      });
    },
  },
  methods: {
    selectValue(): void {
      this.$emit('setOverride', 'lineSeriesKeys', this.selection);
    },
  },
});
