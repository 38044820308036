
import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: ['adData', 'headlineText', 'descriptionText'],
  data: (): {} => ({}),
  computed: {
    isResponsiveSearch(): boolean {
      return this.adData.adType === 'responsive search ad';
    },
    isExpandedText(): boolean {
      return this.adData.adType === 'expanded text ad';
    },
  },
});
