import commonPkg from '@point/classes';

const TOOLTIPS = commonPkg.tooltips.METRIC_TOOLTIPS;

export const componentConfig = {
  cid: 'ordersummaryPerformanceByImpressionsPie',
  id: 'ordersummaryPerformanceByImpressionsPie',
  dataChecks: ['HasDisplay'],
  disabled: undefined,
  dataSource: 'ORDER.ByDeviceTypeImpression',
  breakpoints: ['lg3', 'sm6'],
  name: 'genericPie',
  title: 'Products Overview',
  __typename: 'genericPie',
  exportableTableData: 'xls',
  filterable: true,
  hasBrandImage: undefined,
  hideIfNoData: undefined,
  nameKey: 'DeviceType',
  rootValueKey: 'ORDER',
  selectedValueKey: true,
  stacked: false,
  tooltips: [TOOLTIPS.ORDERS_PRODUCTS_OVERVIEW],
  valueKey: ['Impressions'],
  overrides: undefined,
  noDataTooltips: ['No data'],
  feedSource: undefined,
  removeLegend: true,
  hideTitle: false,
  canvasWidth: '100%',
  canvasHeight: '300px',
  chartColors: [
    '#FF738C',
    '#FF3D7F',
    '#E91E63',
    '#DB09C9',
    '#AA06F4',
    '#7005FA',
    '#5D36FB',
    '#00009D',
    '#000FD2',
    '#0060FB',
    '#2196F3',
    '#4AC7FF',
    '#0ADFD2',
    '#00BCD4',
    '#1CC700',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#CD8469',
    '#E6A38B',
    '#9E9E9E',
    '#C2BDBD',
  ],
};

export const componentHeight = '430px';

export const dataSource = 'ORDER.ByDeviceTypeImpression';

export const sectionConfig = {
  id: 'home',
  enableCampaignSelection: true,
  fileNameTemplate: '${advertiserNameKey}-${tacticModule}-${fileDateTime}',
  pdfExport: 'dynamic/pdf/display',
  pptExport: 'qbr/ppt/display',
  pptExportDevOnly: false,
  pptExportLocalOnly: false,
  title: 'Home',
  xlsExport: 'dynamic/xls/display',
  xlsExportDevOnly: false,
  markup: ['CPM', 'CPC'],
  exportTab: null,
  xlsExportLocalOnly: null,
  __typename: 'Section',
};

export const isExporting = false;

export const mappingTacticsNames = {
  AUDIO: 'Audio',
  BROADCAST: 'Broadcast TV',
  BROADSTREET: 'Broadstreet',
  CALLTRACKING: 'Call Tracking',
  DISPLAY: 'Display',
  FBINSIGHTS: 'Facebook Insights',
  GA: 'Google Analytics',
  GAMDISPLAY: 'Display - O&O',
  GAMVIDEO: 'Video - O&O',
  GOOGLESEARCH: 'Google Search Console',
  GOOGLEVIDEO: 'YouTube',
  OTT: 'CTV',
  PREROLL: 'Video',
  SEM: 'SEM',
  SIMPGEOFENCE: 'Geofence',
  SITEIMPACT: 'Email Marketing',
  SOCIAL: 'Facebook',
  VIDEO: 'CDV',
  GTDISPLAY: 'Geofence - Display',
  GTVIDEO: 'Geofence - Video',
  LINEAR: 'Sinclair RSN',
  TRUGEOFENCE: 'True Geo',
  TV2OTT: 'InnovidXP OTT',
  TV2LINEAR: 'InnovidXP Linear',
  TV2CROSSPLATFORM: 'InnovidXP Cross-Platform',
  GEOFENCE: 'Geofence',
};
