
import Vue from 'vue';
import breakpoints from '../editorOptions/breakpoints.vue';
import chartTitle from '../editorOptions/title.vue';
import notifications from '../editorOptions/notifications.vue';
import valueKey from '../editorOptions/valueKey.vue';
import HidePie from '../editorOptions/hidePie.vue';

export default Vue.extend({
  components: {
    breakpoints,
    chartTitle,
    notifications,
    valueKey,
    HidePie,
  },
  props: ['componentData'],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setOverride(key: string, value: any): void {
      this.$emit('setOverride', key, value);
    },
  },
  computed: {
    hasHiddenPieFeature(): boolean {
      return this.componentData?.dataSource === 'ConversionBreakdown';
    },
    hasAvailableValueKeys(): boolean {
      return this.componentData?.availableValueKeys?.length;
    },
  },
});
