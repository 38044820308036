
import Vue from 'vue';
import TacticTable from './tactic-table.vue';
import { CurrentSection } from '../../../../../types/layout';

export default Vue.extend({
  name: 'centeredHeader',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'config',
  ],
  components: { TacticTable },
  data: (): {
    search: string;
  } => ({
    search: '',
  }),
  mounted(): void {
    if (this.isExporting) {
      this.$emit('start-export-render');
    }
  },
  methods: {
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
  },
  computed: {
    campaignCountStyles(): object {
      const styles = {
        'background-color': this.Theme.light.primary,
        width: '45px',
        height: '45px',
      };
      if (this.config.totalCampaignCount > 99) {
        styles.width = '50px';
        styles.height = '50px';
      }
      return styles;
    },
  },
});
