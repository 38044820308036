import DateRange from '../../../../types/dateRange';

export enum Range {
  THISWEEK = 'thisweek',
  THISMONTH = 'thismonth',
  THISYEAR = 'thisyear',
  ALLTIME = 'alltime',
  LAST30DAYS = 'last30days',
  LASTWEEK = 'lastweek',
  TWOMONTHAGO = 'twomonthago',
  LASTMONTH = 'lastmonth',
  YESTERDAY = 'yesterday',
  LASTYEAR = 'lastyear',
  CUSTOM = 'customRange'
}

export enum RangeText {
  THISWEEK = 'thisweek',
  THISMONTH = 'thismonth',
  THISYEAR = 'thisyear',
  ALLTIME = 'alltime',
  LAST30DAYS = 'last30days',
  LASTWEEK = 'lastweek',
  TWOMONTHAGO = 'twomonthago',
  LASTMONTH = 'lastmonth',
  YESTERDAY = 'yesterday',
  LASTYEAR = 'lastyear',
  CUSTOM = 'customrange'
}

export interface EnhancedDateRange extends Omit<DateRange, 'Text' | 'RangeKey'> {
    Text: RangeText;
    RangeKey: Range;
}
