
import Vue from 'vue';
export default Vue.extend({
  props: ['geoData', 'demographicsOnly'],
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    demographicsTitle(demographic: any): string {
      if (!this.demographicsOnly) {
        return demographic?.title;
      }
      switch (demographic?.title) {
        case 'DMAs':
          return 'DMA TARGET';
        default:
          return demographic?.title?.toUpperCase() || 'N/A';
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    demographicsPairs(demographic: any): any[] {
      const l = [];
      const list = demographic.values;
      for (let i = 0; i < list.length - 1; i += 2) {
        l.push([list[i], list[i + 1], false]);
      }
      l[l.length - 1][2] = true;
      return l;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    demographicsOnlyValue(value: string, index: number, demographic: any): string {
      return value + (index < demographic.values.length - 1 ? ', ' : '');
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geoDataAsLists(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.geoData?.map((demo: any) => {
        return {
          ...demo,
          values: demo.values.split(','),
        };
      });
    },
  },
});
