
import Vue from 'vue';
import utils from '../../../../util';
import { format } from 'date-fns';
import MainSummaryPage from './modules/_mainPage.vue';
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import StandAloneSummary from './modules/_standAloneSummary.vue';
import FooterData from './modules/_footerData.vue';
import DemographicView from './modules/_demographicView.vue';
import SegmentView from './modules/_segmentView.vue';
import GeoDataView from './modules/_geoDataView.vue';
import Tooltips from '../../charts/tooltip/chartTooltip.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'mapCampaignSummary',
  components: {
    MainSummaryPage,
    StandAloneSummary,
    FooterData,
    DemographicView,
    SegmentView,
    GeoDataView,
    Tooltips,
  },
  props: [
    'analyticsType',
    'bottomMetrics',
    'campaignDetails',
    'componentConfig',
    'getCampaignDates',
    'hasImpressionsProgressBar',
    'hasSiteTrafficProgressBar',
    'impressionMetricFontValues',
    'impressionProgressValues',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'isSponsoredGAM',
    'loading',
    'mapHeight',
    'replacementTitle',
    'selectedCampaigns',
    'siteTrafficProgressValues',
    'sponsoredGamProgress',
    'standAlone',
    'tacticNames',
    'topMetrics',
    'sectionId',
    'componentId',
    'demographicsData',
    'geoData',
    'segmentData',
    'demographicsOnly',
  ],
  data() {
    return {
      metricOverTotal: null,
      dateRangeSelected: '',
      totalValue: null,
    };
  },
  created() {
    this.metricOverTotal = this.calcMetricOverTotal(this.impressionProgressValues);
    this.dateRangeSelected = this.calcDateRangeSelected();
    if (!this.isExporting) {
      const exportState = this.$store.getters.exportState || {};
      exportState.selectedRangeFormattedName = this.dateRangeSelected;
      this.$store.dispatch('setExportState', exportState);
    }
  },
  mounted() {
    if (this.isExporting) {
      this.setContainerVisibility();
    } else {
      setTimeout(() => {
        // a lot of things are happening when the view loads. The map takes a few seconds to load.
        // this attempts to load at a slight delay to reduce visual jumps
        this.setContainerVisibility();
      }, 500);
    }

    if (this.demographicsOnly) {
      setTimeout(() => {
        if (this.showDemographicsView || this.showSegmentView || this.showGeodataView) {
          this.$emit('rendered', { empty: false, check: 'mapSummaryView.demographicsOnly' });
        } else {
          this.$emit('rendered', { empty: true, check: 'mapSummaryView.demographicsOnly' });
        }
      }, 100);
    } else if (this.standAlone) {
      setTimeout(() => {
        this.$emit('rendered', { empty: false, check: 'mapSummaryView.standAlone' });
      }, 100);
    }
  },
  methods: {
    getTooltips(key: string): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(key)];
    },
    setContainerVisibility() {
      this.containerHeight = this.$refs?.detailsContainer?.clientHeight;
    },
    onChangeView(isNext) {
      this.$emit('changeView', isNext);
      this.setContainerVisibility();
    },
    formatDate(date: string): string {
      try {
        if (date && date.length > 0 && date !== 'undefined' && date !== 'noEndDate') {
          const parsedDate = utils.normalizeDate(date);
          return format(parsedDate, 'MMM dd, yyyy');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    checkContractEndDate(date: string): boolean {
      if (date === 'noEndDate') {
        return true;
      }
      return false;
    },
    capitalizeWords(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    switchDateRange(newValue) {
      this.metricOverTotal = this.calcMetricOverTotal(newValue);
    },
    calcMetricOverTotal(impressionProgressValues): string {
      let metric = this.impressionProgressValues.metricValue;
      let total = impressionProgressValues.totalValue || 0;
      this.totalValue = impressionProgressValues.totalValue;
      let percent;

      if (!impressionProgressValues.percent) {
        // calculate percent if not given
        if (typeof metric === 'string') {
          metric = parseFloat(impressionProgressValues.metricValue.replace(/[!@#$%^&*]/g, ''));
        }
        if (typeof total === 'string') {
          total = parseFloat(impressionProgressValues.totalValue.replace(/[!@#$%^&*]/g, ''));
        }

        if (total === 0) {
          percent = metric === 0 ? '0%' : '100%';
        } else {
          percent = `${((metric / total) * 100).toFixed(0)}%`;
        }
      } else {
        percent = impressionProgressValues.percent;
      }

      if (!this.isExporting) {
        const exportState = this.$store.getters.exportState || {};
        exportState.impressionPercentForSelectedRange = percent;
        this.$store.dispatch('setExportState', exportState);
      }

      return percent;
    },
    calcDateRangeSelected() {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        const startDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.StartDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.StartDate);
        const endDate = this.isExporting
          ? this.formatDateRange(this.$store.state.customer.exportingData.adData.EndDate)
          : this.formatDateRange(this.$store.state.customer.campaignAdPerformance.EndDate);
        return `${startDate} - ${endDate}`;
      }

      const dateRangeList = this.isExporting
        ? this.$store.state.customer.exportingData?.campaigns.Campaign[0].DateRanges
        : this.$store.state.customer.campaigns.campaignlist[0].DateRanges;
      const range = dateRangeList?.find(item => item.RangeKey === daterange);

      if (range) {
        return this.capitalizeWords(range.RangeName);
      }
      return 'All Time';
    },
    formatDateRange(inputDate) {
      const date = new Date(inputDate);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return formattedDate;
    },
  },
  computed: {
    combinedMetrics(): Array<Object> {
      const topMetrics = this.topMetrics ?? [];
      const bottomMetrics = this.bottomMetrics ?? [];

      const metrics = [...topMetrics, ...bottomMetrics];
      return metrics?.filter(
        metric =>
          !(
            (metric.key === 'Conversions' && metric.value === '0') ||
            (metric.key === 'CVR' && metric.value === '0.00%') ||
            (this.isYahoo &&
              ((metric.key === 'CompletionRate' && metric.value === '0.00%') || (metric.key === 'Hours' && metric.value === '0')))
          ),
      );
    },
    metricOverTotalValueToDisplay(): any {
      if (!this.isExporting) return this.metricOverTotal;
      const cfg = this.exportData?.userState;
      return cfg?.impressionPercentForSelectedRange ?? '';
    },
    summaryDisplayName(): string {
      return this.analyticsType?.toLowerCase() === 'broadcast' ? 'Station' : 'Campaign';
    },
    hasData(): boolean {
      return !!this.campaignDetails;
    },
    showSegmentView(): boolean {
      return !!this.segmentData;
    },
    showDemographicsView(): boolean {
      return this.demographicsData?.length > 0;
    },
    showGeodataView(): boolean {
      return this.geoData?.length > 0;
    },
    showSummaryTooltip(): boolean {
      const accessList = ['gtdisplay', 'gtvideo', 'trugeofence'];
      return accessList.includes(this.componentConfig.dataSource.toLowerCase());
    },
    showCampaignType(): boolean {
      if (!this.tacticNames.toLowerCase().includes('youtube')) return false;
      if (!this.$attrs.campaignDetails?.AdFormats?.length) return false;
      return true;
    },
    campaignType(): string {
      return this.$attrs.campaignDetails?.AdFormats.join(', ');
    },
    rangeValueToDisplay(): string {
      if (!this.isExporting) return this.rangeValue;
      const cfg = this.exportData?.userState;
      return cfg?.selectedRangeFormattedName ?? '';
    },
    rangeValue() {
      const params = { ...this.$store.state.customer.selection };
      const daterange = params.daterange;

      if (daterange === 'customRange') {
        return `Goal for Date Range`;
      }
      return `${this.dateRangeSelected} Goal`;
    },
    isShowImpressionGoal(): boolean {
      return this.totalValue > 100;
    },
    isYahoo(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('YAHOO');
    },
  },
});
