
import Vue from 'vue';
import DropdownMenu from './summaryDateRangeDropdown.vue';
import { Range, RangeText } from './types';
import { mappedRange } from './utils';
import { summaryDaterangeDefault } from './constants';


export default Vue.extend({
  components: { DropdownMenu },
  props: ['loading'],
  data(): {
    selectedRange: { RangeName: string; RangeKey: Range; Text: RangeText } | null;
  } {
    return {
      selectedRange: { RangeName: 'All time', RangeKey: Range.ALLTIME, Text: RangeText.ALLTIME }
    };
  },
  created() {
    const querySummarydaterange = this.$route?.query.summarydaterange;
    if (querySummarydaterange) {
      this.selectedRange = mappedRange(querySummarydaterange, summaryDaterangeDefault)
    }
  },
  computed: {
    isSummaryPage(): boolean {
      return this.$route?.query?.tab === 'summary';
    },
    advertiserId(): string {
      return this.$route.query.id;
    }
  },
  methods: {
    selectRange(selectedRange): void {
      this.selectedRange = selectedRange
      if (selectedRange.RangeKey === 'customRange') {
        this.$router.push(
          {
            query: {
              ...this.$route.query,
              summarydaterange: selectedRange.RangeKey,
              summarystartdate: selectedRange.dates[0],
              summaryenddate: selectedRange.dates[1],
            },
          },
        );
      } else {
        this.$router.push(
          {
            query: {
              ...this.$route.query,
              summarydaterange: selectedRange.RangeKey,
            },
          },
        );
      }
    },
  },
});
