
import Vue from 'vue';

export default Vue.extend({
  name: 'ConversionLegend',
  props: ['pieColors', 'legendData'],
  methods: {
    legendHover(name: string, value: number): void {
      if (!value || value === 0) return;
      this.$emit('m-enter', name);
    },
    legendUnHover(name: string, value: number): void {
      if (!value || value === 0) return;
      this.$emit('m-leave', name);
    },
    color(index: number): string {
      return this.pieColors[index];
    },
    iconColor(index: number): Record<string, string> {
      return {
        'background-color': this.color(index),
      };
    },
  },
});
