
import { GeoTargets, GeoTargetsData } from '../../../../types/geoTargets';
import { Segment, Segments } from '../../../../types/segment';
import ProgressBarValues from '../../../../types/progressBarValues';
import SponsoredGamProgressValues from '../../../../types/sponsoredGamProgress';
import utils from '../../../../util';
import mapCampaignSummary from './mapCampaignSummary.vue';

interface SummaryMetric {
  title: string;
  value: string;
  key: string;
}

export default {
  name: 'mapCampaignSummaryController',
  inheritAttrs: false,
  props: [
    'sectionConfig',
    'componentConfig',
    'mapHeight',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'standAlone',
    'demographicsOnly',
  ],
  components: {
    mapCampaignSummary,
  },
  data: (): {
    campaignView: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortedGeoData: any[] | null;
  } => ({
    campaignView: false,
    sortedGeoData: [0, 0, 0, 0],
  }),
  mounted(): void {
    if (this.componentConfig.campaignSummary.siteTrafficProgressBar) {
      const geoData = utils.adDataForKey(this, `${this.componentConfig.dataSource}.GeoList`);
      if (geoData) {
        this.sortedGeoData = geoData.sort((a, b) =>
          a.PageViews > b.PageViews ? -1 : b.PageViews > a.PageViews ? 1 : 0,
        );
      }
    }
  },
  computed: {
    rootValueKey(): string {
      const rootValueKey = this.componentConfig?.rootValueKey;
      return rootValueKey ? utils.adDataForKey(this, rootValueKey) : null;
    },
    isSponsoredGAM(): boolean {
      return this.tacticNames?.toLowerCase() === 'gam - sponsorship';
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedCampaigns(): any[] {
      if (this.isExporting) {
        if (this.exportData.campaignIds && Array.isArray(this.exportData.adData.CampaignList)) {
          return this.exportData.adData.CampaignList;
        }

        const tacticName =
          this.componentConfig.AnalyticsType ||
          this.sectionConfig.AnalyticsType ||
          this.exportData.layout.AnalyticsType;

        // filter all campaigns for the current tactic
        if (Array.isArray(this.exportData.adData.CampaignList)) {
          return this.exportData.adData.CampaignList.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (c: any) => c.AnalyticsType === tacticName,
          );
        }
      }
      if (this.$store.state.filters.selectedCampaigns && Array.isArray(this.$store.state.filters.selectedCampaigns)) {
        return this.$store.state.filters.selectedCampaigns;
      }
      return [];
    },
    sponsoredGamProgress(): SponsoredGamProgressValues {
      return {
        sponsored: {
          value: this.campaignDetails?.Sponsored,
          title: 'Sponsored inventory',
        },
        impressions: {
          value: this.campaignDetails?.Impressions,
          title: 'Geofencing Impressions',
        },
      };
    },
    impressionProgressValues(): ProgressBarValues {
      const isGeoFence =
        this.analyticsType?.toLowerCase() === 'gtvideo' || this.analyticsType?.toLowerCase() === 'gtdisplay';
      return {
        metricValue: this.campaignDetails?.Impressions,
        metricTitle: isGeoFence ? 'Campaign Impressions' : 'Imps',
        totalValue: this.campaignDetails?.ImpressionGoal,
        totalTitle: 'Goal',
        percent: this.campaignDetails?.ImpressionPercent,
        originImpressionGoal: this.campaignDetails?.OriginImpressionGoal,
      };
    },
    spendProgressValues(): ProgressBarValues {
      const spend = parseFloat(this.campaignDetails?.Spend).toFixed(2) || 0;
      let budget;
      if (this.campaignDetails?.Budget) {
        budget = parseFloat(this.campaignDetails?.Budget).toFixed(2);
      } else {
        budget = '0.00';
      }
      return {
        metricValue: `$${this.formatValue(spend)}`,
        metricTitle: 'Total spend',
        totalValue: `$${this.formatValue(budget)}`,
        totalTitle: 'Budget',
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    siteTrafficProgressValues(): any {
      const trafficLength = this.sortedGeoData.length;
      const oneThirdValue = Math.floor(trafficLength * 0.666);
      const twoThirdValue = Math.floor(trafficLength * 0.333);
      return {
        topValue: this.sortedGeoData[0]?.PageViews,
        oneThirdValue: this.sortedGeoData[oneThirdValue]?.PageViews,
        twoThirdValue: this.sortedGeoData[twoThirdValue]?.PageViews,
        bottomValue: this.sortedGeoData[trafficLength - 1]?.PageViews,
      };
    },
    replacementTitle(): string | boolean {
      return this.componentConfig?.campaignSummary?.replaceTitle;
    },
    campaignList(): object[] {
      if (this.isExporting) {
        return this.exportData.adData.CampaignList;
      }
      if (!Array.isArray(this.$store.state.customer.campaigns.campaignlist)) {
        return [];
      }
      return this.$store.state.customer.campaigns.campaignlist;
    },
    analyticsType(): string {
      return this.campaignDetails?.AnalyticsType;
    },
    campaignDetails(): object | null {
      if (this.selectedCampaigns.length < 1) {
        return null;
      }
      let combinedSummary;
      const firstCampaign = this.selectedCampaigns[0];
      if (this.isExporting) {
        if (!firstCampaign || !firstCampaign.AnalyticsType) {
          return null;
        }
        combinedSummary = this.exportData.adData[`${firstCampaign.AnalyticsType.toUpperCase()}Total`];
      } else {
        if (!firstCampaign || !firstCampaign.CampaignType) {
          return null;
        }
        combinedSummary = utils.getSummmaryDetailsByType(this, firstCampaign.CampaignType);
      }
      if (!combinedSummary) {
        return null;
      }

      return combinedSummary;
    },
    bottomMetrics(): Array<SummaryMetric> {
      if (!this.campaignDetails || !this.componentConfig.campaignSummary) {
        // eslint-disable-next-line no-console
        console.log(`map summary: no bottomMetrics`);
        return [];
      }

      const { bottomMetrics } = this.componentConfig.campaignSummary;
      let metrics: Array<SummaryMetric> = [];
      if (Array.isArray(bottomMetrics)) {
        const filtered = utils.filteredMetrics(this, bottomMetrics);

        // add in metric values
        metrics = filtered.map((metric: SummaryMetric) => {
          let metricValue = this.campaignDetails[metric.key];
          if (metric.key === 'SearchImprshare' && this.rootValueKey) metricValue = this.rootValueKey;

          return {
            title: metric.title,
            value: this.formatValue(metricValue),
            key: metric.key,
          };
        });
      }
      return metrics;
    },
    topMetrics(): Array<SummaryMetric> {
      if (!this.campaignDetails || !this.componentConfig.campaignSummary) return [];
      let metrics: Array<SummaryMetric> = [];
      const { topMetrics } = this.componentConfig.campaignSummary;

      if (Array.isArray(topMetrics)) {
        const filtered = utils.filteredMetrics(this, topMetrics);

        // add in metric values
        metrics = filtered.map((metric: SummaryMetric) => {
          return {
            title: metric.title,
            value: this.formatValue(this.campaignDetails[metric.key]),
            key: metric.key,
          };
        });

        // has progress bar but no impression goal, add Imps to metrics
        if (this.componentConfig.campaignSummary?.impressionsProgressBar && !this.hasImpressionsGoal) {
          metrics.push({
            title: utils.headerNamesMap('Impressions'),
            value: this.formatValue(this.campaignDetails['Impressions']),
            key: 'Impressions',
          });
        }

        if (!this.isBeeswax && !this.isXandr && !this.isSem) {
          const filteredBySource = [...metrics].filter(
            (metric: SummaryMetric) => !['Conversions', 'CVR'].includes(metric.key),
          );

          metrics = filteredBySource;
        }

        if (this.impressionProgressValues?.totalValue <= 100) {
          const filteredByPacing = [...metrics].filter((metric: SummaryMetric) => metric.key !== 'Pacing');

          metrics = filteredByPacing;
        }

        if (this.isOTT) {
          const innovidMetrics = [
            'InnovidXPImpressions',
            'InnovidXPResponseNumber',
            'InnovidXPCostPerResponse',
            'InnovidXPResponsePercent',
          ];
          const innovidValues = [...metrics].filter((metric: SummaryMetric) => innovidMetrics.includes(metric.key));

          if (innovidValues.some(m => m.value === 'unknown')) {
            metrics = [...metrics].filter((metric: SummaryMetric) => !innovidMetrics.includes(metric.key));
          }
        }
      }

      return metrics;
    },
    demographicsData(): object[] {
      const data = utils.adDataForKey(this, 'SIMPGEOFENCE.CampaignTarget');
      if (!data) return [];
      const dataKeys = Object.keys(data).filter(item => item !== 'CampaignId');
      const demographics = dataKeys.map(item => {
        const isString = typeof data[item] === 'string';
        return {
          icon: this.getDemographicIcon(item),
          title: item,
          values: isString ? [data[item]] : data[item],
        };
      });
      return demographics;
    },
    geoData(): Array<GeoTargets> | [] {
      let dataKey = utils.dataKeyBySectionIdMap(this.sectionConfig.id);
      if (this.isExporting) {
        dataKey = utils.dataKeyBySectionIdMap(this.exportData.tab);
      }
      const totalData = utils.adDataForKey(this, `${dataKey}Total`);
      if (totalData) {
        if (totalData['GeoTargetings']) {
          const data = totalData['GeoTargetings'];
          const geoTargets = data.reduce((arr: Array<GeoTargets>, geoTarget: GeoTargetsData) => {
            if (geoTarget.values && Array.isArray(geoTarget.values) && geoTarget.values.length) {
              let valuelist = geoTarget.values;
              if (geoTarget.values.length > 3 && !this.demographicsOnly) {
                valuelist = [...geoTarget.values.slice(0, 3), '...'];
              }
              const geoData = {
                icon: this.getDemographicIcon(geoTarget.type),
                title: geoTarget.DisplayName || '',
                values: valuelist.join(', '),
                tooltipValue: geoTarget.values.join(', '),
              };
              arr.push(geoData);
            }
            return arr;
          }, []);
          // this wasn't returning any Geotargetings and using the fallbacks
          // if (geoTargets.length > 1) return geoTargets;
          if (geoTargets[0] && geoTargets[0]?.values?.length) {
            return geoTargets;
          } else if (totalData['GeoTargeting']) {
            // Fallback for now if new data is not present
            return this.geoTargetFallback(totalData['GeoTargeting']);
          } else return [];
        } else if (totalData['GeoTargeting']) {
          // Fallback for now if new data is not present
          return this.geoTargetFallback(totalData['GeoTargeting']);
        }
      }

      return [];
    },
    segmentData(): Segments | null {
      const totalData = utils.adDataForKey(this, `${this.exportData?.campaign?.AnalyticsType}Total`);
      if (totalData && totalData['ByAudienceTarget']) {
        const data = totalData['ByAudienceTarget'];
        // check for empty array.
        if (Array.isArray(data) && data.length === 0) return null;
        const segments: Array<Segment> = data.map((segment: Segment): Segment => {
          // scrub to use updated property names
          if (segment?.Segment) {
            return {
              Segment: segment.Segment,
              SegmentType: segment?.SegmentType || 'N/A',
            };
          }
        });
        return {
          icon: 'my_location',
          title: this.isExporting ? 'AUDIENCE TARGET' : 'Audience target',
          values: segments,
          tooltipValues: segments,
        };
      } else return null;
    },
    hasSpendProgressBar(): boolean {
      return this.componentConfig.campaignSummary?.spendProgressBar;
    },
    hasImpressionsGoal(): boolean {
      return !!this.campaignDetails?.ImpressionGoal;
    },
    hasImpressionsProgressBar(): boolean {
      const inConfig = this.componentConfig.campaignSummary?.impressionsProgressBar;
      const hasMetricValue =
        this.impressionProgressValues?.metricValue !== null && this.impressionProgressValues?.metricValue !== undefined;
      return inConfig && hasMetricValue && this.hasImpressionsGoal;
    },
    hasSiteTrafficProgressBar(): boolean {
      return this.componentConfig.campaignSummary?.siteTrafficProgressBar;
    },
    tacticNames(): string {
      if (this.selectedCampaigns.length === 1) {
        let list = [];
        if (this.isExporting) {
          list = this.selectedCampaigns[0].Tactics;
        } else {
          const details = utils.campaignDetailsById(this)[this.selectedCampaigns[0].id];
          if (!details) {
            return '';
          }
          list = details.Tactics;
        }
        if (!Array.isArray(list) || list.length === 0) {
          return '';
        }
        return list
          .map((tactic: string) => {
            return utils.getTacticName(tactic);
          })
          .join(', ');
      } else {
        if (!this.campaignDetails) {
          return '';
        }
        return utils.getTacticName(this.campaignDetails.AnalyticsType);
      }
    },
    impressionMetricFontValues(): object {
      return {
        color: 'primary',
        size: this.topMetrics ? 'h6' : 'h5',
        weight: this.topMetrics ? '' : 'bold',
      };
    },
    campaignDetailsById(): object {
      if (this.isExporting) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hash: any = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.exportData.adData.CampaignList.forEach((c: any) => {
          hash[c.CampaignId] = c;
        });
        return hash;
      }
      return utils.campaignDetailsById(this);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCampaignDates(): any[] {
      return utils.getCampaignDateRange(this);
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    hideZeros(): boolean {
      return this.componentConfig.campaignSummary.hideZeros;
    },
    isBeeswax(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('BEESWAX');
    },
    isXandr(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('XANDR');
    },
    isSem(): boolean {
      return this.isExporting
        ? this.exportData.tab?.toLowerCase() === 'sem'
        : this.$store.state.customer?.currentNavTab?.toLowerCase() === 'sem';
    },
    isOTT(): boolean {
      return this.isExporting
        ? this.exportData.tab.toLowerCase() === 'ott'
        : this.$store.state.customer.currentNavTab.toLowerCase() === 'ott';
    },
  },
  watch: {
    '$route.query.viewCampaigns': {
      handler(newCampaigns: string, oldCampaign: string): void {
        if (newCampaigns && newCampaigns !== oldCampaign) {
          if (newCampaigns.length === 1) {
            // here we are overriding what the summary metrics will be to show campaign specific date, not general summary data
            this.campaignView = true;
          } else this.campaignView = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatValue(value): string {
      if (value === undefined) return 'unknown';
      // if (typeof value === 'number' && value > 999999) {
      //   return utils.formatValueEstimate(value);
      // }
      return utils.formatNumberWithCommas(value);
    },
    getDemographicIcon(d: string): string {
      const icons = {
        age: 'cake',
        education: 'school',
        gender: 'person',
        'marital status': 'favorite',
        race: 'invert_colors',
        income: 'payments',
        geotargeting: 'location_on',
        zips: 'location_on',
        dmacodes: 'map',
        cities: 'location_city',
        states: 'terrain',
      };
      return icons[d.toLowerCase()];
    },
    geoTargetFallback(data: string): Array<GeoTargets> {
      const arr = data.split(',');

      let tooltipValues = '';
      for (let i = 0; i < arr.length; i++) {
        if (arr[i + 1]) {
          tooltipValues += arr[i] + ', ';
        } else {
          tooltipValues += arr[i];
        }
      }
      return [
        {
          icon: this.getDemographicIcon('GeoTargeting'),
          title: 'Geo Target',
          values: tooltipValues,
          tooltipValue: tooltipValues,
        },
      ];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      this.$emit('rendered', ctx);
    },
  },
};
