
import Vue from 'vue';
export default Vue.extend({
  name: 'ChartColors',
  props: ['chartColors', 'colorCount'],
  data: (): { palettes: Array<Array<string>>; selectedPalette: number } => ({
    palettes: [
      ['#f72585', '#7209b7', '#4cc9f0', '#4361ee'],
      ['#eec700', '#0a9396', '#ae2012', '#94d2bd'],
      ['#02e0ff', '#0042ee', '#5299ff', '#09447f'],
    ],
    selectedPalette: -1,
  }),
  mounted(): void {
    // if already set, determin which is already selected
    if (this.chartColors) {
      const selected = this.chartColors.join();
      const foundIndex = this.palettes.findIndex(palette => {
        return palette.join() === selected;
      });
      this.selectedPalette = foundIndex;
    }
  },
  methods: {
    setOverride(palette: string[], index: number): void {
      this.selectedPalette = index;
      this.$emit('setOverride', 'chartColors', palette);
    },
  },
});
