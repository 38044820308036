var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-4 w-100",class:{
    'py-2': _vm.$vuetify.breakpoint.lgAndUp,
    'py-4': _vm.$vuetify.breakpoint.mdAndDown,
    'px-4': _vm.halfWidth,
    'dev-tooltip': _vm.showDevTooltip,
    'justify-center': !_vm.halfWidth,
    'align-center': !_vm.halfWidth,
    halfWidth: _vm.halfWidth,
  }},[_c('p',{staticClass:"ma-0 pa-0",class:{ 'text-center': !_vm.halfWidth, 'text-left': _vm.halfWidth }},[(_vm.metric.headline)?_c('span',{staticClass:"headline-group",class:{ 'justify-center': !_vm.halfWidth },attrs:{"title":_vm.metric.headline}},[_c('v-icon',{staticClass:"primary--text mr-3"},[_vm._v(_vm._s(_vm.iconLabel))]),_c('span',{staticClass:"primary--text",class:{ 'text-h4': !_vm.halfWidth, 'text-h5': _vm.halfWidth },domProps:{"innerHTML":_vm._s(_vm.metric.headline)}})],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.metric.toolTip},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.metric.title)?_c('span',_vm._g({staticClass:"font-weight-medium mt-2",class:{ 'subtitle-1': !_vm.halfWidth, 'subtitle-2': _vm.halfWidth },style:({ color: _vm.subheadingColor }),attrs:{"title":!_vm.metric.toolTip ? _vm.metric.title : null},domProps:{"innerHTML":_vm._s(_vm.metric.title)}},on)):_vm._e()]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.metric.toolTip)}})]),(_vm.metric.description)?_c('span',{staticClass:"font-weight-medium mt-2",class:{ 'subtitle-1': !_vm.halfWidth, 'subtitle-2': _vm.halfWidth },attrs:{"title":_vm.metric.description},domProps:{"innerHTML":_vm._s(_vm.metric.description)}}):_vm._e()],1),(_vm.showDevTooltip)?_c('div',{staticClass:"tooltiptext"},_vm._l((Object.keys(_vm.devTooltipData)),function(dataKey,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(dataKey)+":")]),_c('span',[_vm._v(_vm._s(_vm.devTooltipData[dataKey]))])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }