
import Vue from 'vue';

// Components
import availableComponents from '../layouts/availableComponents.vue';
import defaultView from '../layouts/defaultView.vue';
import summaryView from '../layouts/summaryView.vue';
import homeView from '../layouts/homeView.vue';
import adPortalHeader from '../components/exports/adPortalHeaderX.vue';
import adPortalFooter from '../components/exports/adPortalFooterX.vue';
import { CurrentSection } from '../../types/layout';

export default Vue.extend({
  components: {
    availableComponents,
    defaultView,
    summaryView,
    homeView,
    adPortalHeader,
    adPortalFooter
  },
  data(): {
    campaignNav: {
      breakpoints: Array<string>;
      class: string;
      name: string;
    };
  } {
    return {
      campaignNav: {
        breakpoints: ['lg12', 'sm12'],
        class: 'pb-0',
        name: 'campaignNav',
      },
    };
  },
  props: ['validSections', 'currentSection', 'validComponents', 'isPrinting'],
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout(): any {
      return this.$store.state.customer.dynamicLayout;
    },
    lineBorder(): boolean {
      return this.Theme?.config?.chartBorder === 'line';
    },
    isSummaryOnlyView(): boolean {
      return this.$route.query.summaryView || ['orderlist'].includes(this.$route.query.tab);
    },
    isHomeOnlyView(): boolean {
      return this.$route.query.home || ['home'].includes(this.$route.query.tab);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentsList(): any {
      // TODO: do we really need to filter by 'default'?
      const list = this.validComponents.find(
        (component): boolean =>
          component?.type === 'default' ||
          component?.type === 'agency' ||
          component?.type === 'advertiser' ||
          component?.type === 'user',
      );
      return list || [];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentsListSummary(): any {
      const type: string = this.isSummaryOnlyView ? 'summaryonly' : 'summary';
      const list = this.validComponents.find((component): boolean => component?.type === type);
      return list || [];
    },
  },
  methods: {
    setCurrentSection(section: CurrentSection): void {
      this.$emit('set-current-section', section);
    },
  },
});
