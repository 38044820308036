
import Vue from 'vue';
import utils from '../../../../../../util';
export default Vue.extend({
  name: 'metricPacing',
  data: (): {
    pieWidth: string;
    pieHeight: string;
  } => ({
    pieWidth: '30px',
    pieHeight: '30px',
  }),
  props: {
    pacing: String,
  },
  computed: {
    primaryColor(): { color: string } {
      return { color: this.Theme.light.primary };
    },
  },
  methods: {
    formatNumber(num: string): string {
      if (typeof num === 'string' && num.split('').includes('$')) return num;
      return utils.formatNumberWithCommas(parseInt(num));
    },
    getPacingStyle(value: string): string {
      if (!value || value === 'N/A') return 'pacing-na pl-0';
      const parsedValue = parseFloat(value);

      const [normalPacingBorder, goodPacingBorder, badPacingBorder] = [-5.0, 0.0, 5.0];

      if (parsedValue >= goodPacingBorder && parsedValue < badPacingBorder) {
        return 'pacing-good pl-2';
      } else if (parsedValue >= normalPacingBorder && parsedValue < goodPacingBorder) {
        return 'pacing-normal pl-2';
      } else return 'pacing-bad pl-2';
    },
  },
});
