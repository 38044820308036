import { render, staticRenderFns } from "./topMetrics.vue?vue&type=template&id=075d6434&scoped=true"
import script from "./topMetrics.vue?vue&type=script&lang=ts"
export * from "./topMetrics.vue?vue&type=script&lang=ts"
import style0 from "./topMetrics.vue?vue&type=style&index=0&id=075d6434&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "075d6434",
  null
  
)

export default component.exports