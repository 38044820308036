var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.onlyRemove)?_c('v-btn',{staticStyle:{"z-index":"8"},attrs:{"small":"","depressed":"","color":"grey lighten-2 text-transform-capitalize"},on:{"click":function($event){{
        _vm.$store.dispatch('layoutEditor/editComponent', {
          sectionId: _vm.sectionId,
          componentId: _vm.componentId,
        });
        _vm.trackEdit();
      }}}},[_vm._v(" Edit Module "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("edit")])],1):_c('v-btn',{attrs:{"small":"","depressed":"","text":"","color":"red text-transform-capitalize"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_c('v-icon',[_vm._v("clear")]),_vm._v(" Remove ")],1),_c('v-dialog',{attrs:{"max-width":"302"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',{staticClass:"exit-edit-mode-dialog"},[_c('v-card-title',{staticClass:"headline text-transform-none"},[_vm._v("Are you sure you want to remove this module? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteComponent()}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" No ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }